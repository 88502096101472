import {
  Component,
  Input,
  AfterContentInit,
  ContentChildren,
  QueryList,
  Output,
  EventEmitter
} from '@angular/core';
import { ElementTabContentComponent } from './tabContent.component';

@Component({
  selector: 'dge-tab-container',
  templateUrl: './tabContainer.component.html',
  styleUrls: ['./tabContainer.component.min.css']
})
export class ElementTabContainerComponent implements AfterContentInit {
  @Input() activeTab: string;
  @Output() tabChange = new EventEmitter<any>();

  @ContentChildren(ElementTabContentComponent) tabs: QueryList<ElementTabContentComponent>;  

  constructor() { }

  ngAfterContentInit() {
    const activeTabs = this.tabs.filter((tab) => tab.active);
    
    if (activeTabs.length === 0 && this.tabs.length > 0) {
      this.selectTab(this.tabs.first);
    }
  }

  selectTab(tab: ElementTabContentComponent) {
    this.tabs.toArray().forEach(i => i.active = false);
    this.activeTab = tab.key;

    tab.active = true;
  }

  selectTabByKey(key: string) {
    if (this.tabs) {
      const tab = this.tabs.find(i => i.key === key);
      if (tab) {
        this.selectTab(tab);
      }
    }
  }

  onTabClick(tab: ElementTabContentComponent) {
    this.selectTab(tab); 
    this.tabChange.emit(tab.key);
  }
}
