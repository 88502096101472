import { Component, OnInit, Input, ContentChildren, Output, EventEmitter, QueryList, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';

import { ProjectService } from 'src/app/services/project.service';

import { ElementPropertyLineComponent } from './propertyLine.component';
import { PropertyUtils } from 'src/app/utils/propertyUtils';

@Component({
  selector: 'dge-el-condition-container',
  templateUrl: './conditionContainer.component.html',
  styleUrls: ['./conditionContainer.component.min.css']
})
export class ElementConditionContainerComponent implements OnInit, OnDestroy {
  @Input() type: 'default' | 'type' = 'default';
  @Input() key: string;
  @Input() value: any;
  @Input() operator: '=' | '!=' | '<=' | '<' | '>' | '>=' = '=';
  @Input() invert: boolean = false;

  @Output() conditionChange = new EventEmitter<boolean>();

  conditionFulfilled: boolean = false;
  operators = {
    '=': (a, b) => a === b,
    '!=': (a, b) => a !== b,
    '<': (a, b) => a < b,
    '<=': (a, b) => a <= b,
    '>': (a, b) => a > b,
    '>=': (a, b) => a >= b,
  };

  @ContentChildren(ElementPropertyLineComponent)
  private children: QueryList<ElementPropertyLineComponent>;

  _subscriptions: Subscription[] = [];

  constructor(
    private projectService: ProjectService
  ) { }

  ngOnInit() {
    this.checkCondition();

    if (this.type === 'type') {
      this._subscriptions.push(this.projectService.typeChanged.subscribe((res: { key: string, value: string }) => {
        if (res && this.key === res.key) {
          this.checkAndUpdateChildren();
        }
      }));
    } else if (this.type === 'default') {
      this._subscriptions.push(this.projectService.valueChanged.subscribe((res: { key: string, value: object }) => {
        if (res && this.key === res.key) {
          this.checkAndUpdateChildren();
        }
      }));
    }

    this._subscriptions.push(this.projectService.dataChanged.subscribe(res => {
      this.checkAndUpdateChildren();
    }));
  }

  ngOnDestroy() {
    let sub: Subscription;
    while (sub = this._subscriptions.pop()) {
      if (!sub.closed) sub.unsubscribe();
    }
  }

  checkAndUpdateChildren() {
    const oldCondition = this.conditionFulfilled;
    this.checkCondition();
    if (this.children && this.conditionFulfilled !== oldCondition) {
      this.children.forEach((item: ElementPropertyLineComponent, idx: number) => {
        item.reloadInputValue();
      });
    }
  }

  checkCondition() {
    const keyValue = this.projectService.getDataValue(this.key);
    const oldConditionFulfilled = this.conditionFulfilled;

    // if (this.key === 'RotatingMachine.Rotor.Pole.Fixation.Claws[0].Type') console.log(this.key, keyValue, this.value)

    this.conditionFulfilled = false;

    if (typeof keyValue !== 'undefined') {
      if (keyValue === null && (this.value === null || this.value === '')) {
        // Default and Type checks are the same if value is NULL
        this.conditionFulfilled = true;
      } else {
        if (this.type === 'default') {
          if (keyValue !== null && this.operators[this.operator](keyValue, this.value)) this.conditionFulfilled = true;
          // if (this.key === 'RotatingMachine.Rotor.Pole.Fixation.Claws[0].Type') console.log(this.operators[this.operator](keyValue, this.value))

        } else if (this.type === 'type') {
          if (keyValue !== null && PropertyUtils.getDiscriminator(keyValue) === this.value) this.conditionFulfilled = true;
        }
      }
    }

    if (this.invert) this.conditionFulfilled = !this.conditionFulfilled;
    if (this.conditionFulfilled !== oldConditionFulfilled) this.conditionChange.emit(this.conditionFulfilled);
  }
}
