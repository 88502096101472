import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserSettingsService, UserSettings } from 'src/app/services/userSettings.service';

@Component({
  selector: 'dge-layout-col-view',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.min.css']
})
export class LayoutColumnViewComponent implements OnInit, OnDestroy {
  debugMode: boolean = false;

  gearsModuleInitialized = true;
  modulesInitialized: string[] = [];

  _subscriptions: Subscription[] = [];

  constructor(
    private userSettingsService: UserSettingsService
  ) { }

  ngOnInit() {
    this._subscriptions.push(this.userSettingsService.settingsChanged.subscribe((settings: UserSettings) => {
      if (settings) {
        this.debugMode = settings.debugMode || false;
      }
    }));
  } 

  ngOnDestroy() {
    let sub: Subscription;
    while (sub = this._subscriptions.pop()) {
      if (!sub.closed) sub.unsubscribe();
    }
  }
  
  onTabChange(key) {
    if (this.modulesInitialized.indexOf(key) < 0) this.modulesInitialized.push(key);
  }

  // MODULES ----------------------------------------------

  isModuleInitialized(key) {
    return this.modulesInitialized.indexOf(key) >= 0;
  }
}
