import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/Observable';
import { debounceTime } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

import { ConfigService } from './config.service';

import { MeasurementSystem } from '../entities/units/measurementSystem';
import { CDPUserSetting } from '../entities/serviceTypes/index.datapool';

import { ProjectInfo } from '../entities/project';

export class UserSettings {
  public _defaults: boolean;
  public measurementSystem: MeasurementSystem;
  public decimalSeparator: string;
  public dateFormat: string;
  public autoCalculate: boolean;
  public debugMode: boolean;
  public projectHistory: ProjectInfo[];
}

@Injectable({ providedIn: 'root' })
export class UserSettingsService {
  private apiCommonDataPoolUrl =  this.configService.get('commonDataPoolUrl');
  private options = { 
    headers: new HttpHeaders({ 
      'Content-Type': 'application/json'
    })
  };
  private context = 'kftcae';
  private skipSetSettings = false;

  private settings: UserSettings = {
    _defaults: true,
    measurementSystem: MeasurementSystem.Metric,
    decimalSeparator: '.',
    dateFormat: 'yyyy-MM-dd',
    autoCalculate: true,
    debugMode: false,
    projectHistory: []
  };
  public settingsChanged: BehaviorSubject<UserSettings>;
  
  public userSID: string = '';

  constructor(
    private http: HttpClient,
    private configService: ConfigService
  ) { 
    this.settingsChanged = new BehaviorSubject(null);
    this.settingsChanged.pipe(debounceTime(1000)).subscribe(() => this.save());
  }

  // SAVE / LOAD

  save() {
    if (this.skipSetSettings) { this.skipSetSettings = false; return; }
    if (this.settings._defaults) { return; }
    
    this.http.put(this.apiCommonDataPoolUrl + 'UserSettings/' + this.context, JSON.stringify({ data: JSON.stringify(this.settings) }), this.options)
      .subscribe(r => { console.log('Settings saved!'); }, err => console.log(err));
  }

  load(): Observable<UserSettings> {
    return new Observable(obs => {
      this.http.get(this.apiCommonDataPoolUrl + 'UserSettings/' + this.context, this.options).subscribe((r: CDPUserSetting) => {
        obs.next(this.setSettings(r));
      }, errLoad => {
        if (errLoad.status === 404) {
          this.createInitial().subscribe((r: CDPUserSetting) => {
            obs.next(this.setSettings(r));
          }, errCreate => {
            obs.error(errCreate);
          });
        } else {          
          obs.error(errLoad);
        }
      });
    });    
  }

  createInitial() {
    return this.http.post(this.apiCommonDataPoolUrl + 'UserSettings/' + this.context, JSON.stringify({ data: JSON.stringify(this.settings) }), this.options);
  }

  delete() {
    return this.http.delete(this.apiCommonDataPoolUrl + 'UserSettings/' + this.context, this.options);
  }

  setSettings(s: CDPUserSetting): UserSettings {
    this.skipSetSettings = true;
    
    if (s && s.userSID) this.userSID = s.userSID;
    if (s && s.data) {
      this.settings = JSON.parse(s.data);
      this.settingsChanged.next(this.settings);
    }
    return this.settings;
  }

  // Specific Settings

  getSetting(name: string): any {
    return this.settings[name];
  }
  setSetting(name: string, value: any): any {
    if (this.settings[name] !== value) {
      this.settings[name] = value;
      this.settings._defaults = false;
      this.settingsChanged.next(this.settings);
    }
    return this.settings[name];
  }
  
  getMeasurementSystem(): MeasurementSystem {
    return this.settings.measurementSystem;
  }  
  setMeasurementSystem(ms: MeasurementSystem): MeasurementSystem {
    return this.setSetting('measurementSystem', ms);
  }

  getDecimalSeparator(): string {
    return this.settings.decimalSeparator;
  }
  setDecimalSeparator(sep: string): string {
    return this.setSetting('decimalSeparator', sep);
  }

  // Project History

  addToProjectHistory(pi: ProjectInfo) {
    if (!pi) return;

    // Remove existing project from history (to move it back up)
    let ph = this.settings.projectHistory;
    ph = ph.filter(i => i.project.id !== pi.project.id && i.variant.id !== pi.variant.id && i.version.id !== pi.version.id);

    // Add to history
    ph.push(pi);

    // Clean up #TODO# Remove this somewhen
    ph.forEach(prjInf => {        
      if (prjInf.version && prjInf.version['dataPayload']) prjInf.version['dataPayload'] = null;
      if (prjInf.iteration && prjInf.iteration['dataPayload']) prjInf.iteration['dataPayload'] = null;
      if (prjInf.iteration && prjInf.iteration['childLinks']) prjInf.iteration['childLinks'] = null;
      if (prjInf['revision']) delete prjInf['revision'];
    });

    // Only save the last 5
    if (ph.length > 5) ph.splice(0, ph.length - 5);

    this.settings.projectHistory = ph;    
    this.settings._defaults = false;
    this.save();
  }  
}