import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MsalService } from '@azure/msal-angular';

import { Observable, of, BehaviorSubject } from 'rxjs';

import { ConfigService } from './config.service';

import { HelperUtils } from '../utils/helperUtils';
import { Router } from '@angular/router';

export enum AuthRole {
  Administrator,
  User
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  authUser: any = null;
  authRedirectUrl: string;

  constructor(
    private msalService: MsalService
  ) { }

  isAuthenticated(): boolean {
    return !!this.authUser;
  }

  getMsalUser() {
    if (!this.authUser) {
      this.authUser = this.msalService.getUser();
    }
    return this.authUser;
  }

  logout() {
    this.msalService.logout();
  }
}
