import { NgModule } from '@angular/core';

import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import { ListboxModule } from 'primeng/listbox';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MultiSelectModule } from 'primeng/multiselect';

import { ButtonModule } from 'primeng/button';

import { TableModule } from 'primeng/table';
import { TreeTableModule } from 'primeng/treetable';

import { ChartModule } from 'primeng/chart';

import { TooltipModule } from 'primeng/tooltip';
import { ToastModule } from 'primeng/toast';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { MessageService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';

import { MenuModule } from 'primeng/menu';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { ContextMenuModule } from 'primeng/contextmenu';


@NgModule({
  imports: [
    InputTextModule,
    DropdownModule,
    CheckboxModule,
    ListboxModule,
    InputSwitchModule,
    MultiSelectModule,

    ButtonModule,

    TableModule,
    TreeTableModule,

    ChartModule,

    TooltipModule,
    ToastModule,
    MessageModule,
    MessagesModule,
    ConfirmDialogModule,
    DialogModule,

    MenuModule,
    TieredMenuModule,
    ContextMenuModule
  ],
  exports: [
    ButtonModule,
    InputTextModule,
    InputTextareaModule,
    DropdownModule,
    CheckboxModule,
    ListboxModule,
    InputSwitchModule,
    MultiSelectModule,

    ButtonModule,

    TableModule,
    TreeTableModule,

    ChartModule,

    TooltipModule,
    ToastModule,
    MessageModule,
    MessagesModule,
    ConfirmDialogModule,
    DialogModule,

    MenuModule,
    TieredMenuModule,
    ContextMenuModule
  ],
  providers: [
    MessageService,
    ConfirmationService
  ]
})

export class PrimeModule { }
