import { Component, OnInit, ElementRef, ViewChild, Input, Output, EventEmitter } from '@angular/core';

import { ProjectService } from '../../services/project.service';
import { GlobalService, PopupSettings } from 'src/app/services/global.service';
import { ControlBaseComponent } from './baseCtrl.component';
import { UserSettingsService } from 'src/app/services/userSettings.service';
import { PropertyUtils } from 'src/app/utils/propertyUtils';
import { CustomProperty } from 'src/app/entities/propertyTypes/customProperty';
import { HelperUtils } from 'src/app/utils/helperUtils';

@Component({
  selector: 'dge-ctrl-custom',
  templateUrl: './customInput.component.html',
  styleUrls: ['./customInput.component.min.css']
})
export class ControlCustomInputComponent extends ControlBaseComponent implements OnInit {
  _value: string;
  @Input() set value(val: string) {
    this._value = val;
    this.setDisplayValue();
  }
  get value() {
    return this._value;
  }
  @Input() property: CustomProperty;

  displayValue: string;

  @Output() valueChange = new EventEmitter<any>();

  // @ViewChild('overlay', { static: false }) overlay: ElementRef;

  constructor(
    protected globalService: GlobalService,
    protected projectService: ProjectService,
    protected userSettingsService: UserSettingsService
  ) { 
    super(globalService, projectService, userSettingsService);
  }

  ngOnInit() {
    this.getValue();
    
    super.ngOnInit();
  }

  // Events ---------------

  onFocus(e) {
    this.setFocus();
  }

  // Methods -----------------

  getValue() {
    if (this.key) this.value = this.projectService.getDataValue(this.key);
  }

  setDisplayValue() {
    if (this.property.displayProperty) {      
      this.displayValue = HelperUtils.objGetPath(this.value, this.property.displayProperty);
    } else {
      this.displayValue = this.value;
    }
  }

  setFocus() {
    if (!this.readOnly && !this.isFocussed) {
      if (!this.disableKeyChange) {
        this.globalService.focussedCtrlKey = this.ctrlKey;
        this.globalService.focussedLineKey = this.lineKey;
      }
      
      this.carryOverOutput();
    }
  }

  edit() {
    if (this.property.customType === 'material') {
      this.globalService.currentPopup = new PopupSettings({
        type: 'custom-material',
        data: this.property,
        title: this.property.label
      });
    }
  }

  carryOverOutput() {
    alert('nyi');    
  }

  clearInput() {
    alert('nyi');
  }
}
