import { MeasurementSystem } from "./measurementSystem";
import { NumericUnitType } from "./numericUnitType";

export class NumericUnit {
  public key: string;
  public label: string;
  public measurementSystem: MeasurementSystem;
  public convertsTo: NumericUnitType;

  constructor(
    key: string, 
    label: string, 
    measurementSystem: MeasurementSystem, 
    /** Which unit (key, eg. 'in', 'mm', ..) should be used if a masurement system different to the own is selected. */
    convertsTo: NumericUnitType
  ) {
    this.key = key;
    this.label = label;
    this.measurementSystem = measurementSystem;
    this.convertsTo = convertsTo;
  }
}