import { NumericProperty, NumericPropertyOptions } from '../../entities/propertyTypes/numericProperty';
import { NumericUnitType } from '../../entities/units/numericUnitType';
import { SelectProperty, SelectTypeProperty } from '../../entities/propertyTypes/selectProperty';
import { StringProperty } from '../../entities/propertyTypes/stringProperty';

export const PROPERTIES_GEARS = {

  // INPUT ONLY

  'GearsCalculationInput.projectNumber':
  new StringProperty({
    label: 'Project number',
    inputKey: 'GearsCalculationInput.projectNumber',
    helpKey: 'GearsCalculationInput.projectNumber'
  }),

  'GearsCalculationInput.equipmentNumber':
  new StringProperty({
    label: 'Equipment number',
    inputKey: 'GearsCalculationInput.equipmentNumber',
    helpKey: 'GearsCalculationInput.equipmentNumber'
  }),

  'GearsCalculationInput.requiredOutputPower':
  new NumericProperty({
    label: 'Required output power',
    inputKey: 'GearsCalculationInput.requiredOutputPower',
    helpKey:'GearsCalculationInput.requiredOutputPower',
    options: new NumericPropertyOptions({ baseUnit: NumericUnitType.kW, countDecimals: 1}),
    required: true
  }),

  'GearsCalculationInput.outputSpeed':
  new NumericProperty({
    label: 'Required output speed',
    inputKey: 'GearsCalculationInput.outputSpeed',
    helpKey:'GearsCalculationInput.outputSpeed',
    options: new NumericPropertyOptions({ baseUnit: NumericUnitType["1/min"],  countDecimals: 1}),
    required: true
  }),

  'GearsCalculationInput.installedMotorPower': new SelectProperty({
    label: 'Motor nominal power',
    inputKey: 'GearsCalculationInput.installedMotorPower',
    helpKey:'GearsCalculationInput.installedMotorPower'
    // Dynamic, defined in modules/gears/components/forms/index.ts
  }),

  'GearsCalculationInput.motorRotationSpeed':
  new NumericProperty({
    label: 'Motor rot. speed',
    inputKey: 'GearsCalculationInput.motorRotationSpeed',
    helpKey:'GearsCalculationInput.motorRotationSpeed',
    options: new NumericPropertyOptions({ baseUnit: NumericUnitType["1/min"], countDecimals: 0}),
    required: true
  }),

  //START help calculation inputs..

  'GearsCalculationInput.helpCalculations.chainWheelDiameter':
  new NumericProperty({
    label: 'Diameter (pulley, chain wheel, etc.)',
    inputKey: 'GearsCalculationInput.helpCalculations.chainWheelDiameter',
    helpKey:'',
    options: new NumericPropertyOptions({ baseUnit: NumericUnitType.mm, countDecimals: 0}),
    required: false
  }),

  'GearsCalculationInput.helpCalculations.chainWheelSpeed':
  new NumericProperty({
    label: 'Speed (belt, chain, etc.)',
    inputKey: 'GearsCalculationInput.helpCalculations.chainWheelSpeed',
    helpKey:'',
    options: new NumericPropertyOptions({ baseUnit: NumericUnitType["m/s"], countDecimals: 2}),
    required: false
  }),

  //END help calculation inputs..

  'GearsCalculationInput.shaftsType':
    new SelectProperty({
      label: 'Gear type',
      inputKey: 'GearsCalculationInput.shaftsType',
      helpKey:'GearsCalculationInput.shaftsType',
      availableValues: [
        { label: 'Parallel shafts', value: 0 },
        { label: 'Right-angle shafts', value: 1 },
        { label: 'Footmounted Right-angle', value: 2 },
        { label: 'Footmounted parallel shafts', value: 3 }
      ]
    }),

  'GearsCalculationInput.gearReducerType': new SelectProperty({
    label: 'Gear range',
    inputKey: 'GearsCalculationInput.gearReducerType',
    helpKey:'GearsCalculationInput.gearReducerType',
    // Dynamic, defined in modules/gears/components/forms/index.ts
  }),


  // Safety against peak power
  'GearsCalculationInput.peakPowerSafety.peakLoadDirection': new SelectProperty({
    label: 'Peak load direction',
    inputKey: 'GearsCalculationInput.peakPowerSafety.peakLoadDirection',
    helpKey:'GearsCalculationInput.peakPowerSafety.peakLoadDirection'
    // Dynamic, defined in modules/gears/components/forms/index.ts
  }),

  'GearsCalculationInput.peakPowerSafety.peakLoadsPerHour':
  new SelectProperty({
    label: 'Peak loads',
    inputKey: 'GearsCalculationInput.peakPowerSafety.peakLoadsPerHour',
    helpKey:'GearsCalculationInput.peakPowerSafety.peakLoadsPerHour'
    // Dynamic, defined in modules/gears/components/forms/index.ts
  }),

  'GearsCalculationInput.peakPowerSafety.startType': new SelectProperty({
    label: 'Start type',
    inputKey: 'GearsCalculationInput.peakPowerSafety.startType',
    helpKey:'GearsCalculationInput.peakPowerSafety.startType'
    // Dynamic, defined in modules/gears/components/forms/index.ts
  }),

  // Safety against thermal power
  // 'GearsCalculationInput.thermalPowerSafety.ambientTemperature': new SelectProperty({
  //   label: 'Ambient temperature',
  //   inputKey: 'GearsCalculationInput.thermalPowerSafety.ambientTemperature'
  //   // Dynamic, defined in modules/gears/components/forms/index.ts
  // }),

  'GearsCalculationInput.thermalPowerSafety.ambientTemperature': new NumericProperty({
    label: 'Ambient temperature <sub>(10°C - 50°C)</sub>',
    inputKey: 'GearsCalculationInput.thermalPowerSafety.ambientTemperature',
    helpKey:'GearsCalculationInput.thermalPowerSafety.ambientTemperature',
    options: new NumericPropertyOptions(
      {
        baseUnit: NumericUnitType.C,
        countDecimals: 1,
        allowDecimals: true,
        minValue: 10,
        maxValue: 50
      })
  }),

  // 'GearsCalculationInput.thermalPowerSafety.altitude': new SelectProperty({
  //   label: 'Altitude',
  //   inputKey: 'GearsCalculationInput.thermalPowerSafety.altitude'
  //   // Dynamic, defined in modules/gears/components/forms/index.ts
  // }),

  'GearsCalculationInput.thermalPowerSafety.altitude': new NumericProperty({
    label: 'Altitude <sub>(0m - 5250m)</sub>',
    inputKey: 'GearsCalculationInput.thermalPowerSafety.altitude',
    helpKey:'GearsCalculationInput.thermalPowerSafety.altitude',
    options: new NumericPropertyOptions(
      {
        baseUnit: NumericUnitType.m,
        countDecimals: 1,
        allowDecimals: true,
        minValue: 0,
        maxValue: 5250
      })
  }),

  // 'GearsCalculationInput.thermalPowerSafety.sumpTemperature': new SelectProperty({
  //   label: 'Oil sump temperature',
  //   inputKey: 'GearsCalculationInput.thermalPowerSafety.sumpTemperature'
  //   // Dynamic, defined in modules/gears/components/forms/index.ts
  // }),

  'GearsCalculationInput.thermalPowerSafety.sumpTemperature': new NumericProperty({
    label: 'Oil sump temperature <sub>(60°C - 95°C)</sub>',
    inputKey: 'GearsCalculationInput.thermalPowerSafety.sumpTemperature',
    helpKey:'GearsCalculationInput.thermalPowerSafety.sumpTemperature',
    options: new NumericPropertyOptions(
      {
        baseUnit: NumericUnitType.C,
        countDecimals: 1,
        allowDecimals: true,
        minValue: 60,
        maxValue: 95
      })
  }),

  // 'GearsCalculationInput.thermalPowerSafety.operationTimePerHour': new SelectProperty({
  //   label: 'Operation time per hour',
  //   inputKey: 'GearsCalculationInput.thermalPowerSafety.operationTimePerHour'
  //   // Dynamic, defined in modules/gears/components/forms/index.ts
  // }),

  'GearsCalculationInput.thermalPowerSafety.operationTimePerHour': new NumericProperty({
    label: 'Operation time per hour <sub>(20% - 100%)</sub>',
    inputKey: 'GearsCalculationInput.thermalPowerSafety.operationTimePerHour',
    helpKey:'GearsCalculationInput.thermalPowerSafety.operationTimePerHour',
    options: new NumericPropertyOptions(
      {
        baseUnit: NumericUnitType.Pct,
        countDecimals: 0,
        allowDecimals: false,
        minValue: 20,
        maxValue: 100
      })
  }),

  'GearsCalculationInput.gearSizeSelectionMode':  new SelectProperty({
    label: 'Gear size selection mode',
    inputKey: 'GearsCalculationInput.gearSizeSelectionMode',
    helpKey:'GearsCalculationInput.gearSizeSelectionMode',
    availableValues: [
      { label: 'Automatic', value: 0 },
      { label: 'Manual', value: 1 }
    ]
  }),

  'GearsCalculationInput.useCoolingFan':
    new SelectProperty({
      label: 'Use cooling fan',
      inputKey: 'GearsCalculationInput.useCoolingFan',
      helpKey:'GearsCalculationInput.useCoolingFan',
      availableValues: [
        { label: 'No', value: 0 },
        { label: 'Yes', value: 1 }
      ]
    }),

  // INPUT & OUTPUT

  'GearsCalculationInput.gearSize': new SelectProperty({
    label: 'Gear type',
    inputKey: 'GearsCalculationInput.gearSize',
    outputKey: 'GearsCalculationOutput.gearSize',
    helpKey:'GearsCalculationInput.gearSize',
    // Dynamic, defined in modules/gears/components/forms/index.ts
  }),

  'GearsCalculationInput.nominalTransmission': new SelectProperty({
    label: 'Gear ratio',
    inputKey: 'GearsCalculationInput.nominalTransmission',
    outputKey: 'GearsCalculationOutput.nominalTransmission',
    helpKey:'GearsCalculationInput.nominalTransmission',
    // Dynamic, defined in modules/gears/components/forms/index.ts
  }),

  'GearsCalculationInput.outputTorque':
  new NumericProperty({
    label: 'Required output torque',
    // inputKey: 'GearsCalculationInput.outputTorque',
    outputKey: 'GearsCalculationOutput.outputTorque',
    helpKey:'GearsCalculationInput.outputTorque',
    options: new NumericPropertyOptions({ baseUnit: NumericUnitType.Nm })
  }),

  // OUTPUT ONLY

  'GearsCalculationOutput.motorRadialLoad':
  new NumericProperty({
    label: 'Motor radial load',
    outputKey: 'GearsCalculationOutput.motorRadialLoad',
    helpKey:'GearsCalculationOutput.motorRadialLoad',
    options: new NumericPropertyOptions({ baseUnit: NumericUnitType.kN, countDecimals: 2 })
  }),

  'GearsCalculationOutput.gearInputSpeed':
  new NumericProperty({
    label: 'Gear input speed',
    outputKey: 'GearsCalculationOutput.gearInputSpeed',
    helpKey:'GearsCalculationOutput.gearInputSpeed',
    options: new NumericPropertyOptions({ baseUnit: NumericUnitType["1/min"], countDecimals: 2 })
  }),

  'GearsCalculationOutput.vBeltTransmissionRatio':
  new NumericProperty({
    label: 'V-belt ratio',
    outputKey: 'GearsCalculationOutput.vBeltTransmissionRatio',
    helpKey:'GearsCalculationOutput.vBeltTransmissionRatio',
    options: new NumericPropertyOptions({ countDecimals: 3 })
  }),

  'GearsCalculationOutput.gearTransmission':
  new NumericProperty({
    label: 'Gear exact ratio',
    outputKey: 'GearsCalculationOutput.gearTransmission',
    helpKey:'GearsCalculationOutput.gearTransmission',
    options: new NumericPropertyOptions({ countDecimals: 3 })
  }),

  'GearsCalculationOutput.numberOfStages':
  new NumericProperty({
    label: 'Number of stages',
    outputKey: 'GearsCalculationOutput.numberOfStages',
    helpKey:'GearsCalculationOutput.numberOfStages',
    options: new NumericPropertyOptions({ countDecimals: 0 })
  }),

  'GearsCalculationOutput.gearRadialLoad':
  new NumericProperty({
    label: 'Gear allowable radial load',
    outputKey: 'GearsCalculationOutput.gearRadialLoad',
    helpKey:'GearsCalculationOutput.gearRadialLoad',
    options: new NumericPropertyOptions({ baseUnit: NumericUnitType.kN, countDecimals: 2 })
  }),

  'GearsCalculationOutput.gearPower':
  new NumericProperty({
    label: 'Gear allowable power',
    outputKey: 'GearsCalculationOutput.gearPower',
    helpKey:'GearsCalculationOutput.gearPower',
    options: new NumericPropertyOptions({ baseUnit: NumericUnitType.kW, countDecimals: 2 })
  }),

  'GearsCalculationOutput.thermalPower':
  new NumericProperty({
    label: 'Gear thermal power',
    outputKey: 'GearsCalculationOutput.thermalPower',
    helpKey:'GearsCalculationOutput.thermalPower',
    options: new NumericPropertyOptions({ baseUnit: NumericUnitType.kW, countDecimals: 2 })
  }),

  'GearsCalculationOutput.reducedThermalPower':
  new NumericProperty({
    label: 'Reduced gear thermal power',
    outputKey: 'GearsCalculationOutput.reducedThermalPower',
    helpKey:'GearsCalculationOutput.reducedThermalPower',
    options: new NumericPropertyOptions({ baseUnit: NumericUnitType.kW, countDecimals: 2 })
  }),

  'GearsCalculationOutput.safetyFactorAgainstCalculatedPower':
  new NumericProperty({
    label: 'Safety factor to required output power (S)',
    outputKey: 'GearsCalculationOutput.safetyFactorAgainstCalculatedPower',
    helpKey:'GearsCalculationOutput.safetyFactorAgainstCalculatedPower',
    options: new NumericPropertyOptions({ countDecimals: 2 })
  }),

  'GearsCalculationOutput.safetyFactorAgainstMotorPower':
  new NumericProperty({
    label: 'Safety factor to motor nominal power (S<sub>motor</sub>)',
    outputKey: 'GearsCalculationOutput.safetyFactorAgainstMotorPower',
    helpKey:'GearsCalculationOutput.safetyFactorAgainstMotorPower',
    options: new NumericPropertyOptions({ countDecimals: 2 })
  }),

  'GearsCalculationOutput.safetyFactorAgainstPeakPower':
  new NumericProperty({
    label: 'Safety factor to peak power (S<sub>peak</sub>)',
    outputKey: 'GearsCalculationOutput.safetyFactorAgainstPeakPower',
    helpKey:'GearsCalculationOutput.safetyFactorAgainstPeakPower',
    options: new NumericPropertyOptions({ countDecimals: 2 })
  }),

  'GearsCalculationOutput.safetyFactorAgainstThermalPower':
  new NumericProperty({
    label: 'Safety factor to thermal power (S<sub>T</sub>)',
    outputKey: 'GearsCalculationOutput.safetyFactorAgainstThermalPower',
    helpKey:'GearsCalculationOutput.safetyFactorAgainstThermalPower',
    options: new NumericPropertyOptions({ countDecimals: 2 })
  }),

  'GearsCalculationOutput.averageGearboxWeight':
  new NumericProperty({
    label: 'Average weight of gearbox',
    outputKey: 'GearsCalculationOutput.averageGearboxWeight',
    helpKey:'GearsCalculationOutput.averageGearboxWeight',
    options: new NumericPropertyOptions({ baseUnit: NumericUnitType.kg, countDecimals: 2 })
  }),

  'GearsCalculationOutput.gearboxPrice':
  new NumericProperty({
    label: 'Gearbox price (10/2020)',
    outputKey: 'GearsCalculationOutput.gearboxPrice',
    helpKey:'GearsCalculationOutput.gearboxPrice',
    options: new NumericPropertyOptions({ baseUnit: NumericUnitType.euro, countDecimals: 2 })
  }),
  // TODO: add euro as unit

  //START help calculation outputs..

  'GearsCalculationOutput.helpCalculations.beltChainOutputSpeed':
  new NumericProperty({
    label: 'Belt/chain output speed',
    outputKey: 'GearsCalculationOutput.helpCalculations.beltChainOutputSpeed',
    helpKey:'',
    options: new NumericPropertyOptions({ baseUnit: NumericUnitType["1/min"], countDecimals: 3 })
  }),

  //END help calculation outputs..

//     'RotatingMachineInput.MachineDesign_RoundRotor':
//     new SelectProperty({
//       label: 'Machine Design',
//       inputKey: 'RotatingMachineInput.MachineDesign',
//       outputKey: 'RotatingMachine.MachineDesign',
//       helpKey: 'Machine.MachineDesign',
//       availableValues: [
//         { label: 'VERTICAL', value: MachineDesignTypes.Vertical },
//         { label: 'HORIZONTAL', value: MachineDesignTypes.Horizontal },
//         { label: 'HORIZONTAL TURBO', value: MachineDesignTypes.HorizontalTurbo },
//         //{ label: 'BULB', value: MachineDesignTypes.Bulb }
//       ],
//       required: true
//     }),

//     'RotatingMachineInput.MachineDesign_DoubleFedIM':
//     new SelectProperty({
//       label: 'Machine Design',
//       inputKey: 'RotatingMachineInput.MachineDesign',
//       outputKey: 'RotatingMachine.MachineDesign',
//       helpKey: 'Machine.MachineDesign',
//       availableValues: [
//         { label: 'VERTICAL', value: MachineDesignTypes.Vertical },
//         { label: 'HORIZONTAL', value: MachineDesignTypes.Horizontal },
//         //{ label: 'HORIZONTAL TURBO', value: MachineDesignTypes.HorizontalTurbo },
//         //{ label: 'BULB', value: MachineDesignTypes.Bulb }
//       ],
//       required: true
//     }),

//   'RotatingMachineInput.StatorWindingCoolingType':
//     new SelectProperty({
//       label: 'Stator Winding Cooling',
//       inputKey: 'RotatingMachineInput.StatorWindingCoolingType',
//       outputKey: 'RotatingMachine.StatorWindingCoolingType',
//       helpKey: 'Machine.StatorWindingCoolingType',
//       availableValues: [
//         { label: 'AUTO', value: null },
//         { label: 'GAS', value: StatorWindingCoolingTypes.GasIndirect },
//         { label: 'LIQUID', value: StatorWindingCoolingTypes.LiquidDirect }
//       ]
//     }),

//   'RotatingMachineInput.FieldWindingCoolingType':
//     new SelectProperty({
//       label: 'Field Winding Cooling',
//       inputKey: 'RotatingMachineInput.FieldWindingCoolingType',
//       outputKey: 'RotatingMachine.FieldWindingCoolingType',
//       helpKey: 'Machine.FieldWindingCoolingType',
//       availableValues: [
//         { label: 'AUTO', value: null },
//         { label: 'GAS', value: FieldWindingCoolingTypes.Gas },
//         { label: 'LIQUID', value: FieldWindingCoolingTypes.Liquid }
//       ]
//     }),

//   'RotatingMachineInput.RotationDirection':
//     new SelectProperty({
//       label: 'Rotation Direction',
//       inputKey: 'RotatingMachineInput.RotationDirection',
//       outputKey: 'RotatingMachine.RotationDirection',
//       helpKey: 'Machine.RotationDirection',
//       availableValues: [
//         { label: 'AUTO', value: null },
//         { label: 'CLOCKWISE', value: RotationDirections.Clockwise },
//         { label: 'COUNTER-CLOCKWISE', value: RotationDirections.CounterClockwise },
//         { label: 'BOTH', value: RotationDirections.Both }
//       ]
//     }),

//   'RotatingMachineInput.RotationDirectionView':
//     new SelectProperty({
//       label: 'Rot. Direction View',
//       inputKey: 'RotatingMachineInput.RotationDirectionView',
//       outputKey: 'RotatingMachine.RotationDirectionView',
//       helpKey: 'Machine.RotationDirectionView',
//       availableValues: [
//         { label: 'AUTO', value: null },
//         { label: 'DRIVE-END', value: RotationDirectionViews.SeenFromDriveEnd },
//         { label: 'CONNECTION-SIDE', value: RotationDirectionViews.SeemFromConnectionSide },
//         { label: 'NON-DRIVE-END', value: RotationDirectionViews.SeenFromNonDriveEnd },
//         { label: 'NON-CONNECTION-SIDE', value: RotationDirectionViews.SeenFromNonConnectionSide },
//         { label: 'EXCITER-SIDE', value: RotationDirectionViews.SeenFromExciterSide },
//         { label: 'NON-EXCITER-SIDE',  value: RotationDirectionViews.SeenFromNonExciterSide },
//         { label: 'SLIPRING-SIDE',  value: RotationDirectionViews.SeenFromSlipringSide },
//         { label: 'NON-SLIPRING-SIDE',  value: RotationDirectionViews.SeenFromNonSlipringSide },
//         { label: 'HEADWATER-SIDE',  value: RotationDirectionViews.SeenFromHeadwaterSide },
//         { label: 'TAILWATER-SIDE',  value: RotationDirectionViews.SeenFromTailwaterSide },
//         { label: 'UPHILL-SIDE',  value: RotationDirectionViews.SeenFromUphillSide },
//         { label: 'DOWNHILL-SIDE',  value: RotationDirectionViews.SeenFromDownhillSide },
//         { label: 'FRONT-DOOR-SIDE',  value: RotationDirectionViews.SeenFromFrontDoorSide },
//         { label: 'BACK-DOOR-SIDE',  value: RotationDirectionViews.SeenFromBackDoorSide },
//         { label: 'SUN-SIDE',  value: RotationDirectionViews.SeenFromSunSide },
//         { label: 'MOON-SIDE',  value: RotationDirectionViews.SeenFromMoonSide },
//         { label: 'POINT-OF-VIEW',  value: RotationDirectionViews.SeenFromPointOfView }
//       ]
//     }),

//   'RotatingMachineInput.TurbineType':
//     new SelectProperty({
//       label: 'Turbine Type',
//       inputKey: 'RotatingMachineInput.TurbineType',
//       outputKey: 'RotatingMachine.TurbineType',
//       helpKey: 'Machine.TurbineType',
//       availableValues: [
//         { label: 'AUTO', value: null },
//         { label: 'PELTON TURBINE', value: TurbineTypes.Pelton },
//         { label: 'FRANCIS TURBINE', value: TurbineTypes.Francis },
//         { label: 'KAPLAN TURBINE', value: TurbineTypes.Kaplan },
//         { label: 'PUMP-TURBINE', value: TurbineTypes.ReversiblePumpTurbine },
//         { label: 'STEAM TURBINE', value: TurbineTypes.SteamTurbine },
//         { label: 'GAS TURBINE', value: TurbineTypes.GasTurbine },
//         { label: 'OTHER', value: TurbineTypes.Other },
//       ]
//     }),

//   'RotatingMachine.ProjectMetadataTypeDesignation':
//     new StringProperty({
//       label: 'Type Designation',
//       helpKey: 'Machine.ProjectMetadata.TypeDesignation',
//       outputKey: 'RotatingMachine.ProjectMetadata.TypeDesignation'
//     }),


//   // NOMINAL DATA

//   'RotatingMachineInput.PowerOutput.ApparentPower':
//     new NumericProperty({
//       label: 'Nominal Apparent Output',
//       inputKey: 'RotatingMachineInput.PowerOutput.ApparentPower',
//       outputKey: 'RotatingMachine.PowerOutput.ApparentPower',
//       helpKey: 'Machine.PowerOutput.ApparentPower',
//       options: new NumericPropertyOptions({ baseUnit: NumericUnitType.VA, viewUnit: NumericUnitType.MVA, countDecimals: 3 }),
//       required: true
//     }),

//     'RotatingMachineInput.PowerOutput.PowerFactorAbsolute':
//     new NumericProperty({
//       label: 'Nominal Power Factor, |cos φ| ',
//       helpKey: 'Machine.PowerOutput.CosPhiAbsolute',
//       inputKey: 'RotatingMachineInput.PowerOutput.PowerFactor',
//       outputKey: 'RotatingMachine.PowerOutput.CosPhiAbsolute',
//       options: new NumericPropertyOptions({ countDecimals: 3 })
//     }),

//     'RotatingMachine.PowerOutput.CosPhi':
//     new NumericProperty({
//       label: 'Nominal Power Factor, cos φ',
//       helpKey: 'Machine.PowerOutput.CosPhi',
//       outputKey: 'RotatingMachine.PowerOutput.CosPhi',
//       options: new NumericPropertyOptions({ countDecimals: 3 })
//     }),

//   'RotatingMachine.PowerOutput.SinPhi':
//     new NumericProperty({
//       label: 'Nominal Power Factor, sin φ',
//       helpKey: 'Machine.PowerOutput.SinPhi',
//       outputKey: 'RotatingMachine.PowerOutput.SinPhi',
//       options: new NumericPropertyOptions({ countDecimals: 3 })
//     }),

//   'RotatingMachine.PowerOutput.ActivePower':
//     new NumericProperty({
//       label: 'Nominal Active Power',
//       helpKey: 'Machine.PowerOutput.ActivePower',
//       outputKey: 'RotatingMachine.PowerOutput.ActivePower',
//       options: new NumericPropertyOptions({ baseUnit: NumericUnitType.W, viewUnit: NumericUnitType.MW, countDecimals: 3 })
//     }),

//   'RotatingMachine.PowerOutput.ReactivePower':
//     new NumericProperty({
//       label: 'Nominal Reactive Power',
//       helpKey: 'Machine.PowerOutput.ReactivePower',
//       outputKey: 'RotatingMachine.PowerOutput.ReactivePower',
//       options: new NumericPropertyOptions({ baseUnit: NumericUnitType.W, viewUnit: NumericUnitType.MW, countDecimals: 3 })
//     }),

//   'RotatingMachineInput.PowerOutput.OperationMode':
//     new SelectProperty({
//       label: 'Operation Mode',
//       helpKey: 'Machine.PowerOutput.OperationMode',
//       inputKey: 'RotatingMachineInput.PowerOutput.Operation',
//       outputKey: 'RotatingMachine.PowerOutput.OperationMode',
//       availableValues: [
//         { label: 'AUTO', value: null },
//         { label: 'GENERATOR', value: MachineOperations.Generator },
//         { label: 'MOTOR', value: MachineOperations.Motor },
//         { label: 'CONDENSER', value: MachineOperations.Condenser }
//       ]
//     }),

//   'RotatingMachineInput.PowerOutput.ExcitationMode':
//     new SelectProperty({
//       label: 'Excitation Mode',
//       helpKey: 'Machine.PowerOutput.ExcitationMode',
//       inputKey: 'RotatingMachineInput.PowerOutput.ExcitationMode',
//       outputKey: 'RotatingMachine.PowerOutput.ExcitationMode',
//       availableValues: [
//         { label: 'AUTO', value: null },
//         { label: 'LAG', value: ExcitationModes.Lag },
//         { label: 'LEAD', value: ExcitationModes.Lead }
//       ]
//     }),

//   'RotatingMachineInput.PoleCount':
//     new NumericProperty({
//       label: 'Pole Count',
//       helpKey: 'Machine.Stator.Core.Winding.PoleCount',
//       inputKey: 'RotatingMachineInput.PoleCount',
//       outputKey: 'RotatingMachine.Stator.Core.Winding.PoleCount',
//       options: new NumericPropertyOptions({ allowDecimals: false }),
//       required: true
//     }),

//   'RotatingMachineInput.GridFrequency':
//     new NumericProperty({
//       label: 'Grid Frequency',
//       helpKey: 'Machine.GridFrequency',
//       inputKey: 'RotatingMachineInput.GridFrequency',
//       outputKey: 'RotatingMachine.GridFrequency',
//       options: new NumericPropertyOptions({ baseUnit: NumericUnitType.Hz, countDecimals: 3 }),
//       required: true
//     }),

//   'RotatingMachine.SynchronousSpeed':
//     new NumericProperty({
//       label: 'Synchronous Speed',
//       helpKey: 'Machine.SynchronousSpeed',
//       outputKey: 'RotatingMachine.SynchronousSpeed',
//       options: new NumericPropertyOptions({ baseUnit: NumericUnitType['1/s'], viewUnit: NumericUnitType['1/min'], countDecimals: 1 })
//     }),

//   'RotatingMachine.RunawaySpeed':
//     new NumericProperty({
//       label: 'Runaway Speed',
//       helpKey: 'Machine.RunawaySpeed',
//       inputKey: 'RotatingMachineInput.RunawaySpeed',
//       outputKey: 'RotatingMachine.RunawaySpeed',
//       options: new NumericPropertyOptions({ baseUnit: NumericUnitType['1/s'], viewUnit: NumericUnitType['1/min'], countDecimals: 1 })
//     }),

//   'RotatingMachine.LoadRejectionSpeed':
//     new NumericProperty({
//       label: 'Load Rejection Speed',
//       helpKey: 'Machine.LoadRejectionSpeed',
//       inputKey: 'RotatingMachineInput.LoadRejectionSpeed',
//       outputKey: 'RotatingMachine.LoadRejectionSpeed',
//       options: new NumericPropertyOptions({ baseUnit: NumericUnitType['1/s'], viewUnit: NumericUnitType['1/min'], countDecimals: 1 })
//     }),

//   'RotatingMachine.OverSpeed':
//     new NumericProperty({
//       label: 'Overspeed',
//       helpKey: 'Machine.OverSpeed',
//       inputKey: 'RotatingMachineInput.OverSpeed',
//       outputKey: 'RotatingMachine.OverSpeed',
//       options: new NumericPropertyOptions({ baseUnit: NumericUnitType['1/s'], viewUnit: NumericUnitType['1/min'], countDecimals: 1 })
//     }),


//   'RotatingMachine.PhaseDescription':
//     new StringProperty({
//       label: 'Phase Description',
//       helpKey: 'Machine.PhaseDescription',
//       outputKey: 'RotatingMachine.PhaseDescription'
//     }),

//   'RotatingMachineInput.StatorRmsLineVoltage':
//     new NumericProperty({
//       label: 'Stator RMS Line Voltage',
//       helpKey: 'Machine.StatorRmsLineVoltage',
//       inputKey: 'RotatingMachineInput.StatorRmsLineVoltage',
//       outputKey: 'RotatingMachine.StatorRmsLineVoltage',
//       options: new NumericPropertyOptions({ baseUnit: NumericUnitType.V, viewUnit: NumericUnitType.kV, countDecimals: 3 })
//     }),

//   'RotatingMachine.StatorRmsPhaseVoltage':
//     new NumericProperty({
//       label: 'Stator RMS Phase Voltage',
//       helpKey: 'Machine.StatorRmsPhaseVoltage',
//       outputKey: 'RotatingMachine.StatorRmsPhaseVoltage',
//       options: new NumericPropertyOptions({ baseUnit: NumericUnitType.V, viewUnit: NumericUnitType.kV, countDecimals: 3 })
//     }),

//   'RotatingMachine.StatorRmsPhaseCurrent':
//     new NumericProperty({
//       label: 'Stator RMS Line Current',
//       helpKey: 'Machine.StatorRmsPhaseCurrent',
//       outputKey: 'RotatingMachine.StatorRmsPhaseCurrent',
//       options: new NumericPropertyOptions({ baseUnit: NumericUnitType.A, countDecimals: 1 })
//     }),

//   'RotatingMachine.Torque':
//     new NumericProperty({
//       label: 'Nominal Torque',
//       helpKey: 'Machine.Torque',
//       outputKey: 'RotatingMachine.Torque',
//       options: new NumericPropertyOptions({ baseUnit: NumericUnitType.Nm, viewUnit: NumericUnitType.kNm, countDecimals: 2 })
//     }),

//   'RotatingMachine.ReferenceTorque':
//     new NumericProperty({
//       label: 'Reference Torque (1 pu)',
//       helpKey: 'Machine.ReferenceTorque',
//       outputKey: 'RotatingMachine.ReferenceTorque',
//       options: new NumericPropertyOptions({ baseUnit: NumericUnitType.Nm, viewUnit: NumericUnitType.kNm, countDecimals: 2 })
//     }),

//   'RotatingMachine.ReferenceImpedance':
//     new NumericProperty({
//       label: 'Reference Impedance',
//       helpKey: 'Machine.ReferenceImpedance',
//       outputKey: 'RotatingMachine.ReferenceImpedance',
//       options: new NumericPropertyOptions({ baseUnit: NumericUnitType.Ohm, countDecimals: 3 })
//     }),

//     'RotatingMachineInput.StatorPuCurrentHarmonicsInput':
//     new EntityProperty({
//       label: 'Current Harmonic Input',
//       inputKey: 'RotatingMachineInput.StatorPuCurrentHarmonics',
//       helpKey: 'Machine.StatorPuCurrentHarmonicsInput',
//       useList: true,
//       availableEntities: [
//         new EntityDefinition({
//           label: 'Current Harmonic',
//           discriminator: 'OrdinalNumberValuePair',
//           assignment: () => new OrdinalNumberValuePair(),
//           properties: [
//             new NumericProperty({
//               label: 'Ordinal Number',
//               helpKey: 'Machine.StatorPuCurrentHarmonicsInput',
//               inputKey: 'OrdinalNumber',
//               options: new NumericPropertyOptions({ countDecimals: 0 })
//             }),
//             new NumericProperty({
//               label: 'Per-Unit Current',
//               helpKey: 'Machine.StatorPuCurrentHarmonicsInput',
//               inputKey: 'Value',
//               options: new NumericPropertyOptions({ baseUnit: NumericUnitType.pu, viewUnit: NumericUnitType.pu, countDecimals: 3 })
//             }),
//           ]
//         })
//       ]
//     }),

//     /*
//     'RotatingMachineInput.StatorPuCurrentHarmonics':
//     new CustomListProperty({
//       label: '',
//       helpKey: 'Machine.StatorPuCurrentHarmonics',
//       defaultListItemType: 'OrdinalNumberValuePair',
//       //inputKey: 'RotatingMachineInput.StatorPuCurrentHarmonics',
//       outputKey: 'RotatingMachine.StatorPuCurrentHarmonics'
//     }),
// */

//     'RotatingMachineInput.StatorPuCurrentHarmonics':
//     new TableProperty({
//       label: '<b>LIST OF CURRENT HARMONICS</b>',
//       helpKey: 'Machine.StatorPuCurrentHarmonics',
//       outputKey: 'RotatingMachine.StatorPuCurrentHarmonics',
//       columns: [
//         new TablePropertyColumn({
//           textAlign: "right",
//           property: new NumericProperty({
//             label: 'Ordinal number',
//             outputKey: 'ordinalNumber',
//             options: new NumericPropertyOptions({ countDecimals: 0, countDecimalsMin: 0 })
//           })
//         }),
//         new TablePropertyColumn({
//           textAlign: "right",
//           property: new NumericProperty({
//             label: 'Amplitude, in pu',
//             outputKey: 'value',
//             options: new NumericPropertyOptions({ countDecimals: 4, countDecimalsMin: 4 })
//           })
//         }),
//       ]
//     }),

//   //
//   //
//   //  M a s s :
//   //
//   //

//   'RotatingMachine.Mass.Mass':
//   new ComposedProperty({
//     label: 'Total Mass Stator / Rotor',
//     helpKey: 'Machine.Mass.Mass',
//     outputKey: 'RotatingMachine.Mass.Mass',
//     value1: new NumericProperty({
//       outputKey: 'RotatingMachine.Mass.Mass.Stator',
//       options: new NumericPropertyOptions({ baseUnit: NumericUnitType.kg, viewUnit: NumericUnitType.t, countDecimals: 1 })
//     }),
//     value2: new NumericProperty({
//       outputKey: 'RotatingMachine.Mass.Mass.Rotor',
//       options: new NumericPropertyOptions({ baseUnit: NumericUnitType.kg, viewUnit: NumericUnitType.t, countDecimals: 1 })
//     }),
//     separators: ['/', ' ']
//   }),

//   'RotatingMachine.Mass.MassMomentOfInertia':
//   new ComposedProperty({
//     label: 'Total Inertia Stator / Rotor',
//     helpKey: 'Machine.Mass.MassMomentOfInertia',
//     outputKey: 'RotatingMachine.Mass.MassMomentOfInertia',
//     value1: new NumericProperty({
//       outputKey: 'RotatingMachine.Mass.MassMomentOfInertia.Stator',
//       options: new NumericPropertyOptions({ baseUnit: NumericUnitType.kgm2, viewUnit: NumericUnitType.tm2, countDecimals: 0 })
//     }),
//     value2: new NumericProperty({
//       outputKey: 'RotatingMachine.Mass.MassMomentOfInertia.Rotor',
//       options: new NumericPropertyOptions({ baseUnit: NumericUnitType.kgm2, viewUnit: NumericUnitType.tm2, countDecimals: 0 })
//     }),
//     separators: ['/', ' ']
//   }),

//   'RotatingMachine.Mass.InertiaConstant':
//   new NumericProperty({
//     label: 'Rotor Inertia Constant H',
//     helpKey: 'Machine.Mass.InertiaConstant',
//     outputKey: 'RotatingMachine.Mass.InertiaConstant',
//     options: new NumericPropertyOptions({ baseUnit: NumericUnitType.s, viewUnit: NumericUnitType.s, countDecimals: 2 })
//   }),

};
