import { Component} from '@angular/core';
import { MsalService } from '@azure/msal-angular';

// This component is used only to avoid Angular reload when doing acquireTokenSilent()
// Otherwise the landing page including all it's content would be loaded within the hidden iFrame on every silent token request.
// https://docs.microsoft.com/bs-latn-ba/azure/active-directory/develop/msal-js-avoid-page-reloads

@Component({
  selector: 'dge-root',
  template: '',
})
export class MsalComponent {
  constructor(
    private Msal: MsalService
  ) { }
}