import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs/BehaviorSubject';

import { ConfigService } from './config.service';
import { GlobalService } from './global.service';
import { UserSettingsService } from './userSettings.service';
import { CDPProject, CDPVariant, CDPVersion, CDPIteration } from '../entities/serviceTypes/index.datapool';
import { DgeModuleController } from '../entities/dgeModule';
import { PropertyMetaData, ProjectMetaData, ProjectInfo, ProjectErrorController } from '../entities/project';

@Injectable({ providedIn: 'root' })
export class ProjectService {
  public project = {
    data: {} as any,
    metaData: new ProjectMetaData()
  };

  public hasChanges: boolean = false;

  public dataChanged: BehaviorSubject<any>;
  public metaDataChanged: BehaviorSubject<{ key: string, value: PropertyMetaData }>;
  public favoritesChanged: BehaviorSubject<any>;

  public valueChanged: BehaviorSubject<{ key: string, value: object }>;
  public valueChangedExternally: BehaviorSubject<string>; // key
  public typeChanged: BehaviorSubject<{ key: string, value: string }>;

  public errors: ProjectErrorController;
  public errorsChanged: BehaviorSubject<any[]>;

  public currentProject: ProjectInfo;
  public currentProjectChanged: BehaviorSubject<ProjectInfo>;

  public modules: DgeModuleController;

  public _debugLatestInput = {
    gears: {} as any, //DgeModuleCallConfig.name
    screwcon: {} as any, //DgeModuleCallConfig.name
    screwrec: {} as any,
  };

  public _debugLatestOutput =
  {
    gears: {} as any, //DgeModuleCallConfig.name
    screwcon: {} as any, //DgeModuleCallConfig.name
    screwrec: {} as any
  };

  constructor(
    private configService: ConfigService,
    public globalService: GlobalService,
    private userSettingsService: UserSettingsService
  ) {
    this.dataChanged = new BehaviorSubject(null);
    this.metaDataChanged = new BehaviorSubject(null);
    this.favoritesChanged = new BehaviorSubject(null);

    this.valueChanged = new BehaviorSubject(null);
    this.valueChangedExternally = new BehaviorSubject(null);
    this.typeChanged = new BehaviorSubject(null);

    this.errorsChanged = new BehaviorSubject([]);
    this.errors = new ProjectErrorController(this);

    this.currentProjectChanged = new BehaviorSubject(null);

    this.modules = new DgeModuleController(this);

    this.initProject();
  }

  // Project Data Management ================================================================================================

  initProject() {
    this.project.data = {};
    this.setProjectData();

    this.modules.initProjectDefaults();

    this.dataChanged.next(null);
    this.currentProjectChanged.next(null);

    this.hasChanges = false;
  }

  setProjectData() {
    if (!this.project.data) this.project.data = {};
    if (!this.project.metaData) this.project.metaData = new ProjectMetaData();

    this.modules.initProjectData();

    this.errors.clear();

    this._debugLatestInput = {
      gears: null,
      screwcon: null,
      screwrec: null
    };

    this._debugLatestOutput = {
      gears: null,
      screwcon: null,
      screwrec: null,
    };

    this.currentProject = null;
  }

  setProject(data: any) {
    this.project = data;

    this.setProjectData();
    this.valueChanged.next({ key: null, value: null });
    this.dataChanged.next(null);

    this.hasChanges = false;
  }

  getProject() {
    return this.project;
  }

  setProjectInfo(project: CDPProject, variant: CDPVariant, version: CDPVersion, iteration: CDPIteration, isLegacy: boolean = false) {
    if (!project) this.currentProject = null;
    else this.currentProject = new ProjectInfo(project, variant, version, iteration, isLegacy);

    if (this.currentProject.version && this.currentProject.version['dataPayload']) this.currentProject.version['dataPayload'] = null;
    if (this.currentProject.iteration && this.currentProject.iteration.childLinks) this.currentProject.iteration.childLinks = null;
    if (this.currentProject['revision']) delete this.currentProject['revision'];

    this.currentProjectChanged.next(this.currentProject);
    this.userSettingsService.addToProjectHistory(this.currentProject);
  }

  getDataValue(key: string): any {
    return this.findProjectValue(this.project.data, key);
  }

  setDataValue(key: string, value: any, ignoreApiCheck: boolean = false, ignoreHasChanges: boolean = false): any {
    if (!ignoreHasChanges) this.hasChanges = true;
    return this.findProjectValue(this.project.data, key, true, value, ignoreApiCheck);
  }

  private findProjectValue(
    data: any,
    key: string,
    setValue: boolean = false,
    value: any = null,
    ignoreApiCheck: boolean = false
  ): any {
    let ret = data;
    const parts = this.getKeyParts(key);
    
    for (let i = 0; i < parts.length; i++) {
      const part = parts[i];

      if (ret) {
        const isArray = (/\[[0-9]*\]/).test(part);
        let propName = isArray ? parseInt(part.replace(/\[|\]/g, ''), 10) : part;

        // Create property if not existing
        if (setValue && i < parts.length - 1 && !ret[propName]) {
          let isArrayNext = (/\[[0-9]*\]/).test(parts[i + 1]);
          let propVal = {}, tmpPropVal;

          // Check if property is newable, if yes use TypeScript API class
          if (ret.getPropertyInfo && ret.createInstanceFor) {
            const propInfo = ret.getPropertyInfo(propName);

            if (propInfo.isNewable || propInfo.isArrayItemNewable) tmpPropVal = ret.createInstanceFor(propName);

            if (tmpPropVal) propVal = tmpPropVal;

            // If newable array => forward to next item and set value
            if (propInfo.isArrayItemNewable) {
              isArrayNext = false;
              ret[propName] = [];
              ret = ret[propName];
              i++;

              propName = parseInt(parts[i].replace(/\[|\]/g, ''), 10);
            }
          }

          ret[propName] = isArrayNext ? [] : propVal;
        }

        // Set property for last part
        if (setValue && i === parts.length - 1) {
          ret[propName] = value;
          this.valueChanged.next({ key: key, value: value });
        }

        // Move to next part
        if (ret.hasOwnProperty(propName)) {
          ret = ret[propName];
        } else {
          return null;
        }
      } else {
        // throw new ReferenceError('[ProjectService].findProjectValue("' + key + '"): Could not find part "' + part + '" of key.');
        // console.error('[ProjectService].findProjectValue("' + key + '"): Could not find part "' + part + '" of key.');
        return null;
      }
    }

    if (setValue && !ignoreApiCheck && parts) this.checkForApiCalls(parts[0]);

    return ret;
  }

  private getKeyParts(key: string): string[] {
    const parts = key.replace(/<|>/gi, '').replace(/\[/gi, '.[').split('.');
    return parts.map(part => {
      if (part.toUpperCase() === part) return part.toLowerCase();
      return part.substr(0, 1).toLowerCase() + part.substr(1);
    });
  }

  toggleFavorite(key: string) {
    const favs = this.project.metaData.favorites;
    const idx = favs.findIndex(i => i === key);

    if (idx === -1) {
      this.project.metaData.favorites.push(key);
    } else {
      this.project.metaData.favorites.splice(idx, 1);
    }

    this.favoritesChanged.next(this.project.metaData.favorites);
  }

  setCtrlColor(lineKey: string, ctrlKey: string, colorIdx: number) {
    if (this.hasMetaData(ctrlKey) || colorIdx !== null) {
      const md = this.getMetaData(ctrlKey);
      md.lineKey = lineKey;
      md.color = colorIdx;

      this.metaDataChanged.next({ key: ctrlKey, value: md });
      if (colorIdx === null) this.cleanUpMetaData(ctrlKey);
    }
  }

  setLineComment(lineKey: string, comment: string) {
    if (this.hasMetaData(lineKey) || comment !== null) {
      const md = this.getMetaData(lineKey);
      md.lineKey = lineKey;
      md.comment = comment;

      this.metaDataChanged.next({ key: lineKey, value: md });
      if (!comment) this.cleanUpMetaData(lineKey);
    }
  }

  hasMetaData(key: string): boolean {
    return !!this.project.metaData.values[key];
  }

  getMetaData(key: string, doCreate: boolean = true): PropertyMetaData {
    if (doCreate && !this.project.metaData.values[key]) this.project.metaData.values[key] = new PropertyMetaData();
    return this.project.metaData.values[key];
  }

  cleanUpMetaData(key: string) {
    // Cleans up if nothing is set anymore
    if (this.project.metaData.values[key] && this.project.metaData.values[key].isEmpty()) {
      delete this.project.metaData.values[key];
    }
  }

  // API Calls ================================================================================================

  checkForApiCalls(namespace: string) {
    if (namespace && !this.userSettingsService.getSetting('autoCalculate')) return;

    this.modules.doNamespaceCall(namespace);
  }
}
