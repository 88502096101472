import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MsalGuard } from '@azure/msal-angular';

// Default Layout
import { LayoutComponent } from './components/layout/layout.component';
import { NotFoundComponent } from './components/layout/general/notFound.component';
import { EmptyComponent } from './components/layout/empty.component';
import { LoginComponent } from './components/auth/login.component';

const ROUTE_404 = { path: '**', component: NotFoundComponent };

const APP_ROUTES: Routes = [
  { path: '', redirectTo: '/main', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, canActivate: [MsalGuard] }, // MsalGuard
  { path: 'main', component: LayoutComponent, canActivate: [MsalGuard], data: { title: '' }, children: [ // MsalGuard
    { path: 'gears', component: LayoutComponent, canActivate: [MsalGuard], data: { title: '' }, children: [ // MsalGuard
      ROUTE_404
      ] 
    },
    { path: 'screwcon', component: LayoutComponent, canActivate: [MsalGuard], data: { title: '' }, children: [ // MsalGuard
      ROUTE_404
      ] 
    },
    {
      path: 'screwrec', component: LayoutComponent, canActivate: [MsalGuard], data: { title: '' }, children: [
        ROUTE_404
      ]
    },
    ROUTE_404
    ] 
  },
  ROUTE_404
];

@NgModule({
  imports: [ RouterModule.forRoot(APP_ROUTES) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }
