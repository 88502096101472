import { Component, OnInit } from '@angular/core';
import { UserSettingsService } from 'src/app/services/userSettings.service';
import { MeasurementSystem } from 'src/app/entities/units/measurementSystem';

@Component({
  selector: 'dge-el-settings',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.min.css']
})
export class ElementSettingsComponent implements OnInit {
  constructor(
    public userSettingsService: UserSettingsService // #TODO# - remove settings from this component
  ) { }

  ngOnInit() {

  }

  setAutoCalculate(b) {
    this.userSettingsService.setSetting('autoCalculate', b);
  }

  setDebugMode(b) {
    this.userSettingsService.setSetting('debugMode', b);
  }

  setMeasurementSystem(a) { 
    this.userSettingsService.setMeasurementSystem(a ? MeasurementSystem.Metric : MeasurementSystem.Imperial);    
  }

  setDecimalSep(sep) {
    this.userSettingsService.setDecimalSeparator(sep); 
  }
}
