import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dge-form--tests',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.min.css']
})
export class FormTestsComponent implements OnInit {

  constructor() { }

  ngOnInit() {

  }
}
