import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ConfigService } from './config.service';
import { GlobalService, ToastType } from './global.service';
import { ProjectService } from './project.service';

import { HelperUtils } from '../utils/helperUtils';

import { DrawingDescriptions } from '../config/drawingDescriptions';

@Injectable()
export class DrawingsService {
  private apiCalculationServiceUrl =  this.configService.get('calculationServiceUrl');
  private options = { 
    headers: new HttpHeaders({ 
      'Content-Type': 'application/json'
    }), 
    withCredentials: true };
    
  public currentDrawings: any[] = [];  

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private globalService: GlobalService,
    private projectService: ProjectService
  ) { }

  getAvailableDrawingDescriptions(): any {
    return DrawingDescriptions;
  }

  requestSVG(requestedDrawing): Promise<any> {
    const data = this.projectService.getDataValue('rotatingMachine');

    if (this.configService.get('apiCallsEnabled') && !HelperUtils.isObjEmpty(data)) {
      this.globalService.setGlobalMessage('Waiting for drawing results...');

      const ret = this.http
        .post(
          this.apiCalculationServiceUrl + 'Drawings/RequestSVG',
          JSON.stringify({
            "Geometry": this.projectService.project.data.rotatingMachine,
            "Properties": {
              "ContourLineThickness": 1.2,
              "AuxiliaryLineThickness": 1.0,
              "HiddenLineThickness": 1.0,
              "ContourLineColor": "DarkBlue",
              "AuxiliaryLineColor": "DarkGray",
              "HiddenLineColor": "DarkGray",
              "HiddenLineDashPattern": [ 4.0, 2.0 ],
              "DimensionLineThickness": 1.0,
              "DimensionLineColor": "Black",
              "CenterLineColor": "DarkGray",
              "CenterLineThickness": 1.0,
              "CenterLineDashPattern": [ 12.0, 3.5, 3.5, 3.5 ]
            },
            "RequestedDrawings": [requestedDrawing]
          }),
          this.options)
        .toPromise();

      ret.then(() => {
        this.globalService.setGlobalMessage('');
      }, (res) => {
        this.globalService.setGlobalMessage('POST to "Drawings/RequestSVG" unsuccessful!', 'error');
        this.globalService.toast(ToastType.Error, '[ERROR]: POST to "Drawings/RequestSVG" was unsuccessful!');
      
        if (res.error) {
          for (const prop in res.error) {
            if (res.error.hasOwnProperty(prop)) {
              for (let i = 0; i < res.error[prop].length; i++) {
                this.projectService.errors.add('Drawings/RequestSVG', res.error[prop][i]);
              }
            }
          }
        }
      });

      return ret;
    }
    return Promise.reject();
  }
}