import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { MessageService, ConfirmationService } from 'primeng/api';

export class ConfirmExtendedConfig {
  isVisible: BehaviorSubject<boolean>;
  message: string;
  options: ConfirmExtendedOptions;
  accept: Function;
  reject: Function;
  
  constructor() {
    this.isVisible = new BehaviorSubject<boolean>(false);
  }
}

export class ConfirmExtendedOptions {
  showComment: boolean = true;
  commentPlaceholder: string = '';

  constructor (init?: Partial<ConfirmExtendedOptions>) {
    Object.assign(this, init);
  }
}

export class ConfirmExtendedReturnValue {
  comment: string;

  constructor (init?: Partial<ConfirmExtendedReturnValue>) {
    Object.assign(this, init);
  }
}

@Injectable({ providedIn: 'root' })
export class GlobalService {
  /** The currently focussed Line. A line can contain multiple controls. */
  public focussedLineChanged = new BehaviorSubject(null);
  private _focussedLineKey = null;
  get focussedLineKey(): string {
    return this._focussedLineKey;
  }
  set focussedLineKey(val: string) {
    this._focussedLineKey = val;
    this.focussedLineChanged.next(val);
  }
  
  /** The currently focussed ctrl. Used for coloring/user guidance. */
  public focussedCtrlChanged = new BehaviorSubject(null);
  private _focussedCtrlKey = null;
  get focussedCtrlKey(): string {
    return this._focussedCtrlKey;
  }
  set focussedCtrlKey(val: string) {
    this._focussedCtrlKey = val;
    this.focussedCtrlChanged.next(val);
  }
  
  /** Can be used to "trigger" a ctrl focus. */
  public forceFocussedCtrlChanged = new BehaviorSubject(null);
  private _forceFocussedCtrlKey = null;
  get forceFocussedCtrlKey(): string {
    return this._forceFocussedCtrlKey;
  }
  set forceFocussedCtrlKey(val: string) {
    this._forceFocussedCtrlKey = val;
    this.forceFocussedCtrlChanged.next(val);
  }

  /** Loading/processing indicator. */
  public globalMessageChanged = new BehaviorSubject(null);
  private _globalMessage: GlobalMessage = null;
  get globalMessage(): GlobalMessage {
    return this._globalMessage;
  }
  set globalMessage(val: GlobalMessage) {
    this._globalMessage = val;
    this.globalMessageChanged.next(val);
  }

  /** Current shown primary popup (e.g. Data Browser, Settings, etc.). */
  public currentPopupChanged = new BehaviorSubject(null);
  private _currentPopup: PopupSettings = null;
  get currentPopup(): PopupSettings {
    return this._currentPopup;
  }
  set currentPopup(val: PopupSettings) {
    this._currentPopup = val;
    this.currentPopupChanged.next(val);
  }  

  public isDraggingChanged: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public extConfirm: ConfirmExtendedConfig;


  constructor(
    private confirmationService: ConfirmationService,
    private messageService: MessageService
  ) { 
    this.extConfirm = new ConfirmExtendedConfig();
  }

  setGlobalMessage(text: string, type: any = '') {
    this.globalMessage = new GlobalMessage({ text: text, type: type });
  }

  toast(type: ToastType, text: string, title: string = null) {
    this.messageService.add({ severity: type.toString(), summary: title || (type[0].toUpperCase() + type.slice(1)), detail: text, life: 5000 });
  }

  confirm(message: string, accept: Function = null, reject: Function = null) {
    this.confirmationService.confirm({
      message: message,
      header: ' ',
      acceptLabel: 'Yes',
      rejectVisible: true,
      accept: accept,
      reject: reject
    });
  }

  confirmExtended(message: string, options: ConfirmExtendedOptions, accept: Function = null, reject: Function = null) {
    this.extConfirm.message = message;
    this.extConfirm.options = options;
    this.extConfirm.accept = accept;
    this.extConfirm.reject = reject;
    this.extConfirm.isVisible.next(true);
  }

  confirmExtendedAccept(r: ConfirmExtendedReturnValue) {
    this.extConfirm.isVisible.next(false);
    if (this.extConfirm.accept) this.extConfirm.accept(r);
  }
  confirmExtendedReject() {
    this.extConfirm.isVisible.next(false);
    if (this.extConfirm.reject) this.extConfirm.reject();
  }
}

export class GlobalMessage {
  text: string;
  type: '' | 'loading' | 'info' | 'error';

  constructor (init?: Partial<GlobalMessage>) {
    Object.assign(this, init);
  }
}

export class PopupSettings {
  type: 'dashboard' | 'dataBrowser' | 'export' | 'settings' | 'comment' | 'custom-material' | 'kft-belt-chain-calc';
  data: any;
  title: string;
  showHeader: boolean = true;
  closable: boolean = true;
  closeOnEscape: boolean = true;

  constructor (init?: Partial<PopupSettings>) {
    Object.assign(this, init);
  }
}

export enum ToastType {
  Error = 'error',
  Info = 'info',
  Success = 'success',
  Warn = 'warn',
}