import { BaseProperty } from './baseProperty';

export class EntityProperty extends BaseProperty {
  availableEntities: EntityDefinition[];
  useList: boolean = true;

  constructor (init?: Partial<EntityProperty>) {
    super(init);
    Object.assign(this, init);

    this.type = 'EntityProperty';
  }
}

export class EntityDefinition {
  label: string;
  discriminator?: string;
  assignment?: Function;
  properties: BaseProperty[];

  constructor (init?: Partial<EntityDefinition>) {
    Object.assign(this, init);
  }
}