import { BaseProperty } from './baseProperty';

export class StringProperty extends BaseProperty {
  constructor (init?: Partial<StringProperty>) {
    super(init);
    Object.assign(this, init);

    this.type = 'StringProperty';
  }
}
