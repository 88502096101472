import { Component, OnInit } from '@angular/core';

import { faFile, faFolderOpen } from '@fortawesome/free-solid-svg-icons';

import { UserSettingsService, UserSettings } from 'src/app/services/userSettings.service';
import { ProjectService } from 'src/app/services/project.service';
import { GlobalService, PopupSettings, ToastType } from 'src/app/services/global.service';
import { CommonDataPoolService } from 'src/app/services/commonDataPool.service';

import { ProjectInfo } from 'src/app/entities/project';
import { HelperUtils } from 'src/app/utils/helperUtils';

@Component({
  selector: 'dge-el-dashboard',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.min.css']
})
export class ElementDashboardComponent implements OnInit {
  isLoading = true;
  hasError = false;
  errorMessage = '';

  projectHistory: ProjectInfo[];

  icon_faFile = faFile;
  icon_faFolderOpen = faFolderOpen;

  constructor(
    private commonDataPoolService: CommonDataPoolService,
    private globalService: GlobalService,
    private projectService: ProjectService,
    private userSettingsService: UserSettingsService // #TODO# - remove settings from this component
  ) { }

  ngOnInit() {
    this.init();
  }

  init() {
    this.hasError = false;
    this.isLoading = true;
    this.userSettingsService.load().subscribe((s: UserSettings) => {
      this.isLoading = false;
      if (s.projectHistory) {
        this.projectHistory = HelperUtils.copyObj(s.projectHistory);
        this.projectHistory.reverse();
      }
      // this.globalService.currentPopup = null;
    }, (err) => {
      this.hasError = true;
      this.errorMessage = err.message;
      this.isLoading = false;
    });
  }

  newProject() {
    this.projectService.initProject();
    this.globalService.currentPopup = null;
  }

  openDataBrowser() {
    this.globalService.currentPopup = null;
    setTimeout(() => {
      this.globalService.currentPopup = new PopupSettings({ type: 'dataBrowser' });      
    }, 250);
  }

  openProject(pi: ProjectInfo) {
    this.isLoading = true;
    this.commonDataPoolService.loadIteration(pi.project, pi.variant, pi.version, pi.iteration, pi.isLegacy).subscribe((r: any) => {
      this.isLoading = false;
      if (r.success) {
        this.globalService.currentPopup = null;
      } else {
        this.globalService.toast(ToastType.Error, 'Error while opening project.<br/>' + r.error.message);
      }
    });
  }
}
