import { Component, OnInit, Input, ViewChild, ElementRef, OnChanges, Output, EventEmitter } from '@angular/core';

import * as svgPanZoom from 'svg-pan-zoom';
import { SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'dge-ctrl-svg-viewer',
  templateUrl: './svgViewer.component.html',
  styleUrls: ['./svgViewer.component.min.css']
})
export class ControlSvgViewerComponent implements OnInit, OnChanges {
  _svg: string;
  @Input()
  set svg(value: string) {
    this._svg = value;
    this.initSvgPanZoom();
  }
  get svg() {
    return this._svg;
  }
  svgEl: HTMLElement;
  svgViewportEl: HTMLElement;

  // Spz = SvgPanZoom
  currentSpz: any = null;
  @Output() currentSpzChange = new EventEmitter<any>();

  // CSS
  customCss: SafeHtml = null;
  @Input() hiddenLayers: string[] = [];
  
  @ViewChild('container', { static: true }) container: ElementRef;

  // Measuring
  measuringInfo = {
    active: true,
    boundingBox: null as SVGRect,
    dimensions: null,

    lastClickPos: null,

    curClickStyle: null,
    lastClickStyle: null
  };
  measureLine: MeasureLine;
  @Output() distanceChange = new EventEmitter<any>();

  constructor() { 

  }

  ngOnInit() {
    this.measureLine = new MeasureLine();
  }
  
  ngOnChanges() {
    if (this.container.nativeElement && this.svg) {
      setTimeout(() => {
        this.svgEl = null;

        for (let i = 0; i < this.container.nativeElement.childNodes.length; i++) {
          if (this.container.nativeElement.childNodes[i].tagName === 'svg') this.svgEl = this.container.nativeElement.childNodes[i];
        }
        
        if (this.svgEl) {
          this.currentSpz = svgPanZoom(this.svgEl, {
            maxZoom: 320,
            onPan: this.onPan,
            onZoom: this.onZoom,
          });
          this.currentSpzChange.emit(this.currentSpz);

          this.svgViewportEl = this.svgEl.querySelector('.svg-pan-zoom_viewport');

          const bb = this.measuringInfo.boundingBox = (this.svgViewportEl as any).getBBox() as SVGRect;
          this.measuringInfo.dimensions = { 
            w: bb.width + bb.x * -1, 
            h: bb.height + bb.y * -1 
          };
          // console.log(this.measuringInfo.boundingBox, this.measuringInfo.dimensions);

          this.measureLine.init(this.svgViewportEl);
          // console.log(this.measureLine.el);

          // Test 
                
          // const testEl = document.createElement('line');
          // testEl.setAttribute('x1', this.measuringInfo.boundingBox.x.toString());
          // testEl.setAttribute('y1', this.measuringInfo.boundingBox.y.toString());
          // testEl.setAttribute('x2', this.measuringInfo.boundingBox.width.toString());
          // testEl.setAttribute('y2', this.measuringInfo.boundingBox.height.toString());
          // testEl.setAttribute('style', 'fill:none;vector-effect:non-scaling-stroke;stroke-linecap:round;stroke:red;stroke-width:5px;stroke-linejoin:round');
                
          // const testEl2 = document.createElement('line');

          // setTimeout(() => {
          //   this.svgViewportEl.appendChild(testEl2);   
          //   console.log(testEl2);         

          //   setTimeout(() => {           
          //     console.log('att')   
          //     testEl2.setAttribute('x1', '150.0819');
          //     testEl2.setAttribute('y1', '-1170.581');
          //     testEl2.setAttribute('x2', '169.2676');
          //     testEl2.setAttribute('y2', '-1346.9908');
          //     testEl2.setAttribute('style', 'fill:none;vector-effect:non-scaling-stroke;stroke-linecap:round;stroke:red;stroke-width:3px;stroke-linejoin:round;marker-start:url(#arrow);marker-end:url(#arrow)');
          //   }, 100);
          // }, 2000);

          // Hidden Layers
          this.setCustomCss();
        }
      }, 10);
    }
  }

  initSvgPanZoom() {

  }

  onPan(coords: { x: number, y: number }) {
    // console.log(coords.x, coords.y);
  }

  onZoom(lvl: number) {
    // console.log(lvl);    
  }

  getMeasurePointStyle(mp: MeasurePoint) {

  }

  containerOnClick(e: MouseEvent) {
    const doLog = false;

    const rect = this.container.nativeElement.getBoundingClientRect();
    const x1 = e.clientX - rect.x;
    const y1 = e.clientY - rect.y;
    this.measuringInfo.curClickStyle = { top: y1 + 'px', left: x1 + 'px' };
    if (doLog) console.log('cur click', 'x', x1, 'y', y1);

    if (this.measuringInfo.lastClickPos) {
      const x2 = this.measuringInfo.lastClickPos.x;
      const y2 = this.measuringInfo.lastClickPos.y;
      this.measuringInfo.lastClickStyle = { top: y2 + 'px', left: x2 + 'px' };
      if (doLog) console.log('prev click', 'x', x1, 'y', y1);

      const sD = this.measuringInfo.dimensions;
      if (doLog) console.log('svg', sD);

      // container dimensions
      const cR = this.container.nativeElement.getBoundingClientRect();
      const cD = { w: cR.width, h: cR.height };
      if (doLog) console.log('container', cD);

      // const zoom = this.currentSpz.getZoom(); // WRONG, get transform matrix instead
      const transform = this.svgViewportEl.getAttribute('transform');
      if (doLog) console.log('transform', transform);
      const matches: any = transform.match(/^matrix\(([0-9.]*),[0-9.]*,[0-9.]*,[0-9.]*,([-0-9.]*),([-0-9.]*)\)$/);
      if (doLog) console.log('matches', matches);
      const zoom = matches[1];
      const x = matches[2];
      const y = matches[3];

      // For later use: transform matrix offset
      // const tX = matches.groups['x'];
      // const tY = matches.groups['y'];

      const wRatio = sD.w / cD.w;

      const toSvgPoint = (p) => {
        return p / zoom;
      };

      // SVG points
      const sX1 = toSvgPoint(x1);
      const sY1 = toSvgPoint(y1);
      const sX2 = toSvgPoint(x2);
      const sY2 = toSvgPoint(y2);

      // distance
      const distance = Math.sqrt(Math.pow(Math.max(sX1, sX2) - Math.min(sX1, sX2), 2) + Math.pow(Math.max(sY1, sY2) - Math.min(sY1, sY2), 2));
      this.distanceChange.next(distance);
    }

    this.measuringInfo.lastClickPos = { x: x1, y: y1 };
  }

  setHiddenLayers(layers: string[]) {
    this.hiddenLayers = layers || [];

    this.setCustomCss();
  }

  setCustomCss() {        
    this.customCss = `
      <style type="text/css">
        ${this.hiddenLayers.map(hl => 'dge-ctrl-svg-viewer [l="' + hl + '"]').join(', ')} {
          display: none;
        }
      </style>
    `;
  }
}

// Measuring Classes

class MeasurePoint {
  x: number;
  y: number;
  el: HTMLElement;

  constructor(x: number = 0, y: number = 0) {
    this.x = x;
    this.y = y;
  }

  init() {
    this.el = document.createElement('line');    
  }
  
  update() {

  }
}

class MeasureLine {
  point1: MeasurePoint;
  point2: MeasurePoint;
  el: HTMLElement;
  parentEl: HTMLElement;

  constructor() {
    this.point1 = new MeasurePoint(-100, -500);
    this.point2 = new MeasurePoint(100, -500);
  }

  init(parentEl: HTMLElement) {
    this.parentEl = parentEl;

    this.point1.init();
    this.point2.init();

    this.el = document.createElement('line');
    this.update();
    this.el.setAttribute('style', 'fill:none;vector-effect:non-scaling-stroke;stroke-linecap:round;stroke:red;stroke-width:2px;stroke-linejoin:round;');

    parentEl.appendChild(this.point1.el);
    parentEl.appendChild(this.point2.el);
    parentEl.appendChild(this.el);
  }
  
  update() {
    this.point1.update();
    this.point2.update();

    this.el.setAttribute('x1', this.point1.x.toString());
    this.el.setAttribute('y1', this.point1.y.toString());
    this.el.setAttribute('x2', this.point2.x.toString());
    this.el.setAttribute('y2', this.point2.y.toString());
  }
}