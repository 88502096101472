
export enum NumericUnitType {

  m,
  mm,

  m2,
  mm2,

  g,
  kg,
  t,

  kgm2,
  tm2,

  'kg/m3',

  s,

  'VAs/m3',
  'kVAmin/m3',

  in,
  in2,

  VA,
  kVA,
  MVA,
  MVAr,

  '1/s',
  '1/min',
  '1/hr',
  Hz,

  W,
  kW,
  MW,

  V,
  kV,

  Vs,

  A,

  N,
  kN,
  'N/m',
  'kN/mm',
  'N/m2',
  'N/mm2',

  Nm,
  kNm,

  'm/s',

  'm3/s',
  'm3/h',

  Ohm,

  rad,
  deg,

  C,
  K,

  pu,
  pu_s,

  'A/m',
  'A/cm',
  'A/in',

  'A/m2',
  'A/mm2',

  'A2/m3',
  'A2/cm/mm2',

  'W/m/K',

  T,

  'J/kg/K',

  bar,
  Pa,
  kPa,
  GPa,
  psi,
  F,
  µF,

  Pct,

  Pcs,

  'euro' = '€'
}
