import { BehaviorSubject } from "rxjs";

import { ProjectService } from "../services/project.service";

import { CDPProject, CDPVariant, CDPVersion, CDPIteration } from "./serviceTypes/index.datapool";

// METADATA ==============================================================

export class ProjectMetaData {
  favorites: string[] = [];
  values: {
    [key: string]: PropertyMetaData
  } = {};
}

export class PropertyMetaData {
  lineKey: string;
  comment: string = null;
  color: number;

  isEmpty() {
    return !this.comment && !this.color;
  }
}

// ERROR ==============================================================

export class ProjectError {
  key: string;
  message: string = null;
  timestamp: Date;

  constructor (init?: Partial<ProjectError>) {
    Object.assign(this, init);
  }
}

export class ProjectErrorController {
  public list: ProjectError[] = [];
  public changed: BehaviorSubject<ProjectError[]>;

  constructor(
    private projectService: ProjectService
  ) {
    this.changed = this.projectService.errorsChanged;
  }

  add(key: string, message: string) {
    this.list.push(new ProjectError({ key: key, message: message, timestamp: new Date }));
    this.changed.next(this.list);
  }

  clear() {
    this.list = [];
    this.changed.next(this.list);
  }
}

// INFO ==============================================================

export class ProjectInfo {
  project: CDPProject;
  variant: CDPVariant;
  version: CDPVersion;
  iteration: CDPIteration;
  isLegacy: boolean;

  constructor(
    project: CDPProject,
    variant: CDPVariant,
    version: CDPVersion,
    iteration: CDPIteration,
    isLegacy: boolean
  ) {
    this.project = project;
    this.variant = variant;
    this.version = version;
    this.iteration = iteration;
    this.isLegacy = isLegacy;
  }
}