import { BaseProperty } from './baseProperty';
import { NumericUnitType } from '../units/numericUnitType';

export class SelectProperty extends BaseProperty {
  public availableValues: SelectOption[] = [];
  public editable: boolean = false;
  public displayUnit: NumericUnitType = null;

  constructor (init?: Partial<SelectProperty>) {
    super(init);
    Object.assign(this, init);

    this.type = 'SelectProperty';
  }
}

export class SelectOption {
  public label: string;
  public value: any;
  public hidden?: boolean = false;

  constructor (init?: Partial<SelectOption>) {
    Object.assign(this, init);
  }
}

export class SelectTypeProperty extends SelectProperty {
  public availableValues: SelectTypeOption[];
  public options: SelectPropertyOptions = new SelectPropertyOptions();

  constructor (init?: Partial<SelectTypeProperty>) {
    super(init);
    Object.assign(this, init);

    this.type = 'SelectTypeProperty';
  }
}

export class SelectTypeOption extends SelectOption {
  public label: string = null;
  public value: any = null;
  public assignment?: Function = null;
  public hidden?: boolean = false;

  constructor (init?: Partial<SelectTypeOption>) {
    super();
    Object.assign(this, init);
  }
}

export class SelectPropertyOptions {
  /** keepProperties: Can be defined to keep specific properties when switching between types. */
  public keepProperties: KeepPropertyConfig[];

  constructor (init?: Partial<SelectPropertyOptions>) {
    Object.assign(this, init);
  }
}

export class KeepPropertyConfig {
  forTypes: string[];
  properties: string[];

  constructor (init?: Partial<KeepPropertyConfig>) {
    Object.assign(this, init);
  }
}
