import { Component, OnInit, ElementRef, ViewChild, Input, Output, EventEmitter } from '@angular/core';

import { ProjectService } from '../../services/project.service';
import { GlobalService, PopupSettings } from 'src/app/services/global.service';
import { ControlBaseComponent } from './baseCtrl.component';
import { UserSettingsService } from 'src/app/services/userSettings.service';
import { PropertyUtils } from 'src/app/utils/propertyUtils';
import { NumericPropertyOptions } from 'src/app/entities/propertyTypes/numericProperty';
import { NumericUnitType } from 'src/app/entities/units/numericUnitType';
import { CustomListProperty } from 'src/app/entities/propertyTypes/customListProperty';

@Component({
  selector: 'dge-ctrl-customlist',
  templateUrl: './customListInput.component.html',
  styleUrls: ['./customListInput.component.min.css']
})
export class ControlCustomListInputComponent extends ControlBaseComponent implements OnInit {
  _value: string;
  @Input() set value(val: string) {
    this._value = val;
  }
  get value() {
    return this._value;
  }
  @Input() property: CustomListProperty;
  @Output() valueChange = new EventEmitter<any>();
  // @ViewChild('overlay', { static: false }) overlay: ElementRef;

  displayValue: string;

  numericOptions = {
    'puN3': new NumericPropertyOptions({ baseUnit: NumericUnitType.pu, viewUnit: NumericUnitType.pu, countDecimals: 3, countDecimalsMin: 3 }),
    'm2N2': new NumericPropertyOptions({ baseUnit: NumericUnitType.m2, viewUnit: NumericUnitType.m2, countDecimals: 3, countDecimalsMin: 3 }),
    'mm2N1': new NumericPropertyOptions({ baseUnit: NumericUnitType.m2, viewUnit: NumericUnitType.mm2, countDecimals: 1, countDecimalsMin: 1 }),
    'mmN1': new NumericPropertyOptions({ baseUnit: NumericUnitType.m, viewUnit: NumericUnitType.mm, countDecimals: 1, countDecimalsMin: 1 }),
    'mmN2': new NumericPropertyOptions({ baseUnit: NumericUnitType.m, viewUnit: NumericUnitType.mm, countDecimals: 2, countDecimalsMin: 2 }),
    'radN3': new NumericPropertyOptions({ baseUnit: NumericUnitType.rad, viewUnit: NumericUnitType.deg, countDecimals: 3 }),
    'oneN0': new NumericPropertyOptions({ countDecimals: 0 })
  };

  constructor(
    protected globalService: GlobalService,
    protected projectService: ProjectService,
    protected userSettingsService: UserSettingsService
  ) { 
    super(globalService, projectService, userSettingsService);
  }

  ngOnInit() {
    this.getValue();
    
    super.ngOnInit();
  }

  // Events ---------------

  onFocus(e) {
    this.setFocus();
  }

  // DISPLAY 

  getUnit(options: NumericPropertyOptions) {
    return PropertyUtils.getMeasurementSystemUnitLabel(this.userSettingsService.getMeasurementSystem(), options.viewUnit || options.baseUnit);
  }

  getBoolToYesNo(b: boolean) {
    return b ? "YES" : "NO";
  }

  // Methods -----------------

  getValue() {
    if (this.key) this.value = this.projectService.getDataValue(this.key);
  }
  
  getType(item: any) {
    return PropertyUtils.getDiscriminator(item) || this.property.defaultListItemType || '';
  }

  setFocus() {
    if (!this.readOnly && !this.isFocussed) {
      if (!this.disableKeyChange) {
        this.globalService.focussedCtrlKey = this.ctrlKey;
        this.globalService.focussedLineKey = this.lineKey;
      }
      
      this.carryOverOutput();
    }
  }

  carryOverOutput() {
    alert('nyi');
  }

  clearInput() {
    alert('nyi');
  }
}
