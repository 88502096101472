import { Component, OnInit, HostListener } from '@angular/core';
// import { OAuthService, OAuthErrorEvent } from 'angular-oauth2-oidc';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { ConfirmationService} from 'primeng/api';
import { faCalculator, faSave, faAngleDown, faFileExport, faDraftingCompass, faFile, faEllipsisH, faCogs, faPencilRuler, faMinusSquare, faPlusSquare } from '@fortawesome/free-solid-svg-icons';

import { CalculationService } from 'src/app/services/calculation.service';
import { CommonDataPoolService } from 'src/app/services/commonDataPool.service';
import { ContextMenuService, ContextMenuOptions } from 'src/app/services/contextMenu.service';
import { GlobalService, GlobalMessage, PopupSettings, ToastType } from 'src/app/services/global.service';
import { ProjectService } from 'src/app/services/project.service';

import { ProjectInfo } from 'src/app/entities/project';
import { CDPIteration } from 'src/app/entities/serviceTypes/index.datapool';
import { DgeModuleCallResponse } from 'src/app/entities/dgeModule';

import {saveAs} from "file-saver";

import { PROPERTIES_GEARS } from 'src/app/config/properties/gears';
import { PROPERTIES_SCREWCON } from 'src/app/config/properties/screwcon';
import { UserSettingsService, UserSettings } from 'src/app/services/userSettings.service';
import { GearsCalculationInput } from 'src/app/entities/serviceTypes';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'dge-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.min.css'],
  animations: [
    trigger('appear', [
      state('in', style({
        opacity: '1',
        transform: 'translateY(0)'
      })),
      transition('void => *', [
        style({
          opacity: '0',
          transform: 'translateY(10px)'
        }),
        animate('200ms ease')
      ]),
      transition('* => void', [
        animate('200ms ease', style({
          opacity: '0',
          transform: 'translateY(10px)'
        }))
      ])
    ])
  ]
})
export class LayoutComponent implements OnInit {

  debugMode: boolean = false;

  public showWaitInfo = false;
  messageText: string;
  messageType: string;

  projectInfo: ProjectInfo;

  modulesInitialized: string[] = ['Gears']; //, 'Screwcon'

  footerOpened: boolean = false;
  footerCurrentTab: string;

  menuItems = [
    { label: 'Dashboard', icon: 'pi pi-fw pi-home', command: (e: Event) => this.menuClick('dashboard') },
    { separator: true },
    { label: 'New Project', icon: 'pi pi-fw pi-file', command: (e: Event) => this.menuClick('newProject') },
    { label: 'Data Browser', icon: 'pi pi-fw pi-folder-open', command: (e: Event) => this.menuClick('dataBrowser') },
    { separator: true },
    { label: 'Settings', icon: 'pi pi-fw pi-cog', command: (e: Event) => this.menuClick('settings') }
  ];

  icon_faSave = faSave;
  saveEnabled = false;
  saveTooltip: string = '';

  icon_faCalculate = faCalculator;
  icon_faCalculateSynthesis = faDraftingCompass;
  icon_faAngleDown = faAngleDown;

  icon_gears_export_xml = faFileExport;

  icon_screwcon_export_acad = faPencilRuler;
  icon_screwcon_transferToGears = faCogs;

  icon_expand = faPlusSquare;
  icon_collapse = faMinusSquare;
  helpTabExpanded: boolean = true;

  isAuthorized = false;

  gearsTabActive = true;
  get canExportGearsAsXml() {
    return this.gearsTabActive === true && this.projectService.getDataValue(PROPERTIES_GEARS["GearsCalculationOutput.gearPower"].outputKey) > 0;
  }

  screwconTabActive = false;
  get canExportScrewconToAutoCad() {

    const stackerAndReclaimer = new Number(this.projectService.getDataValue(PROPERTIES_SCREWCON["ScrewconCalculationInput.stackerAndReclaimer"].inputKey));
    const screwOutputKey = "ScrewconCalculationOutput.screw";

    return this.screwconTabActive === true &&
           this.projectService.getDataValue(screwOutputKey) !=null &&
           stackerAndReclaimer == 0;
  }

  get canTransferToGears()
  {
    const screwOutputKey = "ScrewconCalculationOutput.screw";
    return this.screwconTabActive === true && this.projectService.getDataValue(screwOutputKey) != null;
  }

  constructor(
    // private oauthService: OAuthService,
    private calculationService: CalculationService,
    private commonDataPoolService: CommonDataPoolService,
    private confirmationService: ConfirmationService,
    private contextMenuService: ContextMenuService,
    private globalService: GlobalService,
    private projectService: ProjectService,
    private configService: ConfigService,
    private userSettingsService: UserSettingsService,
    private router: Router
  ) {
    this.globalService.globalMessageChanged.subscribe((gm: GlobalMessage) => {
      this.messageText = gm ? gm.text : '';
      this.messageType = gm ? gm.type : '';
    });

    this.projectService.currentProjectChanged.subscribe((pi: ProjectInfo) => {
      if (pi) {
        this.projectInfo = pi;
        this.saveTooltip = (!pi || !pi.isLegacy) ? 'Save new iteration' : 'Saving is disabled for legacy projects.';
      } else {
        this.projectInfo = null;
        this.saveTooltip = '';
      }
      this.saveEnabled = !!pi && !pi.isLegacy;
    });

    this.userSettingsService.settingsChanged.subscribe((settings: UserSettings) => {
      if (settings) {
        this.debugMode = settings.debugMode || false;
      }
    });

    this.router.events.subscribe(
      (event) => {

        if (event instanceof NavigationStart) {
          this.showWaitInfo = true;
        }
        if (event instanceof NavigationEnd
          || event instanceof NavigationCancel
          || event instanceof NavigationError) {
          this.showWaitInfo = false;
        }
      }
    );
  }

  ngOnInit() {
    // if (this.oauthService.getAccessToken()) {
    // }
    this.helpTabWidthCalculation();
    this.globalService.currentPopup = new PopupSettings({ type: 'dashboard', closable: true, closeOnEscape: false });
  }

  @HostListener('document:keyup', ['$event'])
  onDocumentKeyup(e: KeyboardEvent) {
    // CTRL-Space to recalculate
    if (e.ctrlKey && e.which === 32) {
      this.calculate();
      e.preventDefault();
      e.stopPropagation();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.helpTabWidthCalculation();
  }

  // Expand help tab if window width is equal/greater than 1200px
  helpTabWidthCalculation(): void {
    const screenWidth = window.innerWidth;
    this.helpTabExpanded = screenWidth >= 1200;
  }

  // Auth

  login() {

  }

  private storageRead(key: string): any {
    const data = localStorage.getItem(key);
    if (data != null) {
      return JSON.parse(data);
    }

    return;
  }

  private storageWrite(key: string, value: any): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  // MODULES ----------------------------------------------
  onTabChange(key) {
    if (this.modulesInitialized.indexOf(key) < 0)
    {
      this.modulesInitialized.push(key);
    }

    this.gearsTabActive = key == "Gears";
    this.screwconTabActive = key == "Screwcon";
  }

  showModuleTab(key: string) {
    return !(this.configService.get('disabledModules') || []).includes(key.toLowerCase());
  }

  isModuleInitialized(key: string) {
    return this.modulesInitialized.indexOf(key) >= 0;
  }

  // Layout Stuff

  onFooterTabClicked(key) {
    if (!this.footerOpened) {
      this.footerOpened = true;
    } else {
      if (this.footerCurrentTab === key) this.footerOpened = false;
    }
    this.footerCurrentTab = key;
  }

  toggleFooter(status) {
    this.footerOpened = status || !this.footerOpened;
  }

  getContainerClass() {
    const r = {};
    if (this.footerOpened) r['x-footer-opened'] = true;
    return r;
  }

  getFooterClass() {
    const r = {};
    if (this.footerOpened) r['x-footer-opened'] = true;
    return r;
  }

  openMenu(e) {
    if (!this.contextMenuService.isDisabled) {
      const options = new ContextMenuOptions();
      options.class = '';
      options.offset = 10;
      options.items = this.menuItems;
      options.position = { x: e.clientX, y: e.clientY };
      this.contextMenuService.options = options;
    }
  }

  menuClick(item: string) {
    switch (item) {
      case 'dashboard':
        this.globalService.currentPopup = new PopupSettings({ type: 'dashboard' });
        break;

      case 'newProject':
        if (this.projectService.hasChanges) {
          this.confirmationService.confirm({
            message: 'Are you sure you want to initialize a new project?<br>All unsaved data will be lost.',
            accept: () => {
              this.projectService.initProject();
              this.globalService.setGlobalMessage('New project initialized!', 'info');
            },
          });
        } else {
          this.projectService.initProject();
          this.globalService.setGlobalMessage('New project initialized!', 'info');
        }
        break;

      case 'dataBrowser':
        this.globalService.currentPopup = new PopupSettings({ type: 'dataBrowser' });
        break;

      case 'settings':
        this.globalService.currentPopup = new PopupSettings({ type: 'settings' });
        break;

      case 'calculate':
        this.projectService.checkForApiCalls(null);
        break;
    }
    this.contextMenuService.options = null;
  }

  saveNewIteration() {
    if (this.saveEnabled) {
      const curPrj = this.projectService.currentProject;
      this.commonDataPoolService.saveNewIteration(curPrj.version.id).subscribe((r: CDPIteration) => {
        this.projectService.setProjectInfo(curPrj.project, curPrj.variant, curPrj.version, r ? r : null);
      });
    }
  }

  calculate() {
    this.calculateStart();
  }

  private doGearsCalc()
  {
    this.calculationService.doGearsCalculation().subscribe((res: DgeModuleCallResponse) => {
      this.projectService._debugLatestOutput.gears = res.response;
      this.projectService.dataChanged.next(res.dataChangedValue);

      if (res.globalMessage) this.projectService.globalService.setGlobalMessage(res.globalMessage.message, res.globalMessage.type);
      if (res.toastMessage) this.projectService.globalService.toast(res.toastMessage.type, res.toastMessage.message);
    });
  }

  private doScrewConCalc()
  {
    this.calculationService.doScrewconCalculation().subscribe((res: DgeModuleCallResponse) => {
      this.projectService._debugLatestOutput.screwcon = res.response;
      this.projectService.dataChanged.next(res.dataChangedValue);

      if (res.globalMessage) this.projectService.globalService.setGlobalMessage(res.globalMessage.message, res.globalMessage.type);
      if (res.toastMessage) this.projectService.globalService.toast(res.toastMessage.type, res.toastMessage.message);
    });
  }

  calculateStart() {

    this.doGearsCalc();

    this.doScrewConCalc();

  }

  gearsDownloadAsXml()  {

    if(this.canExportGearsAsXml)
    {
      this.downloadAsXmlStart();
    }

  }

  downloadAsXmlStart() {
    this.calculationService.exportGearsCalculationAsXml().subscribe((res: DgeModuleCallResponse) => {

      if (res.globalMessage) this.projectService.globalService.setGlobalMessage(res.globalMessage.message, res.globalMessage.type);
      if (res.toastMessage) this.projectService.globalService.toast(res.toastMessage.type, res.toastMessage.message);

      const projectNumber = this.projectService.getDataValue(PROPERTIES_GEARS["GearsCalculationInput.projectNumber"].inputKey);
      const equipmentNumber = this.projectService.getDataValue(PROPERTIES_GEARS["GearsCalculationInput.equipmentNumber"].inputKey);

      saveAs(res.response, `${projectNumber}-${equipmentNumber}-gea.xml`);

    });
  }

  screwconExportAutoCad()
  {
    if(this.canExportScrewconToAutoCad)
    {
      const stackerAndReclaimer = new Number(this.projectService.getDataValue(PROPERTIES_SCREWCON["ScrewconCalculationInput.stackerAndReclaimer"].inputKey));

      if(stackerAndReclaimer == 1)
      {
        this.projectService.globalService.toast(ToastType.Warn, "A-CAD file creation is not supported for Stacker and Reclaimer.");
        return;
      }

      const fa = new Number(this.projectService.getDataValue('ScrewconCalculationOutput.axialForce'));
      if(fa > 76000)
      {
        this.projectService.globalService.toast(ToastType.Warn, "Check bearing: axial load too big. Axial load: " + fa.toFixed(0) + " N");
      }

      this.screwconExportAutoCadStart();
    }
  }

  screwconExportAutoCadStart()
  {
    this.calculationService.exportScrewconCalculationToAutoCadFile().subscribe((res: DgeModuleCallResponse) => {

      if (res.globalMessage) this.projectService.globalService.setGlobalMessage(res.globalMessage.message, res.globalMessage.type);
      if (res.toastMessage) this.projectService.globalService.toast(res.toastMessage.type, res.toastMessage.message);

      if(this.projectService.errors.list.length == 0)
      {
        const gearsData = this.projectService.project.data.gearsCalculationInput;
        
        let name = "OUTSCREW.DAT";
        if (!!gearsData.projectNumber && !!gearsData.equipmentNumber) {
          name = `${gearsData.projectNumber}-${gearsData.equipmentNumber}.dat`
        }
        
        saveAs(res.response, name, );
      }
    });
  }

  screwconTransferToGears()
  {
    if(this.canTransferToGears)
    {
      const gearsOutputPower = new Number(this.projectService.getDataValue(PROPERTIES_GEARS["GearsCalculationInput.requiredOutputPower"].inputKey));

      if(gearsOutputPower > 0)
      {
        this.confirmationService.confirm({
          message: 'Are you sure you want to transfer data to Gears? Existing data will be overwritten.',
          accept: () => {
            this.copyToGears();
          },
        });
      }
      else
      {
        this.copyToGears();
      }
    }
  }

  private copyToGears()
  {
    this.projectService.setDataValue(PROPERTIES_GEARS["GearsCalculationInput.requiredOutputPower"].inputKey, this.projectService.getDataValue(PROPERTIES_SCREWCON["ScrewconCalculationOutput.calculatedPower"].outputKey), true, true);
    this.projectService.setDataValue(PROPERTIES_GEARS["GearsCalculationInput.outputSpeed"].inputKey, this.projectService.getDataValue(PROPERTIES_SCREWCON["ScrewconCalculationOutput.screwRotationSpeed"].outputKey), true, true);


    const motorSize = this.projectService.getDataValue(PROPERTIES_SCREWCON["ScrewconCalculationOutput.motorSize"].outputKey);

    this.projectService.setDataValue(PROPERTIES_GEARS["GearsCalculationInput.installedMotorPower"].inputKey, motorSize, true, true);
    this.projectService.valueChanged.next({ key: 'GearsCalculationInput.installedMotorPower', value: motorSize });

    this.projectService.globalService.toast(ToastType.Info, "Transfer to Gears complete.");

    this.doGearsCalc();
  }

  log(foo) {
    console.log(foo);
  }

}
