import { PROPERTIES_GEARS } from './gears';
import { PROPERTIES_SCREWCON } from './screwcon';
import { PROPERTIES_SCREWREC } from './screwrec';

export const PROPERTIES = {};

function assignSearchGroup(props, searchGroupName: string) {
  for (const prop in props) {
    if (props.hasOwnProperty(prop)) props[prop].searchGroup = searchGroupName;
  }
}

assignSearchGroup(PROPERTIES_GEARS, 'gears');
assignSearchGroup(PROPERTIES_SCREWCON, 'screwcon');
assignSearchGroup(PROPERTIES_SCREWREC, 'screwrec');

Object.assign(PROPERTIES, PROPERTIES_GEARS, PROPERTIES_SCREWCON, PROPERTIES_SCREWREC);

