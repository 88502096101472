var torque;

torque = {
  kNm: {
    name: {
      singular: 'kilo newton metre',
      plural: 'kilo newton metres'
    },
    to_anchor: 1e3
  }, 
  Nm: {
    name: {
      singular: 'newton metre', 
      plural: 'newton metres'
    },
    to_anchor: 1
  },
  N: {
    name: {
      singular: 'newton', 
      plural: 'newtons'
    },
    to_anchor: 1
  },
  kN: {
    name: {
      singular: 'kilo newton', 
      plural: 'kilo newtons'
    },
    to_anchor: 1e3
  }, 
  ['N/m']: {
    name: {
      singular: 'newton per meter', 
      plural: 'kilo newtons per meter'
    },
    to_anchor: 1
  },  
  ['kN/mm']: {
    name: {
      singular: 'kilo newton per millimetres', 
      plural: 'kilo newtons per millimetres'
    },
    to_anchor: 1.0e6
  },  
};


module.exports = {
  metric: torque,
  _anchors: {
    metric: {
      unit: 'Nm', 
      ratio: 1
    }
  }
};
