import { Component, OnInit, ElementRef, ViewChild, Input, Output, EventEmitter } from '@angular/core';

import { ProjectService } from '../../services/project.service';
import { GlobalService } from 'src/app/services/global.service';
import { ControlBaseComponent } from './baseCtrl.component';
import { UserSettingsService } from 'src/app/services/userSettings.service';
import { PropertyUtils } from 'src/app/utils/propertyUtils';

@Component({
  selector: 'dge-ctrl-string',
  templateUrl: './stringInput.component.html',
  styleUrls: ['./stringInput.component.min.css']
})
export class ControlStringInputComponent extends ControlBaseComponent implements OnInit {
  @Input() value: string;
  @Input() rows: number = 1;
  @Output() valueChange = new EventEmitter<any>();

  @ViewChild('overlay', { static: false }) overlay: ElementRef;
  @ViewChild('input', { static: false }) input: ElementRef;

  constructor(
    protected globalService: GlobalService,
    protected projectService: ProjectService,
    protected userSettingsService: UserSettingsService
  ) { 
    super(globalService, projectService, userSettingsService);
  }

  ngOnInit() {
    this.getValue();
    
    super.ngOnInit();
  }

  // Events ---------------

  onKeydown(e: KeyboardEvent) {
    if (e.code === 'Escape') {
      // ESC - Cancel
      if (this.carryOverOutputActive) this.carryOverOutputActive = false;

      this.globalService.focussedCtrlKey = null;
      this.globalService.focussedLineKey = null;

      this.disableOnChange = true;
      (this.input.nativeElement as HTMLInputElement).blur();
      this.disableOnChange = false;

      this.getValue();
      this.setDisplayValue();
    }
  }

  onChange(e) {
    if (!this.disableOnChange) {
      console.log('entered value: ', e.target.value);
      this.setValue(e.target.value);
      if (!this.disableKeyChange) this.globalService.focussedCtrlKey = null;
    }
  }

  onFocus(e) {
    this.setFocus();
  }

  onBlur(e) {
    this.isFocussed = false;
    if (this.carryOverOutputActive) {
      this.onChange(e);
      this.carryOverOutputActive = false;
    }
  }

  // Methods -----------------

  getValue() {
    if (this.key) this.value = this.projectService.getDataValue(this.key);
  }

  setDisplayValue() {
    if (this.input) (this.input.nativeElement as HTMLInputElement).value = this.value;
  }

  setValue(value: any) {
    this.value = value;
    this.storeValue();
  }

  storeValue() {
    if (this.key) this.projectService.setDataValue(this.key, this.value);
    this.valueChange.emit(this.value);
    console.log('value set to: ', this.value);
  }

  setFocus() {
    if (!this.readOnly && !this.isFocussed) {
      if (!this.disableKeyChange) {
        this.globalService.focussedCtrlKey = this.ctrlKey;
        this.globalService.focussedLineKey = this.lineKey;
      }
      
      this.carryOverOutput();

      setTimeout(() => {
        this.input.nativeElement.select();
      }, 1);
    }
  }

  carryOverOutput(storeValue?: boolean) {
    if (this.lineKey && !this.value) {
      const prop = PropertyUtils.findProperty(this.lineKey);

      if (prop && prop.type === 'StringProperty' && prop.outputKey) {
        this.value = this.projectService.getDataValue(prop.outputKey);
        if (storeValue) {
          this.storeValue();
        } else {
          if (this.value) this.carryOverOutputActive = true;
        }
        this.setDisplayValue();
      }
    }
  }

  clearInput() {
    this.value = null;
    this.storeValue();
  }
}
