import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import * as moment from 'moment';

import { ConfigService } from "./config.service";
import { GlobalService, ToastType } from "./global.service";
import { ProjectService } from './project.service';
import { ConfirmationService } from 'primeng/api';

import { FakeEndpoint } from './fakeEndpoint';
import { CDPProject, CDPVariant, CDPVersion, CDPIteration, CDPFlag, CDPFlagAssociation } from '../entities/serviceTypes/index.datapool';
import { HelperUtils } from '../utils/helperUtils';

@Injectable()
export class CommonDataPoolService {  
  private apiCommonDataPoolUrl =  this.configService.get('commonDataPoolUrl');
  private apiCalculationServiceUrl =  this.configService.get('calculationServiceUrl');
  private options = { 
    headers: new HttpHeaders({ 
      'Content-Type': 'application/json'
    })
  };

  private payloadContextConfig: any[] = [
    { name: 'gearsCalculationOutput', path: 'gearsCalculationOutput'},
    { name: 'gearsCalculationInput', path: 'gearsCalculationInput'},

    { name: 'screwconCalculationOutput', path: 'screwconCalculationOutput'},
    { name: 'screwconCalculationInput', path: 'screwconCalculationInput'},

    { name: 'screwrecCalculationOutput', path: 'screwrecCalculationOutput'},
    { name: 'screwrecCalculationInput', path: 'screwrecCalculationInput'}
  ];

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private confirmationService: ConfirmationService,
    private globalService: GlobalService,
    private projectService: ProjectService
  ) { 
    
  }

  // => Data Management ==================================================================================================

  getProjectData(): any {
    const data = {
      metaData: JSON.stringify(HelperUtils.objGetPath(this.projectService.project, 'metaData'))
    };

    this.payloadContextConfig.forEach(cfg => {
      data[cfg.name] = JSON.stringify(HelperUtils.objGetPath(this.projectService.project, 'data.' + cfg.path));
    });

    return data;
  }

  setProjectData(data: any, isLegacy: boolean = false) {
    const project: any = {
      data: {}
    };

    if (!isLegacy) {
      if (data.metaData) project.metaData = JSON.parse(data.metaData);

      this.payloadContextConfig.forEach(cfg => {
        if (data[cfg.name]) project.data[cfg.path] = JSON.parse(data[cfg.name]);
      });    
    } else {
      project.data = data;
    }

    this.projectService.setProject(project);
  }

  // => Project ==========================================================================================================

  getProjects() {
    if (this.configService.get('apiCallsEnabled')) {    
      return this.http.get(this.apiCommonDataPoolUrl + 'Projects', this.options);
    } else {
      const fakeAPI = new FakeEndpoint();
      return fakeAPI.getResponse('VOLTA/Project');
    }
  }

  saveProject(data: CDPProject) {
    if (data.id) {
      return this.updateProject(data);
    } else {
      return this.createProject(data);
    }
  }

  updateProject(data: CDPProject) {
    return this.http.put(this.apiCommonDataPoolUrl + 'projects', JSON.stringify(data), this.options);
  }

  createProject(data: CDPProject) {
    return this.http.post(this.apiCommonDataPoolUrl + 'projects', JSON.stringify(data), this.options);    
  }

  deleteProject(data: CDPProject, comment) {
    return this.http.delete(this.apiCommonDataPoolUrl + 'projects/' + data.id + "?reason=" + comment, this.options);    
  }

  // => Variant --------------------------------------

  getVariants(projectID: number): Observable<CDPVariant[]> {
    return this.http.get<CDPVariant[]>(this.apiCommonDataPoolUrl + 'Variants/VariantsByProjectId/' + projectID, this.options);
  }

  getVariant(variantID: number): Observable<CDPVariant> {
    return this.http.get<CDPVariant>(this.apiCommonDataPoolUrl + 'Variants/' + variantID, this.options);
  }

  saveVariant(data: CDPVariant) {
    if (data.id) {
      return this.updateVariant(data);
    } else {
      return this.createVariant(data);
    }
  }

  updateVariant(data: CDPVariant) {
    return this.http.put(this.apiCommonDataPoolUrl + 'variants/' + data.id, JSON.stringify(data), this.options);
  }

  createVariant(data: CDPVariant) {
    return this.http.post(this.apiCommonDataPoolUrl + 'variants', JSON.stringify(data), this.options);    
  }

  deleteVariant(data: CDPVariant, comment) {
    return this.http.delete(this.apiCommonDataPoolUrl + 'variants/' + data.id + "?reason=" + comment, this.options);    
  }

  // => Version --------------------------------------

  getVersions(variantID: number): Observable<CDPVersion[]> {
    return this.http.get<CDPVersion[]>(this.apiCommonDataPoolUrl + 'versions/VersionsByVariantId/' + variantID, this.options);
  }

  getVersion(versionID: number): Observable<CDPVersion> {
    return this.http.get<CDPVersion>(this.apiCommonDataPoolUrl + 'versions/' + versionID, this.options);
  }

  saveVersion(data: CDPVersion) {
    if (data.id) {
      return this.updateVersion(data);
    } else {
      return this.createVersion(data);
    }
  }

  updateVersion(data: CDPVersion) {
    return this.http.put(this.apiCommonDataPoolUrl + 'versions/' + data.id, JSON.stringify(data), this.options);
  }

  createVersion(data: CDPVersion) {
    return this.http.post(this.apiCommonDataPoolUrl + 'versions', JSON.stringify(data), this.options);    
  }

  deleteVersion(data: CDPVersion, comment) {
    return this.http.delete(this.apiCommonDataPoolUrl + 'versions/' + data.id + "?reason=" + comment, this.options);    
  }

  // => Iteration --------------------------------------

  getIterations(versionID: number): Observable<CDPIteration[]> {
    return this.http.get<CDPIteration[]>(this.apiCommonDataPoolUrl + 'iterations/IterationsByVersion/' + versionID, this.options);
  }

  getIteration(iterationID: number, doNotAddPayloads: boolean = false): Observable<CDPIteration> {
    let contexts = '';
    if (!doNotAddPayloads && this.payloadContextConfig.length > 0) contexts = '?' + this.payloadContextConfig.map(cfg => 'contexts=' + cfg.name).join('&');

    return this.http.get<CDPIteration>(this.apiCommonDataPoolUrl + 'iterations/GetWithPayloads/' + iterationID + contexts, this.options);
  }

  getLatestIteration(versionID: number): Observable<CDPIteration> {
    return this.http.get<CDPIteration>(this.apiCommonDataPoolUrl + 'iterations/LatestForVersion/' + versionID, this.options);
  }

  saveIteration(data: CDPIteration) {
    return this.createIteration(data);
  }

  saveNewIteration(versionID: number) {
    return new Observable((obs) => {
      const it = new CDPIteration(); 
      it.name = 'AUTO-It-' + moment().format('YYYY-MM-DD HH:mm:ss');
      it.versionId = versionID;
      it.childLinks = this.getProjectData();    
      it.createdBy = 'USER ID';

      this.saveIteration(it).subscribe((r) => {
        this.globalService.toast(ToastType.Success, 'Project successfully saved as "' + it.name + '".');
        obs.next(r);
      }, (err) => {
        this.globalService.toast(ToastType.Error, 'ERROR while saving project. Please check console.');
        obs.next(err);
      });
    });
  }

  createIteration(data: CDPIteration) {
    return this.http.post(this.apiCommonDataPoolUrl + 'iterations/PostWithPayloads', JSON.stringify(data), this.options);    
  }

  deleteIteration(data: CDPIteration, comment) {
    return this.http.delete(this.apiCommonDataPoolUrl + 'iterations/' + data.id + "?reason=" + comment, this.options);    
  }

  loadIteration(project: CDPProject, variant: CDPVariant, version: CDPVersion, iteration: CDPIteration, isLegacy: boolean) {
    return new Observable((obs) => {
      const loadCb = (r: CDPIteration) => {
        const setProject = (data) => {
          if (data) {
            this.setProjectData(data, isLegacy);
            this.projectService.setProjectInfo(project, variant, version, r ? r : null, isLegacy);
            this.globalService.toast(ToastType.Success, 'Project successfully loaded.');
            obs.next({ success: true });
          } else {
            this.globalService.toast(ToastType.Error, 'Loading project not possible: No data attached.');
            obs.next({ success: false, error: 'Loading project not possible: No data attached.' });
          }
        };

        if (!isLegacy) {
          // NON-Legancy
          setProject(r ? r.childLinks : null);
        } else {
          // LEGACY
          if (r && r['dataPayload']) {
            this.convertLegacyProject(r['dataPayload']).subscribe((res) => {
              setProject(res);
              this.projectService.checkForApiCalls(null);
            }, (err) => {
              obs.next({ success: false, error: err });
            });
          } else {
            obs.next({ success: false, error: 'Loading project not possible: No data attached.' });
          }
        }
      };

      const loadErrorCb = (err) => {
        obs.next({ success: false, error: err });
      };

      const confirmCb = () => {
        if (!isLegacy) {
          if (iteration) {
            this.getIteration(iteration.id).subscribe(loadCb, loadErrorCb);
          } else {
            this.getLatestIteration(version.id).subscribe((rIt: CDPIteration) => {              
              this.getIteration(rIt.id).subscribe(loadCb, loadErrorCb);
            }, loadErrorCb);
          }
        } else {
          this.getLegacyVersion(version.id).subscribe(loadCb, loadErrorCb);
        }
      };

      if (this.projectService.hasChanges) {        
        this.confirmationService.confirm({
          message: 'You have unsaved changes. Do you really want to continue?',
          accept: confirmCb
        });
      } else {
        confirmCb();
      }

    });
  }

  // => LEGACY  ==========================================================================================================

  getLegacyProjects() {
    if (this.configService.get('apiCallsEnabled')) {   
      const $obs = this.http.get(this.apiCommonDataPoolUrl + 'GECCOLegacy/projects', this.options);
      const $map = $obs.pipe(map((i: any) => {
        i.createdBy = i.created_By;
        return i;
      }));
      return $map;
    }
  }

  getLegacyVariants(projectID: number) {
    if (this.configService.get('apiCallsEnabled')) {    
      return this.http.get(this.apiCommonDataPoolUrl + 'GECCOLegacy/variants/ByProjectId/' + projectID, this.options);
    }
  }

  getLegacyVersions(variantID: number) {
    if (this.configService.get('apiCallsEnabled')) {    
      return this.http.get(this.apiCommonDataPoolUrl + 'GECCOLegacy/versions/ByVariantId/' + variantID, this.options);
    }
  }

  getLegacyVersion(versionID: number) {
    return this.http.get(this.apiCommonDataPoolUrl + 'GECCOLegacy/versions/' + versionID, this.options);
  }

  convertLegacyProject(xml: string) {
    return this.http.post(this.apiCalculationServiceUrl + 'Compatibility/ConvertGECCOInput', "'" + xml + "'", this.options);
  }

  // => MASTER DATA ==========================================================================================================

  getMasterData(): Observable<any> {
    if (this.configService.get('apiCallsEnabled')) {    
      return this.http.get(this.apiCommonDataPoolUrl + 'MasterData', this.options);
    }
    return null;
  }

  // => FLAGS ==========================================================================================================

  getFlagTypes(): Observable<CDPFlag[]> {
    return this.http.get<CDPFlag[]>(this.apiCommonDataPoolUrl + 'Flags', this.options);
  }

  getFlags(): Observable<CDPFlagAssociation[]> {
    return this.http.get<CDPFlagAssociation[]>(this.apiCommonDataPoolUrl + 'Flagging', this.options);
  }

  setFlag(iterationID: number, flagID: number): Observable<CDPFlagAssociation> {
    const url = `${this.apiCommonDataPoolUrl}Flagging?iterId=${iterationID}&flagId=${flagID}`;
    return this.http.post<CDPFlagAssociation>(url, null, this.options);
  }

  removeFlag(flagAssociationID: number): Observable<CDPFlagAssociation> {
    const url = `${this.apiCommonDataPoolUrl}Flagging/${flagAssociationID}`;
    return this.http.delete<CDPFlagAssociation>(url, this.options);
  }
}