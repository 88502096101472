export class HelperUtils {  
  // General Helpers --------------------------------------------------------

  static copyObj(source) {
    if (Object.prototype.toString.call(source) === '[object Array]') {
      const clone = [];
      for (let i = 0; i < source.length; i++) {
        clone[i] = this.copyObj(source[i]);
      }
      return clone;
    } else if (typeof (source) === 'object') {
      const clone = {};
      for (const prop in source) {
        if (source.hasOwnProperty(prop)) {
          clone[prop] = this.copyObj(source[prop]);
        }
      }
      return clone;
    } else {
      return source;
    }
  }

  static isNull(o) {
    return typeof o === 'undefined' || o === null;
  }

  static isObjEmpty(o) {
    if (!o) return true;
    if (typeof o !== 'object') return true;
    return Object.keys(o).length === 0;
  }

  static objHasPath(o: any, pathString: string) {
    const parts = pathString.split('.');

    let ret = o;

    for (let i = 0; i < parts.length; i++) {
      const part = parts[i];

      if (ret) {
        if (ret.hasOwnProperty(part)) { 
          ret = ret[part];
        } else {
          return false;
        }  
      } else {
        return false;
      }
    }
    return true;
  }

  static objGetPath(o: any, pathString: string) {
    const parts = pathString.split('.');

    let ret = o;

    for (let i = 0; i < parts.length; i++) {
      const part = parts[i];

      if (ret) {
        if (ret.hasOwnProperty(part)) { 
          ret = ret[part];
        } else {
          return null;
        }  
      } else {
        return null;
      }
    }
    
    return ret;
  }

  static cleanUpObj(o: any) {
    // Cleans first level of object to remove "undefined" properties
    for (const prop in o) {
      if (o.hasOwnProperty(prop) && o[prop] === undefined) {
        delete o[prop];
      }
    }
    return o;
  }

  // String Helpers --------------------------------------------------------

  static toFirstCharUpper(s: string) {
    if (!s) return s;
    return s.substr(0, 1).toUpperCase() + s.substr(1);
  }

  // Number Helpers --------------------------------------------------------

  static isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  static getPrecision(num) {
    if (!isFinite(num)) return 0;
    let e = 1, p = 0;
    while (Math.round(num * e) / e !== num) { 
      e *= 10; 
      p++; 
    }
    return p;
  }

  static round(value: number, precision: number): number {
    const multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  } 

  static cleanUpNumber(value: any, decimalSeparator: string, regExp: RegExp, separators: string[] = null) {
    if (value === null || typeof value === 'undefined') return null;

    value = value.toLowerCase().replace(regExp, '');

    const decSep = decimalSeparator;
    if (decSep !== '.') value = value.toString().replace(decSep, '.');

    return value;
  }
  
  precisionRound(number, precision) {
    const factor = Math.pow(10, precision);
    return Math.round(number * factor) / factor;
  }
}
