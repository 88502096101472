import { Component, HostListener, OnInit, OnDestroy } from '@angular/core';
import { MsalService, BroadcastService } from '@azure/msal-angular';

import { Subscription } from 'rxjs';

import { GlobalService } from './services/global.service';
import { ConfigService } from './services/config.service';

@Component({
  selector: 'dge-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  isDragActive = false;
  isDragActiveTO = null;

  subscriptions: Subscription[] = new Array();

  constructor(
    private broadcastService: BroadcastService, 
    private auth: MsalService, 
    private configService: ConfigService,
    private globalService: GlobalService
  ) {
    this.subscriptions.push(this.broadcastService.subscribe("msal:loginFailure", (_payload) => {
      console.error('loginFailure', _payload);
      this.auth.acquireTokenRedirect([this.configService.authConfig.aad.clientId]);
    }));
    this.subscriptions.push(this.broadcastService.subscribe("msal:acquireTokenFailure", (_payload) => {
      console.error('acquireTokenFailure', _payload, this.configService.authConfig);
      this.auth.acquireTokenRedirect([this.configService.authConfig.aad.clientId]);
    }));
    
    this.globalService.isDraggingChanged.subscribe(b => this.isDragActive = b);
  }

  ngOnInit() {
    
  }

  ngOnDestroy() {
    this.broadcastService.getMSALSubject().next(1);
    while (this.subscriptions.length > 0) {
      this.subscriptions.pop().unsubscribe();
    }
  }

  // Drag
  @HostListener('document:dragover', ['$event'])
  onDragOver($e) {
    $e.stopPropagation();
    $e.preventDefault();
    
    // only for Files
    if (!$e.dataTransfer || !$e.dataTransfer.types.some(t => t === 'Files')) return;

    const fnClear = () => {
      this.globalService.isDraggingChanged.next(false);
      this.isDragActive = false;
    };
  
    clearTimeout(this.isDragActiveTO);
    if (!$e.path.some((i: HTMLElement) => i.classList && i.classList.contains('dge-dropzone'))) {
      $e.dataTransfer.dropEffect = 'none';
      this.isDragActiveTO = setTimeout(fnClear, 300);
    } else {
      $e.dataTransfer.dropEffect = 'copy';
    }
    
    if (!this.globalService.isDraggingChanged.getValue()) this.globalService.isDraggingChanged.next(true);
    this.isDragActive = true;
  }

  @HostListener('document:drop', ['$event'])
  onDragDrop($e) {
    this.globalService.isDraggingChanged.next(false);
    this.isDragActive = false;
  }
}
