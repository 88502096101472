
import { NgModule, LOCALE_ID, ApplicationRef } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Modules
import { ReactiveComponentLoaderModule } from '@wishtack/reactive-component-loader';
import { SharedModule } from './app.module.shared';

// Arrays of components
import { FormComponents } from './app.components.forms';

// Entry Component
import { AppComponent } from './app.component';
import { MsalComponent } from './components/auth/msal.component';

// General Layout
import { LayoutComponent } from './components/layout/layout.component';
import { LayoutColumnEditComponent } from './components/layout/colEdit/index.component';
import { LayoutColumnViewComponent } from './components/layout/colView/index.component';
import { LayoutColumnFavsComponent } from './components/layout/colFavs/index.component';
import { LayoutColumnFavsPanelFavsComponent } from './components/layout/colFavs/panelFavs.component';
import { LayoutColumnFavsPanelSearchComponent } from './components/layout/colFavs/panelSearch.component';
import { LayoutColumnFavsPanelCommentsComponent } from './components/layout/colFavs/panelComments.component';
import { LayoutColumnFavsPanelHelpComponent } from './components/layout/colFavs/panelHelp.component';
import { LayoutFooterComponent } from './components/layout/footer/footer.component';

// Layout'ish Components
import { PopupControllerComponent } from './components/layout/general/popupController.component';
import { ContextMenuComponent } from './components/shared/contextMenu.component';

// Controls

import { AppRoutingModule } from './app.routing';

@NgModule({
  declarations: [
    // Components
    AppComponent,
    MsalComponent,

    // Layout'ish Components   
    PopupControllerComponent,
    ContextMenuComponent,

    LayoutComponent,
    LayoutColumnEditComponent,
    LayoutColumnViewComponent,
    LayoutColumnFavsComponent,
    LayoutColumnFavsPanelFavsComponent,
    LayoutColumnFavsPanelSearchComponent,
    LayoutColumnFavsPanelCommentsComponent,
    LayoutColumnFavsPanelHelpComponent,
    LayoutFooterComponent,

    ...FormComponents,
  ],
  imports: [
    // Angular Modules
    BrowserModule,
    BrowserAnimationsModule,
    
    // Modules
    SharedModule,
    AppRoutingModule, 

    // Lazy Loaded
    ReactiveComponentLoaderModule.forRoot(),
    ReactiveComponentLoaderModule.withModule({
      moduleId: 'gears',
      loadChildren: './modules/gears/gears.module#GearsModule'
    }),
    ReactiveComponentLoaderModule.withModule({
      moduleId: 'screwcon',
      loadChildren: './modules/screwcon/screwcon.module#ScrewconModule'
    }),
    ReactiveComponentLoaderModule.withModule({
      moduleId: 'screwrec',
      loadChildren: './modules/screwrec/screwrec.module#ScrewrecModule'
    })
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'en-GB' }
  ],
  entryComponents: [
    AppComponent,
    MsalComponent
  ]
})
export class AppModule {
  // see MsalComponent for details
   ngDoBootstrap(ref: ApplicationRef) {
    if (window !== window.parent && !window.opener) {
      ref.bootstrap(MsalComponent);
    } else {
       ref.bootstrap(AppComponent);
    }
   }
}
