import { BaseProperty } from './baseProperty';
import { NumericProperty, NumericPropertyOptions } from './numericProperty';
import { StringProperty } from './stringProperty';
import { ComposedProperty } from './composedProperty';
import { SelectProperty, SelectOption } from './selectProperty';

export class ListProperty extends BaseProperty {
  public columns: BaseProperty[];
  public options: ListPropertyOptions = new ListPropertyOptions();

  constructor (init?: Partial<ListProperty>) {
    super(init);
    Object.assign(this, init);

    this.type = 'ListProperty';
  }
}

export class ListPropertyOptions {
  mode: 'input' | 'output' = 'input';
  
  maxCount: number = null;
  allowEdit: boolean = true;
  /** Only usable if a single column is used. Values will be stored in an array instead of an object array. */
  convertToSimpleList: boolean = false;
  convertToArrayOfArrays: boolean = false;
  // #TODO# - Odd number condition?
  assignment?: Function = null;
  
  constructor (init?: Partial<ListPropertyOptions>) {
    Object.assign(this, init);
  }
}

export class StringPropertyColumn extends StringProperty {
  /** Use {{unit}} placeholder to show converted unit label. */
  public labelTemplate: string;

  constructor (init?: Partial<StringPropertyColumn>) {
    super(init);
    Object.assign(this, init);

    this.type = 'StringPropertyColumn';
  }
}

export class NumericPropertyColumn extends NumericProperty {
  /** Use {{unit}} placeholder to show converted unit label. */
  public labelTemplate: string;
  
  constructor (init?: Partial<NumericPropertyColumn>) {
    super(init);
    Object.assign(this, init);

    this.type = 'NumericPropertyColumn';
  }
}

export class ComposedPropertyColumn extends ComposedProperty {
  /** Use {{unit}} placeholder to show converted unit label. */
  public labelTemplate: string;
    
  constructor (init?: Partial<ComposedPropertyColumn>) {
    super(init);
    Object.assign(this, init);

    this.type = 'ComposedPropertyColumn';
  }
}

export class SelectPropertyColumn extends SelectProperty {
  /** Use {{unit}} placeholder to show converted unit label. */
  public labelTemplate: string;
  
  constructor (init?: Partial<SelectPropertyColumn>) {
    super(init);
    Object.assign(this, init);

    this.type = 'SelectPropertyColumn';
  }
}