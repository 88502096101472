var massPerArea;

massPerArea = {
  gm2: {
    name: {
      singular: 'Gram square meter', 
      plural: 'Grams square meter'
    }, 
    to_anchor: 1
  }, 
  kgm2: {
    name: {
      singular: 'Kilogram square meter', 
      plural: 'Kilograms square meter'
    }, 
    to_anchor: 1000
  }, 
  tm2: {
    name: { 
      singular: 'Tonne (metric) square meter', 
      plural: 'Tonnes (metric) square meter' 
    }, 
    to_anchor: 1000000
  }
};


module.exports = {
  metric: massPerArea, 
  _anchors: {
    metric: {
      unit: 'gm2', 
      ratio: 1
    }
  }
};
