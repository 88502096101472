import { Component, OnInit, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'dge-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.min.css']
})
export class PopupComponent implements OnInit, OnChanges {
  @Input() blockMode: 'full' | 'none' = 'full';
  @Input() visibilityMode: 'display' | 'visibility' = 'display';
  @Input() visible = false;
  styles: any;

  constructor() { }

  ngOnInit() {
    this.setStyles();
  }

  ngOnChanges() {
    this.setStyles();
  }

  setStyles() {
    this.styles = {};
    switch (this.visibilityMode) {
      case 'visibility':
        this.styles = { 'visibility': this.visible ? 'visible' : 'hidden' };
        break;
      default:
        this.styles = { 'display': this.visible ? 'flex' : 'none' };
    }
  }

}
