// https://angular.io/guide/attribute-directives

import { Directive, ElementRef, Input, HostListener } from '@angular/core';

import { ContextMenuService, ContextMenuOptions } from '../services/contextMenu.service';

import { MenuItem } from 'primeng/api';

@Directive({
  selector: '[dgeContextMenu]'
})
export class ContextMenuDirective {
  @Input() contextMenuEnabled: boolean = true;
  @Input() contextMenuType: string = '';
  @Input() contextMenuData: any;
  @Input() contextMenuPosition: 'right' | 'bottom' = 'right';
  @Input() contextMenuOffset: number = 0;
  @Input() contextMenuItems: MenuItem[] = null;

  options: ContextMenuOptions;

  constructor(
    private el: ElementRef,
    private contextMenuService: ContextMenuService
  ) { }

  @HostListener('contextmenu', ['$event'])
  onContextMenu(e: MouseEvent) {
    if (this.contextMenuEnabled && !this.contextMenuService.isDisabled) {
      this.options = new ContextMenuOptions();
      this.options.type = this.contextMenuType;
      this.options.data = this.contextMenuData;
      this.options.offset = this.contextMenuOffset;
      this.options.items = this.contextMenuItems;
      this.options.position = { x: e.clientX, y: e.clientY };
      this.contextMenuService.options = this.options;

      e.preventDefault();
      e.cancelBubble = true;
    }
  } 
}