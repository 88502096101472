import { Component, OnInit, Input } from '@angular/core';

import { environment } from '../../../environments/environment';

@Component({
  selector: 'dge-ctrl-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.min.css']
})
export class ControlImageComponent implements OnInit {
  @Input() src: string;
  @Input() urlType: string = 'help';

  imagePath = '';

  constructor() { }

  ngOnInit() {
    if (this.urlType && environment.imageUrls && environment.imageUrls[this.urlType]) {
      this.imagePath = environment.imageUrls[this.urlType] + this.src;
    } else {
      this.imagePath = this.src;
    }
  }
}
