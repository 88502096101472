import { NumericUnit } from '../../entities/units/numericUnit';
import { NumericUnitType } from '../../entities/units/numericUnitType';
import { MeasurementSystem } from '../../entities/units/measurementSystem';

export const NumericUnits = {
  [NumericUnitType.m]: new NumericUnit('m', 'm', MeasurementSystem.Metric, NumericUnitType.in),
  [NumericUnitType.mm]: new NumericUnit('mm', 'mm', MeasurementSystem.Metric, NumericUnitType.in),
  [NumericUnitType["m/s"]]: new NumericUnit('m/s', 'm/s', MeasurementSystem.Metric, null),


  [NumericUnitType.m2]: new NumericUnit('m2', 'm²', MeasurementSystem.Metric, NumericUnitType.in2),
  [NumericUnitType.mm2]: new NumericUnit('mm2', 'mm²', MeasurementSystem.Metric, NumericUnitType.in2),

  [NumericUnitType.g]: new NumericUnit('g', 'g', MeasurementSystem.Metric, null),
  [NumericUnitType.kg]: new NumericUnit('kg', 'kg', MeasurementSystem.Metric, null),
  [NumericUnitType.t]: new NumericUnit('t', 't', MeasurementSystem.Metric, null),

  [NumericUnitType.kgm2]: new NumericUnit('kgm2', 'kgm²', MeasurementSystem.Metric, null),
  [NumericUnitType.tm2]: new NumericUnit('tm2', 'tm²', MeasurementSystem.Metric, null),

  [NumericUnitType['m3/s']]: new NumericUnit('m3/s', 'm³/s', MeasurementSystem.Metric, null),
  [NumericUnitType['m3/h']]: new NumericUnit('m3/h', 'm³/h', MeasurementSystem.Metric, null),

  [NumericUnitType['kg/m3']]: new NumericUnit('kg/m3', 'kg/m³', MeasurementSystem.Metric, null),

  [NumericUnitType.s]: new NumericUnit('s', 's', MeasurementSystem.Metric, null),

  [NumericUnitType['VAs/m3']]: new NumericUnit('VAs/m3', 'VAs/m³', MeasurementSystem.Metric, null),
  [NumericUnitType['kVAmin/m3']]: new NumericUnit('kVAmin/m3', 'kVAmin/m³', MeasurementSystem.Metric, null),

  [NumericUnitType.in]: new NumericUnit('in', 'in', MeasurementSystem.Imperial, NumericUnitType.mm),

  [NumericUnitType.VA]: new NumericUnit('VA', 'VA', MeasurementSystem.None, null),
  [NumericUnitType.kVA]: new NumericUnit('kVA', 'kVA', MeasurementSystem.None, null),
  [NumericUnitType.MVA]: new NumericUnit('MVA', 'MVA', MeasurementSystem.None, null),
  [NumericUnitType.MVAr]: new NumericUnit('MVA', 'MVAr', MeasurementSystem.None, null),

  [NumericUnitType.W]: new NumericUnit('W', 'W', MeasurementSystem.None, null),
  [NumericUnitType.kW]: new NumericUnit('kW', 'kW', MeasurementSystem.None, null),
  [NumericUnitType.MW]: new NumericUnit('MW', 'MW', MeasurementSystem.None, null),

  [NumericUnitType.Hz]: new NumericUnit('Hz', 'Hz', MeasurementSystem.None, null),
  [NumericUnitType['1/s']]: new NumericUnit('Hz', '1/s', MeasurementSystem.None, null),
  [NumericUnitType['1/min']]: new NumericUnit('rpm', '1/min', MeasurementSystem.None, null),

  [NumericUnitType.V]: new NumericUnit('V', 'V', MeasurementSystem.None, null),
  [NumericUnitType.kV]: new NumericUnit('kV', 'kV', MeasurementSystem.None, null),

  [NumericUnitType.Vs]: new NumericUnit('Vs', 'Vs', MeasurementSystem.None, null),

  [NumericUnitType.A]: new NumericUnit('A', 'A', MeasurementSystem.None, null),

  [NumericUnitType.Nm]: new NumericUnit('Nm', 'Nm', MeasurementSystem.None, null),
  [NumericUnitType.kNm]: new NumericUnit('kNm', 'kNm', MeasurementSystem.None, null),

  [NumericUnitType.N]: new NumericUnit('N', 'N', MeasurementSystem.Metric, null),
  [NumericUnitType.kN]: new NumericUnit('kN', 'kN', MeasurementSystem.Metric, null),
  [NumericUnitType['N/m']]: new NumericUnit('N/m', 'N/m', MeasurementSystem.Metric, null),
  [NumericUnitType['kN/mm']]: new NumericUnit('kN/mm', 'kN/mm', MeasurementSystem.Metric, null),

  [NumericUnitType['N/m2']]: new NumericUnit('N/m2', 'N/m²', MeasurementSystem.Metric, null),
  [NumericUnitType['N/mm2']]: new NumericUnit('N/mm2', 'N/mm²', MeasurementSystem.Metric, null),

  [NumericUnitType.Ohm]: new NumericUnit('Ohm', 'Ω', MeasurementSystem.None, null),
  // [NumericUnitType.in2]: new NumericUnit('in2', 'in²', MeasurementSystem.Imperial, NumericUnitType.mm)

  [NumericUnitType.rad]: new NumericUnit('rad', 'rad', MeasurementSystem.None, null),
  [NumericUnitType.deg]: new NumericUnit('deg', '°', MeasurementSystem.None, null),

  [NumericUnitType.C]: new NumericUnit('C', '°C', MeasurementSystem.None, null),
  [NumericUnitType.K]: new NumericUnit('K', 'K', MeasurementSystem.None, null),

  [NumericUnitType.pu]: new NumericUnit('pu', 'pu', MeasurementSystem.None, null),
  [NumericUnitType.pu_s]: new NumericUnit('pu s', 'pu s', MeasurementSystem.None, null),

  [NumericUnitType['A/m']]: new NumericUnit('A/m', 'A/m', MeasurementSystem.Metric, NumericUnitType['A/in']),
  [NumericUnitType['A/cm']]: new NumericUnit('A/cm', 'A/cm', MeasurementSystem.Metric, NumericUnitType['A/in']),
  [NumericUnitType['A/in']]: new NumericUnit('A/in', 'A/in', MeasurementSystem.Imperial, NumericUnitType['A/m']),

  [NumericUnitType['A/m2']]: new NumericUnit('A/m2', 'A/m²', MeasurementSystem.Metric, null),
  [NumericUnitType['A/mm2']]: new NumericUnit('A/mm2', 'A/mm²', MeasurementSystem.Metric, null),


  [NumericUnitType['W/m/K']]: new NumericUnit('W/m/K', 'W/m/K', MeasurementSystem.Metric, null),

  [NumericUnitType.T]: new NumericUnit('T', 'T', MeasurementSystem.None, null),

  [NumericUnitType['J/kg/K']]: new NumericUnit('J/kg/K', 'J/kg/K', MeasurementSystem.Metric, null),

  [NumericUnitType.bar]: new NumericUnit('bar', 'bar', MeasurementSystem.Metric, NumericUnitType.Pa),
  [NumericUnitType.Pa]: new NumericUnit('Pa', 'Pa', MeasurementSystem.Metric, NumericUnitType.psi),
  [NumericUnitType.kPa]: new NumericUnit('kPa', 'kPa', MeasurementSystem.Metric, NumericUnitType.psi),
  [NumericUnitType.GPa]: new NumericUnit('GPa', 'GPa', MeasurementSystem.Metric, NumericUnitType.psi),
  [NumericUnitType.psi]: new NumericUnit('psi', 'psi', MeasurementSystem.Imperial, NumericUnitType.GPa),

  [NumericUnitType.F]: new NumericUnit('F', 'F', MeasurementSystem.Metric, null),

  [NumericUnitType.µF]: new NumericUnit('µF', 'µF', MeasurementSystem.Metric, null),

  [NumericUnitType["A2/cm/mm2"]]: new NumericUnit('A2/cm/mm2', 'A²/cm/mm²', MeasurementSystem.Metric, null),
  [NumericUnitType["A2/m3"]]: new NumericUnit('A2/m3', 'A²/m³', MeasurementSystem.Metric, null),

  [NumericUnitType.Pct]: new NumericUnit('Pct', '%', MeasurementSystem.Metric, null),

  [NumericUnitType.Pcs]: new NumericUnit('Pcs', 'pcs.', MeasurementSystem.Metric, null),

  [NumericUnitType["1/hr"]]: new NumericUnit('1/hr', '1/hr', MeasurementSystem.Metric, null),

  // Currencies
  [NumericUnitType.euro]: new NumericUnit('€', '€', MeasurementSystem.Metric, null)
};
