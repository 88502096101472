import { NumericProperty, NumericPropertyOptions } from "src/app/entities/propertyTypes/numericProperty";
import { SelectProperty } from "src/app/entities/propertyTypes/selectProperty";
import { StringProperty } from "src/app/entities/propertyTypes/stringProperty";
import { NumericUnitType } from "src/app/entities/units/numericUnitType";

export const PROPERTIES_SCREWREC = {
    // INPUTS
    'ScrewrecCalculationInput.projectNumber': new StringProperty({
        label: 'Project number',
        inputKey: 'ScrewrecCalculationInput.projectNumber',
        helpKey: 'ScrewrecCalculationInput.projectNumber'
    }),
    'ScrewrecCalculationInput.equipmentNumber': new StringProperty({
        label: 'Equipment number',
        inputKey: 'ScrewrecCalculationInput.equipmentNumber',
        helpKey: 'ScrewrecCalculationInput.equipmentNumber'
    }),
    'ScrewrecCalculationInput.reclaimerType': new SelectProperty({
        label: 'Reclaimer type',
        inputKey: 'ScrewrecCalculationInput.reclaimerType',
        helpKey: 'ScrewrecCalculationInput.reclaimerType',
        required: true,
    }),
    'ScrewrecCalculationInput.speedRegulation': new SelectProperty({
        label: 'Speed regulation',
        inputKey: 'ScrewrecCalculationInput.speedRegulation',
        helpKey: 'ScrewrecCalculationInput.speedRegulation',
        required: true,
    }),
    'ScrewrecCalculationInput.materialType': new SelectProperty({
        label: 'Material type',
        inputKey: 'ScrewrecCalculationInput.materialType',
        helpKey: 'ScrewrecCalculationInput.materialType',
        required: true,
    }),
    'ScrewrecCalculationInput.densityOfMaterial': new NumericProperty({
        label: 'Density of material',
        inputKey: 'ScrewrecCalculationInput.densityOfMaterial',
        helpKey: 'ScrewrecCalculationInput.densityOfMaterial',
        required: true,
        options: new NumericPropertyOptions({
            baseUnit: NumericUnitType['kg/m3']
        })
    }),
    'ScrewrecCalculationInput.maximumCapacity': new NumericProperty({
        label: 'Maximum capacity',
        inputKey: 'ScrewrecCalculationInput.maximumCapacity',
        helpKey: 'ScrewrecCalculationInput.maximumCapacity',
        required: true,
        options: new NumericPropertyOptions({
            baseUnit: NumericUnitType['m3/h']
        })
    }),
    'ScrewrecCalculationInput.minimumCapacity': new NumericProperty({
        label: 'Minimum capacity',
        inputKey: 'ScrewrecCalculationInput.minimumCapacity',
        helpKey: 'ScrewrecCalculationInput.minimumCapacity',
        options: new NumericPropertyOptions({
            baseUnit: NumericUnitType['m3/h']
        })
    }),
    'ScrewrecCalculationInput.materialPileHeight': new NumericProperty({
        label: 'Material pile height',
        inputKey: 'ScrewrecCalculationInput.materialPileHeight',
        helpKey: 'ScrewrecCalculationInput.materialPileHeight',
        required: true,
        options: new NumericPropertyOptions({
            baseUnit: NumericUnitType.m
        })
    }),

    // OUTPUTS
    'ScrewrecCalculationOutput.maxDrivePower': new NumericProperty({
        label: 'Maximum drive power',
        outputKey: 'ScrewrecCalculationOutput.maxDrivePower',
        helpKey: 'ScrewrecCalculationOutput.maxDrivePower',
        options: new NumericPropertyOptions({
            baseUnit: NumericUnitType.kW,
            countDecimals: 3
        })
    }),
    'ScrewrecCalculationOutput.minDrivePower': new NumericProperty({
        label: 'Minimum drive power',
        outputKey: 'ScrewrecCalculationOutput.minDrivePower',
        helpKey: 'ScrewrecCalculationOutput.minDrivePower',
        options: new NumericPropertyOptions({
            baseUnit: NumericUnitType.kW,
            countDecimals: 3
        })
    }),

    'ScrewrecCalculationOutput.maxRotation': new NumericProperty({
        label: 'Maximum rotation',
        outputKey: 'ScrewrecCalculationOutput.maxRotation',
        helpKey: 'ScrewrecCalculationOutput.maxRotation',
        options: new NumericPropertyOptions({
            baseUnit: NumericUnitType["1/min"],
            countDecimals: 3
        })
    }),
    'ScrewrecCalculationOutput.minRotation': new NumericProperty({
        label: 'Minimum rotation',
        outputKey: 'ScrewrecCalculationOutput.minRotation',
        helpKey: 'ScrewrecCalculationOutput.minRotation',
        options: new NumericPropertyOptions({
            baseUnit: NumericUnitType["1/min"],
            countDecimals: 3
        })
    }),

    'ScrewrecCalculationOutput.maxDriveTorque': new NumericProperty({
        label: 'Maximum drive torque',
        outputKey: 'ScrewrecCalculationOutput.maxDriveTorque',
        helpKey: 'ScrewrecCalculationOutput.maxDriveTorque',
        options: new NumericPropertyOptions({
            baseUnit: NumericUnitType.kNm,
            countDecimals: 3
        })
    }),
    'ScrewrecCalculationOutput.maxStartTorque': new NumericProperty({
        label: 'Maximum start torque',
        outputKey: 'ScrewrecCalculationOutput.maxStartTorque',
        helpKey: 'ScrewrecCalculationOutput.maxStartTorque',
        options: new NumericPropertyOptions({
            baseUnit: NumericUnitType.kNm,
            countDecimals: 3
        })
    }),
    'ScrewrecCalculationOutput.maxAxialForce': new NumericProperty({
        label: 'Maximum axial force',
        outputKey: 'ScrewrecCalculationOutput.maxAxialForce',
        helpKey: 'ScrewrecCalculationOutput.maxAxialForce',
        options: new NumericPropertyOptions({
            baseUnit: NumericUnitType.kN,
            countDecimals: 3
        })
    }),
}