import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { GlobalService, PopupSettings } from 'src/app/services/global.service';

import { PropertyUtils } from 'src/app/utils/propertyUtils';

@Component({
  selector: 'dge-popup-controller',
  templateUrl: './popupController.component.html',
  styleUrls: ['./popupController.component.min.css']
})
export class PopupControllerComponent implements OnInit, OnDestroy {
  popup: PopupSettings = null;
  title: string;
  titles = {
    'dashboard': 'KFT CAE Tools',
    'dataBrowser': 'Data Browser',
    'settings': 'User Settings',
    'kft-belt-chain-calc': 'Belt/chain output speed calculation'
  };

  _subscriptions: Subscription[] = [];

  constructor(
    private globalService: GlobalService
  ) { }

  ngOnInit() {
    this._subscriptions.push(this.globalService.currentPopupChanged.subscribe((popup: PopupSettings) => {
      this.setPopup(popup);
    }));
  }

  ngOnDestroy() {
    let sub: Subscription;
    while (sub = this._subscriptions.pop()) {
      if (!sub.closed) sub.unsubscribe();
    }
  }

  setPopup(popup: PopupSettings) {
    this.popup = popup;
    if (this.popup) {
      if (this.popup.title) this.title = this.popup.title;
      else if (this.popup.type === 'comment') this.title = PropertyUtils.findProperty(this.popup.data['lineKey']).label;
      else this.title = this.titles[popup.type];
    }
  }

  onDialogHide() {
    setTimeout(() => {
      this.globalService.currentPopup = null;
    }, 200);
  }
}
