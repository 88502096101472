import { Pipe, PipeTransform } from '@angular/core';
import * as convert from '../utils/convertUnitsModified';

import { UserSettingsService } from '../services/userSettings.service';

import { NumericPropertyOptions } from '../entities/propertyTypes/numericProperty';

import { NumericUnits } from '../config/units/numericUnits';

@Pipe({
  name: 'unitConversion',
  pure: false
})
export class UnitConversionPipe implements PipeTransform {
  constructor(private userSettingsService: UserSettingsService) { }

  transform(value: number, numValOpt: NumericPropertyOptions, reverse: boolean = false): number {
    if (value === null || typeof value === 'undefined' || value.toString() === '') return null;

    let ret: number = Number(value);
    if (isNaN(ret)) {
      // console.error('[UnitConversionPipe].transform: "' + value.toString() + '" is not a number.');
      return null;
    }

    if (numValOpt && numValOpt.baseUnit !== null) {
      const baseUnit = NumericUnits[numValOpt.baseUnit];
      if (!baseUnit) throw new ReferenceError('[UnitConversionPipe].transform: NumericUnitType(enum) "' + numValOpt.baseUnit.toString() + '" not defined as NumericUnit(config).');

      let viewUnit = NumericUnits[numValOpt.viewUnit] || baseUnit;
      if (viewUnit && this.userSettingsService.getMeasurementSystem() !== viewUnit.measurementSystem && viewUnit.convertsTo) {
        viewUnit = NumericUnits[viewUnit.convertsTo];
      }

      if (baseUnit.key !== viewUnit.key) {
        const fromUnit = reverse ? viewUnit : baseUnit;
        const toUnit = reverse ? baseUnit : viewUnit;
        ret = convert(ret).from(fromUnit.key).to(toUnit.key);
      }
    }

    return ret;
  }
}
