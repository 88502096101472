export abstract class BaseProperty {
  public type: string = 'BaseProperty';
  public label: string = null;
  public inputKey: string = null;
  public outputKey: string = null;
  public nullable: boolean = true;
  public required: boolean = false;
  public helpKey: string = null;
  public searchGroup: string = null;
  public disabled: boolean = false;
  
  constructor (init?: Partial<BaseProperty>) {
    Object.assign(this, init);
  }
}
