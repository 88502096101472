import { PROPERTIES } from '../config/properties/index';

import { BaseProperty } from '../entities/propertyTypes/baseProperty';
import { NumericPropertyOptions, NumericProperty } from '../entities/propertyTypes/numericProperty';
import { NumericUnitType } from '../entities/units/numericUnitType';
import { NumericUnits } from '../config/units/numericUnits';
import { MeasurementSystem } from '../entities/units/measurementSystem';
import { ComposedProperty } from '../entities/propertyTypes/composedProperty';
import { ListProperty } from '../entities/propertyTypes/listProperty';
import { ArrayProperty } from '../entities/propertyTypes/arrayProperty';
import { MultiProperty } from '../entities/propertyTypes/multiProperty';

import { UnitConversionPipe } from '../pipes/unitConversion.pipe';

import { UserSettingsService } from '../services/userSettings.service';
import { HelperUtils } from './helperUtils';

export class PropertyUtils {  
  // General Helpers --------------------------------------------------------

  static findProperty(key: string): BaseProperty {
    if (typeof PROPERTIES[key] !== 'undefined') {
      return PROPERTIES[key];      
    } else {
      // throw new ReferenceError(key);
    }
  }

  static getNumericRegExpString(decimalSeparator: string, allowNegatives: boolean = true, allowScientific: boolean = true, additionalChars: string = ''): string {
    return '[^0-9' + decimalSeparator + (allowNegatives ? '-' : '') + (allowScientific ? 'e+' : '') + additionalChars + ']';
  }

  // Value Labeling Helpers --------------------------------------------------------

  /** Returns the corresponding unit from the currently selected measurement system. */
  static getMeasurementSystemUnitLabel(currentSystem: MeasurementSystem, unitType: NumericUnitType): string {
    // #TODO# - display view unit
    if (unitType === null || typeof unitType === 'undefined') return '-';

    let unit = NumericUnits[unitType];
    if (!unit) return '-';
    if (unit.convertsTo && currentSystem !== unit.measurementSystem) unit = NumericUnits[unit.convertsTo];
    return unit.label;
  }

  static getTypeLabel(value: BaseProperty, returnAsHTML: boolean = false): string {
    let ret = '';

    switch (value.type) {
      case 'StringProperty':
        ret = 'String';
        break;

      case 'NumericProperty':
      case 'NumericPropertyColumn':
        const numValue: NumericProperty = value as NumericProperty;
        ret = `Numeric (${numValue.options.allowDecimals ? 'Double' : 'Int'})`;
        break;

      case 'ComposedProperty':
        const compValue: ComposedProperty = value as ComposedProperty;
        ret = `Composed (${this.getTypeLabel(compValue.value1, returnAsHTML)} ${compValue.separators[0]} ${this.getTypeLabel(compValue.value2, returnAsHTML)})`;
        break;

      case 'SelectProperty':
        ret = 'Select';
        break;

      case 'SelectTypeProperty':
        ret = 'Select';
        break;

      case 'ListProperty':
        const listProp: ListProperty = value as ListProperty;
        ret = `List (${listProp.columns.map(i => this.getTypeLabel(i)).join(', ')})`;
        break;

      case 'ArrayProperty':
        const arrProp: ArrayProperty = value as ArrayProperty;
        ret = `Array (${arrProp.numericOptions.allowDecimals ? 'Double' : 'Int'})`;
        break;

      case 'MultiProperty':
        const multiProp: MultiProperty = value as MultiProperty;
        if (returnAsHTML) ret = `Multi:<br>${this.getTypeLabel(multiProp.inputProperty, returnAsHTML)}<br>${this.getTypeLabel(multiProp.outputProperty, returnAsHTML)}`;
        else ret = `${this.getTypeLabel(multiProp.inputProperty, returnAsHTML)} / ${this.getTypeLabel(multiProp.outputProperty, returnAsHTML)}`;
        break;
    }

    return ret;
  }

  // Separated (Fraction/Composed) Helpers --------------------------------------------------------

  static getSeparatedValueSplit(
    value: any, 
    separators: string[], 
    userSettingsService: UserSettingsService, 
    cleanUpRegExp: RegExp, 
    options: NumericPropertyOptions[]
  ): number[] {
    if (HelperUtils.isNull(value) || value === '') return null;

    let cleanValue = HelperUtils.cleanUpNumber(value, userSettingsService.getDecimalSeparator(), cleanUpRegExp);

    // Check each separator and if included then remove others from string
    let usedSeparator = '';
    for (let si = 0; si < separators.length; si++) {
      if (cleanValue.indexOf(separators[si]) >= 0) {
        usedSeparator = separators[si];
        cleanValue = cleanValue.replace(new RegExp('[' + separators.slice(si + 1).join('') + ']', 'gi'), '');
      }
    }

    // Split up
    let nums: number[] = [parseFloat(cleanValue)];
    if (usedSeparator && cleanValue.indexOf(usedSeparator) >= 0) {
      nums = cleanValue.split(usedSeparator).map(Number);  
    }

    if (nums.length >= 1) {
      for (let i = 0; i < nums.length; i++) {
        const opt = options[i] || options[0];
        if (!HelperUtils.isNull(nums[i]) && opt && opt.baseUnit !== null) nums[i] = new UnitConversionPipe(userSettingsService).transform(nums[i], opt, true);
      }
      
      return nums;
    } else {
      return null;
    }
  }

  static getTwoValueSplit(
    value: any, 
    separators: string[], 
    userSettingsService: UserSettingsService, 
    cleanUpRegExp: RegExp, 
    optionsVal1: NumericPropertyOptions, 
    optionsVal2: NumericPropertyOptions
  ): number[] {
    const nums = this.getSeparatedValueSplit(value, separators, userSettingsService, cleanUpRegExp, [optionsVal1, optionsVal2]);

    if (nums && nums.length >= 1 && nums.length <= 2) {
      return nums;
    } else {
      return null;
    }
  }

  // API Types Helper --------------------------------------------------------

  static getDiscriminator(obj: any): any {
    if (!obj) return null;

    if (obj['discriminator']) return obj['discriminator'];
    if (obj['_discriminator']) return obj['_discriminator'];

    return null;
  }

  // MFD: No longer in use
  // static cleanUpApiClass(obj: any): any {
  //   if (!obj) return null;

  //   // Use copyObj to remove functions generated by nSwagStudio
  //   obj = HelperUtils.copyObj(obj);

  //   // nSwagStudio uses "<obj>._discriminator" but API expects "<obj>.discriminator"
  //   if (obj._discriminator) {
  //     obj.discriminator = obj._discriminator;
  //     delete obj._discriminator;
  //   }

  //   return obj;
  // }
}
