import { Component, OnInit } from '@angular/core';

import { AuthService } from 'src/app/services/auth.service';
import { GlobalService, ConfirmExtendedOptions, ConfirmExtendedReturnValue } from 'src/app/services/global.service';

@Component({
  selector: 'dge-el-confirm-extended',
  templateUrl: './confirmExtended.component.html',
  styleUrls: ['./confirmExtended.component.min.css']
})
export class ElementConfirmExtendedComponent implements OnInit {
  showDialog: boolean = false;
  message: string = '';
  options: ConfirmExtendedOptions;
  returnValue: ConfirmExtendedReturnValue;

  constructor(
    public authService: AuthService,
    private globalService: GlobalService
  ) { }

  ngOnInit() {    
    this.globalService.extConfirm.isVisible.subscribe(b => {
      this.showDialog = b;
      this.message = this.globalService.extConfirm.message;
      this.options = this.globalService.extConfirm.options;


      this.returnValue = new ConfirmExtendedReturnValue({
        comment: ''
      });
    });
  }

  accept() {
    if (this.returnValue.comment.length > 0) this.globalService.confirmExtendedAccept(this.returnValue);
  }

  reject() {
    this.globalService.confirmExtendedReject();
  }
}
