import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpRequest, HttpEventType } from '@angular/common/http';
import { Subject } from 'rxjs';

import { ConfigService } from "./config.service";
import { ProjectService } from './project.service';
import { UserSettingsService } from './userSettings.service';

import { UploadStatus } from '../utils/uploadUtils';
import { MeasurementSystem } from '../entities/units/measurementSystem';

export enum ReportType {
  Report,
  ExpressionFile
}

@Injectable()
export class ReportingService {  
  private apiCommonDataPoolUrl =  this.configService.get('commonDataPoolUrl');

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private projectService: ProjectService,
    private userSettingsService: UserSettingsService
  ) { 
    
  }

  // => Reporting< ==================================================================================================

  public getReport(file: File, type: ReportType): UploadStatus {
    let url: string;
    const formData: FormData = new FormData();

    const projectData = { 
      rotatingMachine: this.projectService.project.data['rotatingMachine']
    };

    if (type === ReportType.Report) {
      url = this.apiCommonDataPoolUrl + 'Reporting/GenerateReport';
      formData.append('TemplateFile', file, file.name);
      formData.append('JsonData', JSON.stringify(projectData));
      formData.append('UseImperialUnits', (this.userSettingsService.getMeasurementSystem() === MeasurementSystem.Imperial).toString());
      formData.append('FormattingCultureString', 'en-US');
    }
    if (type === ReportType.ExpressionFile) {
      url = this.apiCommonDataPoolUrl + 'DataExport/GetExpressionFile';
      formData.append('TemplateFile', file, file.name);
      formData.append('JsonData', JSON.stringify(projectData));
    }

    const req = new HttpRequest('POST', url, formData, {
      reportProgress: true,
      responseType: 'blob'
    });

    // create a new progress-subject for every file
    const progress = new Subject<number>();
    const response = new Subject<any>();

    // send the http-request and subscribe for progress-updates
    this.http.request(req).subscribe(event => {
      if (event.type === HttpEventType.UploadProgress) {

        // calculate the progress percentage
        const percentDone = Math.round(100 * event.loaded / event.total);

        // pass the percentage into the progress-stream
        progress.next(percentDone);
      } else if (event instanceof HttpResponse) {

        // Close the progress-stream if we get an answer form the API
        // The upload is complete
        progress.complete();
        response.next(event);
      }
    }, (err) => response.error(err));

    // Save every progress-observable in a map of all observables
    const status: UploadStatus = {
      progress: progress.asObservable(),
      response: response.asObservable(),
      file: file
    };

    // return the map of progress.observables
    return status;
  }
}