import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

import { TooltipService, TooltipOptions } from '../../services/tooltip.service';

@Component({
  selector: 'dge-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.min.css'],
  animations: [
    trigger('appear', [
      state('in', style({
        opacity: '1',
        transform: 'scale(1)'
      })),
      transition('void => *', [
        style({
          opacity: '0'
        }),
        animate('100ms ease')
      ]),
      transition('* => void', [
        animate('100ms ease', style({
          opacity: '0'
        }))
      ])
    ])
  ]
})
export class TooltipComponent implements OnInit {
  tooltip: TooltipOptions;
  styles: any = {};
  arrowStyle; any;

  @ViewChild('tooltipMainElement', { static: true }) tooltipElement: ElementRef;

  constructor(
    private tooltipService: TooltipService
  ) { }

  ngOnInit() {
    this.tooltipService.tooltipChange.subscribe((tooltip: TooltipOptions) => {
      if (!this.tooltipService.isTooltipDisabled) {
        this.tooltip = tooltip;
        this.hideTooltip();
        setTimeout(() => {
          this.setStyles();
        }, 0);
      }
    });
    this.tooltipService.tooltipDisabledChange.subscribe((disabled: boolean) => {
      if (disabled) {
        this.tooltip = null;
      }
    });
  }

  hideTooltip() {
    this.styles['opacity'] = '0';
  }

  setStyles(): any {
    this.styles = {};
    this.arrowStyle = {};

    if (!this.tooltip) {
      this.styles['opacity'] = '0';
    } else {
      if (this.tooltip.refRect) {
        this.styles['opacity'] = '1';

        if (this.tooltip.position === 'right') {
          const top = this.tooltip.refRect.top + this.tooltip.offset;
          this.styles['left'] = this.tooltip.refRect.left + this.tooltip.refRect.width + 15 + 'px';

          const elHeight = this.tooltipElement.nativeElement.offsetHeight;
          
          if (top + elHeight + 5 > window.innerHeight) {
            const newTop = window.innerHeight - elHeight - 5;
            this.styles['bottom'] = '5px';
            this.arrowStyle['top'] = (top - newTop) + 'px';
          } else {
            this.styles['top'] = top + 'px';
          }
        }
        if (this.tooltip.position === 'bottom') {
          this.styles['top'] = this.tooltip.refRect.top + this.tooltip.refRect.height + 15 + 'px';

          const elWidth = this.tooltipElement.nativeElement.offsetWidth;
          const left = this.tooltip.refRect.left + this.tooltip.offset;
          
          const centerOffset = Math.floor(this.tooltip.refRect.width / 2) - Math.floor(elWidth / 2);
          
          if (left + Math.floor(this.tooltip.refRect.width / 2) + Math.floor(elWidth / 2) + 5 > window.innerWidth) {
            const newLeft = window.innerWidth - elWidth - 5;
            this.styles['right'] = '5px';

            // console.log(left, newLeft, left-newLeft, elWidth)
            // var arrowLeft = left - newLeft + Math.floor(elWidth / 2) - 15
            // if (arrowLeft < elWidth - 15) {
            //   this.arrowStyle['left'] = arrowLeft + 'px';
            // } else {
            //   this.arrowStyle['left'] = arrowLeft + 'px';
            //   //this.arrowStyle['right'] = '5px';
            // }
            if (this.tooltip.refRect.left + this.tooltip.refRect.width / 2 > window.innerWidth) {
              this.arrowStyle['right'] = '5px';
            } else {
              this.arrowStyle['left'] = ((left - newLeft) + centerOffset + (elWidth / 2) - 15) + 'px';
            }
            
          } else {
            this.styles['left'] = left + centerOffset + 'px';
            this.arrowStyle['left'] = (Math.floor(elWidth / 2) - 15) + 'px';
          }
        }
      }
    }
  }

  formatDate(str) {
    if (!str) return '';

    str = str.toString();

    const y = str.substr(0, 4);
    const m = str.substr(4, 2);
    const d = str.substr(6, 2);

    return `${d}.${m}.${y}`;
  }

  formatHours(hrs: number) {
    return hrs.toFixed(1).replace('.', ',');
  }
}
