import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { MenuItem, ConfirmationService } from 'primeng/api';
import { trigger, state, style, animate, transition } from '@angular/animations';

import * as moment from 'moment';

import { forkJoin } from 'rxjs';

import { ContextMenuService } from 'src/app/services/contextMenu.service';
import { CommonDataPoolService } from 'src/app/services/commonDataPool.service';
import { ProjectService } from 'src/app/services/project.service';
import { GlobalService, ToastType, ConfirmExtendedOptions, ConfirmExtendedReturnValue } from 'src/app/services/global.service';

import { HelperUtils } from 'src/app/utils/helperUtils';

import { CDPProject, CDPVariant, CDPVersion, CDPIteration, CDPFlag, CDPFlagAssociation } from 'src/app/entities/serviceTypes/index.datapool';

@Component({
  selector: 'dge-el-data-browser',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.min.css'],
  animations: [
    trigger('appear', [
      state('in', style({
        opacity: '1',
        transform: 'translateY(0)'
      })),
      transition('void => *', [
        style({
          opacity: '0',
          transform: 'translateY(-10px)'
        }),
        animate('100ms ease')
      ]),
      transition('* => void', [
        animate('100ms ease', style({
          opacity: '0',
          transform: 'translateY(10px)'
        }))
      ])
    ])
  ]
})
export class ElementDataBrowserMainComponent implements OnInit, AfterViewInit {
  isLoading = false;
  
  _projects: CDPProject[];
  get projects(): CDPProject[] { return this._projects; }
  set projects(val: CDPProject[]) { this._projects = val; this.setFilteredProjects(); }
  _variants: CDPVariant[];
  get variants(): CDPVariant[] { return this._variants; }
  set variants(val: CDPVariant[]) { this._variants = val; this.setFilteredVariants(); }
  _versions: CDPVersion[];
  get versions(): CDPVersion[] { return this._versions; }
  set versions(val: CDPVersion[]) { this._versions = val; this.setFilteredVersions(); }
  _iterations: CDPIteration[];
  get iterations(): CDPIteration[] { return this._iterations; }
  set iterations(val: CDPIteration[]) { this._iterations = val; this.setFilteredIterations(); }
  
  filteredProjects: CDPProject[];
  filteredVariants: CDPVariant[];
  filteredVersions: CDPVersion[];
  filteredIterations: CDPIteration[];

  filterProjects: string;
  filterVariants: string;
  filterVersions: string;
  filterIterations: string;

  selectedProject: any;
  selectedVariant: any;
  selectedVersion: any;
  selectedIteration: any;

  loadingProjects = false;
  loadingVariants = false;
  loadingVersions = false;
  loadingIterations = false;

  showIterations = false;
  legacyActive = false;
  showDetails = false;
  showFlags = true;

  flagsEnabled = false;
  flagTypes: CDPFlag[];
  flagTypesMapping = {
    'OFFERED': { flag: null, icon: 'fas fa-hand-holding-usd' }, // hand-holding
    'ORDERED': { flag: null, icon: 'fas fa-cart-arrow-down' },
    'BUILT': { flag: null, icon: 'fas fa-tools' }
  };
  flags: CDPFlagAssociation[];

  details: string = '';
  detailFlags: any[] = [];

  contextMenuItems: MenuItem[];
  contextMenuItemsIteration: MenuItem[];
  contextMenuItemsOnlyNew: MenuItem[];

  editPopupTitle: string = '';
  editPopupVisible = false;
  editPopupItem: any;
  editPopupItemPrevious: any;
  editPopupData: any;
  editInProgress = false;
  editResponse: string;  
  @ViewChild('editNameInput', { static: true }) editNameInput: ElementRef<HTMLInputElement>;

  editIsPrivate = false;
  editProjectConfirmationVisible = false;
  editProjectConfirmation = false;

  types = ['Project', 'Variant', 'Version', 'Iteration'];

  constructor(
    private commonDataPoolService: CommonDataPoolService,
    private confirmationService: ConfirmationService,
    private contextMenuService: ContextMenuService,
    private globalService: GlobalService,
    private projectService: ProjectService
  ) { }

  ngOnInit() {
    this.contextMenuItems = [      
      { label: 'New', icon: 'pi pi-fw pi-plus', command: (e: Event) => this.openEditPopup(e, 'new') },
      { label: 'Edit', icon: 'pi pi-fw pi-pencil', command: (e: Event) => this.openEditPopup(e, 'edit') },
      { label: 'Delete', icon: 'pi pi-fw pi-trash', command: (e: Event) => this.confirmDelete(e) }
    ];
    this.contextMenuItemsIteration = [      
      { label: 'New', icon: 'pi pi-fw pi-plus', command: (e: Event) => this.openEditPopup(e, 'new') },
      { label: 'Edit', icon: 'pi pi-fw pi-pencil', command: (e: Event) => this.openEditPopup(e, 'edit') },
      { label: 'Delete', icon: 'pi pi-fw pi-trash', command: (e: Event) => this.confirmDelete(e) },
      { separator: true },
      { label: 'Flag as "As Offered"', icon: 'pi-fw ' + this.flagTypesMapping.OFFERED.icon, command: (e: Event) => this.flagIteration(e, this.flagTypesMapping.OFFERED) },
      { label: 'Flag as "As Ordered"', icon: 'pi-fw ' + this.flagTypesMapping.ORDERED.icon, command: (e: Event) => this.flagIteration(e, this.flagTypesMapping.ORDERED) },
      { label: 'Flag as "As Built"', icon: 'pi-fw ' + this.flagTypesMapping.BUILT.icon, command: (e: Event) => this.flagIteration(e, this.flagTypesMapping.BUILT) },
      { label: 'Remove any flags', icon: 'pi-fw fas fa-times', command: (e: Event) => this.unflagIteration(e) }
    ];
    this.contextMenuItemsOnlyNew = [      
      { label: 'New', icon: 'pi pi-fw pi-plus', command: (e: Event) => this.openEditPopup(e, 'new') }
    ];

    const fnInit = () => {      
      if (this.projectService.currentProject) {
        this.legacyActive = this.projectService.currentProject.isLegacy;
        this.selectedProject = this.projectService.currentProject.project;
        this.selectedVariant = this.projectService.currentProject.variant;
        this.selectedVersion = this.projectService.currentProject.version;

        this.getVariants(this.selectedProject.id);
        this.getVersions(this.selectedVariant.id);
      }

      this.init();
    };

    if (this.flagsEnabled) {
    this.initFlags().subscribe(() => {
      // init with currently loaded project
      fnInit();
    });
    } else {
      fnInit();
    }
  }

  ngAfterViewInit() {
    console.log('AfterViewInit');
  }

  close() {    
    this.isLoading = false;
    this.globalService.currentPopup = null;
  }

  init() {
    this.details = 'No item selected.';
    this.detailFlags = [];
    this.filterProjects = '';
    this.filterVariants = '';
    this.filterVersions = '';
    this.filterIterations = '';

    this.getProjects();
  }

  // FLAGS

  initFlags() {
    return forkJoin([
      this.commonDataPoolService.getFlagTypes(), 
      this.getFlags()
    ]).do(([flagTypes, flags]) => {
      this.flagTypes = flagTypes;
      this.flagTypesMapping.OFFERED.flag = this.flagTypes.find(i => i.name === 'AS OFFERED');
      this.flagTypesMapping.ORDERED.flag = this.flagTypes.find(i => i.name === 'AS ORDERED');
      this.flagTypesMapping.BUILT.flag = this.flagTypes.find(i => i.name === 'AS BUILT');
      console.log(flagTypes, this.flagTypesMapping);
    });
  }

  getFlags() {
    this.isLoading = true;
    return this.commonDataPoolService.getFlags().do(flags => {
      this.flags = flags;
      this.isLoading = false;
    });
  }

  flagIteration($e, flagMapping: any) {
    if (!this.flagsEnabled) return;
    const iter = this.contextMenuService.options.data.value;
    this.confirmationService.confirm({
      message: `Do you really want to flag iteration "${iter.name}" as "${flagMapping.flag.name}"?`,
      accept: () => {
        this.isLoading = true;
        this.commonDataPoolService.setFlag(iter.id, flagMapping.flag.id).subscribe(() => {
          this.getFlags().subscribe(() => {
            this.isLoading = false;
          });
        }, err => {
          this.isLoading = false;
          this.globalService.toast(ToastType.Error, err.message);
        });
      }
    });
  }

  unflagIteration($e) {
    alert('nyi');
  }

  getDetails(type: string, item: any) {
    if (type === 'date') {
      return moment(item.value.modifiedAt || item.value.createdAt).format('YYYY-MM-DD');
    }
    if (type === 'user') {
      return item.value.modifiedBy || item.value.createdBy;      
    }    
    return '';
  }

  getFlag(flagType: string, item: any, idStr: string) {
    if (this.flagsEnabled && this.flags.filter(i => i.fkFlagId === this.flagTypesMapping[flagType].flag.id && i[idStr] === item.value.id).length > 0) {
      return `<i class="${this.flagTypesMapping[flagType].icon}"></i>`;
    }
    return '';
  }

  // DATA

  getProjects() {
    this.loadingProjects = true;    
    this.commonDataPoolService[!this.legacyActive ? 'getProjects' : 'getLegacyProjects']().subscribe(res => {
      this.projects = this.sortResult(res);
      this.loadingProjects = false;

      if (!this.selectedProject || !this.projects.find(i => i.id === this.selectedProject.id)) {
        this.variants = [];
        this.versions = [];
        this.iterations = [];
        this.selectedProject = null;
        this.selectedVariant = null;
        this.selectedVersion = null;
        this.selectedIteration = null;
      } else {
        this.selectedProject = this.projects.find(i => i.id === this.selectedProject.id);
      }
    });
  }

  getVariants(projectID) {
    if (!projectID) {
      this.variants = [];
      return;
    }

    this.loadingVariants = true;
    this.commonDataPoolService[!this.legacyActive ? 'getVariants' : 'getLegacyVariants'](projectID).subscribe(res => {
      this.variants = this.sortResult(res);
      this.loadingVariants = false;

      if (!this.selectedVariant || !this.variants.find(i => i.id === this.selectedVariant.id)) {
        this.versions = [];
        this.iterations = [];
        this.selectedVariant = null;
        this.selectedVersion = null;
        this.selectedIteration = null;
      } else {        
        this.selectedVariant = this.variants.find(i => i.id === this.selectedVariant.id);
      }
    });
  }

  getVersions(variantID) {
    if (!variantID) {
      this.versions = [];
      return;
    }

    this.loadingVersions = true;
    this.commonDataPoolService[!this.legacyActive ? 'getVersions' : 'getLegacyVersions'](variantID).subscribe(res => {
      this.versions = this.sortResult(res);
      this.loadingVersions = false;

      if (!this.selectedVersion || !this.versions.find(i => i.id === this.selectedVersion.id)) {
        this.iterations = [];
        this.selectedVersion = null;
        this.selectedIteration = null;
      } else {        
        this.selectedVersion = this.versions.find(i => i.id === this.selectedVersion.id);
      }
    });
  }

  getIterations(versionID) {
    if (!versionID) {
      this.iterations = [];
      return;
    }

    this.loadingIterations = true;
    this.commonDataPoolService.getIterations(versionID).subscribe(res => {
      this.iterations = this.sortResult(res);
      this.loadingIterations = false;

      if (!this.selectedIteration || !this.iterations.find(i => i.id === this.selectedIteration.id)) {
        this.selectedIteration = null;
      } else {        
        this.selectedIteration = this.iterations.find(i => i.id === this.selectedIteration.id);
      }
    });
  }

  sortResult(arr) {
    arr.sort((a, b) => {
      if (a.name < b.name)
        return -1;
      if (a.name > b.name)
        return 1;
      return 0;
    });
    return arr;
  }

  // FILTER

  setFilteredProjects() {
    this.filteredProjects = this.getFiltered(this.projects, this.filterProjects);
  }

  setFilteredVariants() {
    this.filteredVariants = this.getFiltered(this.variants, this.filterVariants);    
  }

  setFilteredVersions() {
    this.filteredVersions = this.getFiltered(this.versions, this.filterVersions);    
  }

  setFilteredIterations() {
    this.filteredIterations = this.getFiltered(this.iterations, this.filterIterations);
  }

  getFiltered(array, str) {
    if (!str) return array;
    if (array.length === 0) return array;

    const filters = str.split(' ').filter(f => !!f).map(f => f.toLowerCase());

    return array.filter(i => filters.every(f => i.name.toLowerCase().indexOf(f) >= 0));
  }

  getDetailsBreadcrumb()  {
    if (!this.selectedProject) {
      return 'Details';
    } else {
      let s = '';
      for (let i = 0; i < this.types.length; i++) {
        if (this['selected' + this.types[i]]) s += ' > ' + this['selected' + this.types[i]].name;
        else break;
      }
      return s;
    }
  }


  // EVENTS

  onChange(type: string, e) {
    let flagIdStr = '';
    if (type === 'project') {
      this.getVariants(e.value.id);
      flagIdStr = 'fkProjId';
    }
    if (type === 'variant') {
      this.getVersions(e.value.id);
      flagIdStr = 'fkVarId';
    }
    if (type === 'version' && this.showIterations) {
      this.getIterations(e.value.id);
      flagIdStr = 'fkVerId';
    }
    if (type === 'iteration') {
      flagIdStr = 'fkIterId';
    }
    
    this.details = e.value.description || '<i>(no description)</i>';
    this.details += '<br>';
    this.details += '<br>Created by: ' + e.value.createdBy;
    this.details += '<br>Created at: ' + moment(e.value.createdAt).format('YYYY-MM-DD HH:mm:ss');
    if (e.value.modifiedBy) this.details += '<br><br>Modified by: ' + e.value.modifiedBy;
    if (e.value.modifiedAt) this.details += '<br>Modified at: ' + moment(e.value.modifiedAt).format('YYYY-MM-DD HH:mm:ss');

    // Flags
    if (this.flagsEnabled) {
      this.detailFlags = [];
      Object.entries(this.flagTypesMapping).forEach(map => {
        const flags = this.flags.filter(i => i.fkFlagId === map[1].flag.id && i[flagIdStr] === e.value.id);
        if (flags) {
          flags.forEach(flag => {
            const detailFlag = {
              id: flag.fkIterId,
              icon: map[1].icon,
              name: map[1].flag.name,
              project: this.selectedProject,
              variant: this.selectedVariant,
              version: this.selectedVersion,
              iteration: this.selectedIteration,
            };

            if (!detailFlag.variant) this.commonDataPoolService.getVariant(flag.fkVarId).subscribe(r => detailFlag.variant = r);
            if (!detailFlag.version) this.commonDataPoolService.getVersion(flag.fkVerId).subscribe(r => detailFlag.version = r);
            if (!detailFlag.iteration) this.commonDataPoolService.getIteration(flag.fkIterId, true).subscribe(r => detailFlag.iteration = r);

            this.detailFlags.push(detailFlag);
          });
        }
      });
    }
  }

  onDblClick(type: string, e) {
    if (type === 'iteration') {      
      this.loadIteration();
    }
  }

  legacyActiveChanged(e) {
    this.init();
  }

  showIterationsChanged(e) {
    if (this.showIterations && this.selectedVersion) this.getIterations(this.selectedVersion.id);
  }

  onEditKeydown(e: KeyboardEvent) {
    if (e.which === 13) {
      this.saveEditPopup(e.ctrlKey);
      e.preventDefault();
      e.stopPropagation();
    }
  }

  // EDIT POPUP

  openEditPopup(e, mode: 'new' | 'edit') {
    if (this.contextMenuService.options) this.editPopupData = this.contextMenuService.options.data;
    const data = this.editPopupData;
    this.editPopupItemPrevious = null;
    
    if (mode === 'new') {
      if (data.type === 'project') {
        this.editPopupItem = new CDPProject();
        this.editProjectConfirmationVisible = true;
        this.editProjectConfirmation = false;
      }
      if (data.type === 'variant') { 
        this.editPopupItem = new CDPVariant(); 
        this.editPopupItem.projectId = this.selectedProject.id; 
      }
      if (data.type === 'version') { 
        this.editPopupItem = new CDPVersion(); 
        this.editPopupItem.variantId = this.selectedVariant.id; 
        this.editPopupItem.programme = 1;
      }
      if (data.type === 'iteration') { 
        this.editPopupItem = new CDPIteration(); 
        this.editPopupItem.versionId = this.selectedVersion.id; 
        this.editPopupItem.data = JSON.stringify({ test: true });
      }
      this.editPopupItem.createdBy = 'USER ID';

      this.editPopupTitle = 'New ';

    } else if (mode === 'edit') {
      this.editPopupItem = data.value;
      this.editPopupItemPrevious = HelperUtils.copyObj(this.editPopupItem);
      this.editPopupTitle = 'Edit ';
    }
    
    this.editPopupTitle += data.type;

    this.editPopupVisible = true;
    this.editResponse = '';

    try {
      setTimeout(() => { this.editNameInput.nativeElement.select(); }, 100);
    } catch (ex) { }

    this.contextMenuService.options = null;
  }

  saveEditPopup(openNewNextType: boolean = false) {
    console.log(this.editPopupItem, this.editPopupData);
    const type = this.editPopupData.type;

    this.editResponse = '';
    if (!this.editPopupItem.name || this.editPopupItem.name.length <= 2) this.editResponse += '<span class="dge-font-error">Please enter a name with at least 2 characters.</span><br>';
    if (this.editProjectConfirmationVisible && !this.editProjectConfirmation) this.editResponse += '<span class="dge-font-error">Please confirm project creation.</span><br>';
    if (this.editResponse !== '') return;

    const successCB = (res) => {
      this['selected' + HelperUtils.toFirstCharUpper(type)] = res;

      if (type === 'project') this.getProjects();
      if (type === 'variant') this.getVariants(this.selectedProject ? this.selectedProject.id : null);
      if (type === 'version') this.getVersions(this.selectedVariant ? this.selectedVariant.id : null);
      if (type === 'iteration') this.getIterations(this.selectedVersion ? this.selectedVersion.id : null);
        
      if (openNewNextType && ['project', 'variant'].indexOf(type) >= 0) {
        this.editPopupData.type = this.getNextType(this.editPopupData.type).toLowerCase();
        this.openEditPopup(null, 'new');
      } else {
        this.editPopupVisible = false;
      }

      this.editInProgress = false;
    };
    const errorCB = (err) => {
      this.editInProgress = false;
      console.log(err);
      if (err) this.editResponse = '<span class="dge-font-error">' + err.error || err.message || err.toString() + '</span>';
    };

    this.editInProgress = true;

    const saveCB = () => this.commonDataPoolService['save' + HelperUtils.toFirstCharUpper(type)](this.editPopupItem).subscribe(successCB, errorCB);

    if (type === 'project' && this.editPopupItemPrevious && this.editPopupItem.name !== this.editPopupItemPrevious.name) {
      this.confirmationService.confirm({
        message: `Do you really want to rename project "${this.editPopupItemPrevious.name}" to "${this.editPopupItem.name}"?`,
        accept: saveCB
      });
    } else {
      saveCB();
    }
  }

  getSaveAndNewLabel() {
    return 'Save and new ' + this.getNextType(this.editPopupData.type).toLowerCase();
  }

  confirmDelete(e) {
    const data = this.contextMenuService.options.data;

    const successCB = (res) => {
      this.globalService.toast(ToastType.Success, '"' + data.value.name + '" successfully deleted.');
      if (data.type === 'project') this.getProjects();
      if (data.type === 'variant') this.getVariants(this.selectedProject ? this.selectedProject.id : null);
      if (data.type === 'version') this.getVersions(this.selectedVariant ? this.selectedVariant.id : null);
      if (data.type === 'iteration') this.getIterations(this.selectedVersion ? this.selectedVersion.id : null);
    };
    const errorCB = (err) => {
      
      this.globalService.toast(ToastType.Error, 'ERROR while deleting' + '(' + err.statusText +')' +'. Please check console.');
      
      
    };

    this.globalService.confirmExtended(`Do you really want to delete "${data.value.name}"?`,
      new ConfirmExtendedOptions({ commentPlaceholder: 'Enter a reason:' }),
      (r: ConfirmExtendedReturnValue) => {
        // User has accepted, comment/reason is contained in r.comment
        this.commonDataPoolService['delete' + HelperUtils.toFirstCharUpper(data.type)](data.value, r.comment).subscribe(successCB, errorCB);
      },
      () => {
        // User has rejected
      }
    );   
  
    this.contextMenuService.options = null;
  }

  // LOAD / SAVE

  loadIteration() {
    this.loadIterationExec(this.selectedProject, this.selectedVariant, this.selectedVersion, this.showIterations ? this.selectedIteration : null, this.legacyActive);
  }

  loadIterationByFlag(detailFlag: any) {
    this.loadIterationExec(detailFlag.project, detailFlag.variant, detailFlag.version, detailFlag.iteration, false);
  }

  loadIterationExec(project: CDPProject, variant: CDPVariant, version: CDPVersion, iteration: CDPIteration, isLegacy: boolean) {
    this.isLoading = true;
    this.commonDataPoolService
      .loadIteration(project, variant, version, iteration, isLegacy)
      .subscribe((r: any) => {
        console.log(r);
        this.isLoading = false;
        if (r.success) {
          this.close();
        }
      }, err => {
        this.isLoading = false;
        this.globalService.toast(ToastType.Error, err.message);
      });
  }

  saveIteration() {
    this.isLoading = true;
    this.commonDataPoolService.saveNewIteration(this.selectedVersion.id).subscribe((r: CDPIteration) => {
      this.projectService.setProjectInfo(this.selectedProject, this.selectedVariant, this.selectedVersion, r ? r : null, false);
      this.close();
    }, err => {
      this.isLoading = false;
      this.globalService.toast(ToastType.Error, err.message);
    });
  }

  // Helper

  getNextType(type: string): string {
    const idx = this.types.indexOf(HelperUtils.toFirstCharUpper(type)) + 1;
    if (idx >= 0 && idx < this.types.length) return this.types[idx];
    return '';
  }
}
