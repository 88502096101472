import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'dge-tab-content',
  templateUrl: './tabContent.component.html',
  styleUrls: ['./tabContent.component.min.css']
})
export class ElementTabContentComponent implements OnInit {
  @Input() key: string = '';
  @Input() label: string;
  @Input() active = false;
  @Input() visibilityType: 'display' | 'visibility' = 'display';

  constructor() { }

  ngOnInit() {
    if (!this.key) this.key = this.label;
  }
}
