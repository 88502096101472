import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dge-layout-col-favs',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.min.css']
})
export class LayoutColumnFavsComponent implements OnInit {
  showWaitInfo: boolean = false;

  ngOnInit() {

  }

  onCommentChange(val) {
    
  }
}
