import { Observable, BehaviorSubject } from "rxjs";

import { ProjectService } from "../services/project.service";

import { ToastType } from "../services/global.service";

export class DgeModuleController {
  // #TODO# Refactor with enums
  moduleNames: string[];
  modules: {
    [module: string]: DgeModule;
  };
  
  modulesChanged: BehaviorSubject<DgeModule>;

  constructor(
    private projectService: ProjectService
  ) {
    this.moduleNames = [];
    this.modules = {};

    this.modulesChanged = new BehaviorSubject(null);
  }
  
  initProjectData() {
    this.moduleNames.forEach(modName => {
      const dgeMod = this.modules[modName];
      if (dgeMod.initProjectData) dgeMod.initProjectData();
    });
  }
  
  initProjectDefaults() {
    this.moduleNames.forEach(modName => {
      const dgeMod = this.modules[modName];
      if (dgeMod.initProjectDefaults) dgeMod.initProjectDefaults();
    });
  }

  isModuleInitialized(moduleName: string): boolean {
    return this.moduleNames.indexOf(moduleName) >= 0;
  }

  add(dgeMod: DgeModule) {
    if (!this.modules[dgeMod.name]) {
      this.moduleNames.push(dgeMod.name);
      this.modules[dgeMod.name] = dgeMod;
      if (dgeMod.init) dgeMod.init();
      if (dgeMod.initProjectData) dgeMod.initProjectData();
      if (dgeMod.initProjectDefaults) dgeMod.initProjectDefaults();

      this.modulesChanged.next(dgeMod);
    }
  }

  doModuleCall(moduleName: string) {
    if (this.modules[moduleName]) {
      this.modules[moduleName].callConfigs.forEach(cc => this.doCall(cc));
    }
  }

  doNamespaceCall(namespace: string) {
    this.moduleNames.forEach(modName => {
      const dgeMod = this.modules[modName];
      if (dgeMod.callConfigs) {
        dgeMod.callConfigs
          .filter(cc => cc.namespaces && cc.namespaces.length > 0)
          .forEach(cc => {                          
              if (!namespace || cc.namespaces.some(i => i === namespace)) {
                this.doCall(cc);
              }
          });
      }
    });
  }

  doCall(cc: DgeModuleCallConfig) {
    cc.function().subscribe((res: DgeModuleCallResponse) => {
      this.projectService._debugLatestOutput[cc.name.toLowerCase()] = res.response;
      this.projectService.dataChanged.next(res.dataChangedValue);
      
      if (res.globalMessage) this.projectService.globalService.setGlobalMessage(res.globalMessage.message, res.globalMessage.type);
      if (res.toastMessage) this.projectService.globalService.toast(res.toastMessage.type, res.toastMessage.message);
    });
  }
}

export class DgeModule {
  name: string;
  callConfigs: DgeModuleCallConfig[] = [];
  /* Init global operations. */
  init: Function;
  /* Init default project data for all projects (e.g. ".rotatingMachineInput") */
  initProjectData: Function;
  /* Init default properties for new projects (e.g. ".rotatingMachineInput.powerOutput.apparentPower") */
  initProjectDefaults: Function;

  constructor (init?: Partial<DgeModule>) {
    Object.assign(this, init);
  }
}

export class DgeModuleCallConfig {
  name: string;
  /* Namespaces to check for an auto calculation (e.g. "RotatingMachineInput") */
  namespaces: string[];
  function: DgeModuleCallResponseFunction;

  constructor (init?: Partial<DgeModuleCallConfig>) {
    Object.assign(this, init);
  }
}

export class DgeModuleCallResponse {
  // Will be set to current output
  response: any = {};
  // Triggers re-render of properties.
  dataChangedValue: any = null;

  globalMessage: { message: string, type?: string };
  toastMessage: { type: ToastType, message: string };

  constructor (init?: Partial<DgeModuleCallResponse>) {
    Object.assign(this, init);
  }
}

type DgeModuleCallResponseFunction = () => Observable<DgeModuleCallResponse>;