import { Component, OnInit } from '@angular/core';
import { ProjectService } from 'src/app/services/project.service';
@Component({
  selector: 'dge-layout-col-favs-panel-favs',
  templateUrl: './panelFavs.component.html',
  styleUrls: ['./panelFavs.component.min.css']
})
export class LayoutColumnFavsPanelFavsComponent implements OnInit {
  favorites: string[]; // keys

  constructor(
    private projectService: ProjectService
  ) { 
    this.favorites = [];
  }

  ngOnInit() {
    this.projectService.favoritesChanged.subscribe((favorites) => {
      this.favorites = favorites || [];
    });
  }
}