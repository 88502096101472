import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { BaseProperty } from 'src/app/entities/propertyTypes/baseProperty';

import { Observable, fromEvent } from 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';
import { PROPERTIES } from 'src/app/config/properties';
import { PropertyUtils } from 'src/app/utils/propertyUtils';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

@Component({
  selector: 'dge-layout-col-favs-panel-search',
  templateUrl: './panelSearch.component.html',
  styleUrls: ['./panelSearch.component.min.css']
})
export class LayoutColumnFavsPanelSearchComponent implements OnInit {
  results: string[] = [];
  searchAllProperties: boolean = false;
  searchInChildren: boolean = false;

  @ViewChild('filter', { static: true }) filter: ElementRef;

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    fromEvent(this.filter.nativeElement, 'keyup')
      .pipe(debounceTime(200))
      .pipe(distinctUntilChanged())
      .subscribe(() => this.doFilter());

    this.router.events.subscribe(
      (event) => {
        if (event instanceof NavigationEnd) this.doFilter();
      }
    );
  }

  doFilter() {
    const filters = this.filter.nativeElement.value.split(' ').filter(f => !!f).map(f => f.toLowerCase()); //  && f.indexOf('<') !== 0 && f.indexOf('>') !== 0

    this.results = [];

    if (filters.length > 0) {       
      const outletGears = this.route.snapshot.children.find(i => i.outlet === 'gears');
      const outletGearsRoute = outletGears && outletGears.url.length ? outletGears.url[0].path : '';

      for (const key in PROPERTIES) {
        if (filters.every(f => {
          // #TODO# - Define searched fields.
          // return this.contains(key, f) || this.contains(VALUES[key].label, f) || this.contains(VALUES[key].inputKey, f) || this.contains(VALUES[key].outputKey, f);
          const prop = PropertyUtils.findProperty(key);
          if (!this.searchAllProperties && (this.searchInChildren ? prop.searchGroup.indexOf(outletGearsRoute) < 0 : prop.searchGroup !== outletGearsRoute)) return false;
          return this.contains(prop.label, f);
        })) {
          this.results.push(key);
        }
      }
    }

    this.results.sort();
  }

  contains(searchIn: string, forString: string) {
    if (!searchIn || !forString) return false;
    return searchIn.toString().toLowerCase().indexOf(forString.toLowerCase()) >= 0;
  }
}
