import { BaseProperty } from './baseProperty';

import { NumericUnitType } from '../units/numericUnitType';

export class NumericProperty extends BaseProperty {
  public options: NumericPropertyOptions = new NumericPropertyOptions();

  constructor (init?: Partial<NumericProperty>) {
    super(init);
    Object.assign(this, init);

    this.type = 'NumericProperty';
  }
}

export class NumericPropertyOptions {
  public allowDecimals: boolean = true;
  public countDecimals: number = 0;
  public countDecimalsMin: number = 0;

  public minValue: number = undefined;
  public maxValue: number = undefined;

  public allowNegatives: boolean = true;

  public regexp: RegExp;

  public baseUnit: NumericUnitType = null;
  public viewUnit: NumericUnitType = null;
  public allowUnitConversion: boolean = false;

  constructor (init?: Partial<NumericPropertyOptions>) {
    Object.assign(this, init);

    if (this.baseUnit !== null) this.allowUnitConversion = true;
  }
}
