import { BaseProperty } from './baseProperty';
import { NumericUnitType } from '../units/numericUnitType';

export class CustomProperty extends BaseProperty {
  customType: 'material' = null;
  customSubType: 'ElectricalSteelSheetMaterial' = null;
  displayProperty: string = null;
  public displayUnit: NumericUnitType = null;
  
  constructor (init?: Partial<CustomProperty>) {
    super(init);
    Object.assign(this, init);

    this.type = 'CustomProperty';

    if (this.customType === 'material') {
      this.displayProperty = 'name';
    }
  }
}
