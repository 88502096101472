import { Component, OnInit, Input, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';

import { merge } from 'rxjs';

import { ProjectService } from '../../services/project.service';
import { GlobalService, ToastType } from 'src/app/services/global.service';
import { UserSettingsService } from 'src/app/services/userSettings.service';

import { ControlBaseComponent } from './baseCtrl.component';
import { PropertyUtils } from 'src/app/utils/propertyUtils';
import { FormatNumericPipe } from 'src/app/pipes/formatNumeric.pipe';
import { NumericProperty } from 'src/app/entities/propertyTypes/numericProperty';
import { UnitConversionPipe } from 'src/app/pipes/unitConversion.pipe';
import { HelperUtils } from 'src/app/utils/helperUtils';

@Component({
  selector: 'dge-ctrl-composed',
  templateUrl: './composedInput.component.html',
  styleUrls: ['./composedInput.component.min.css']
})
export class ControlComposedInputComponent extends ControlBaseComponent implements OnInit {
  @Input() inputKey: string;
  @Input() value1Config: NumericProperty;
  @Input() value2Config: NumericProperty;
  @Input() separators: string[] = ['x', '*', ' '];
  @Output() valueChange = new EventEmitter<any>();
  @Input() value1: any;
  @Input() value2: any;
  regExp: RegExp;
  regExpRaw: string;
  isHidden: boolean = false;

  @ViewChild('overlay', { static: false }) overlay: ElementRef;
  @ViewChild('input', { static: false }) input: ElementRef;

  constructor(
    protected globalService: GlobalService,
    protected projectService: ProjectService,
    protected userSettingsService: UserSettingsService
  ) { 
    super(globalService, projectService, userSettingsService);
  }

  ngOnInit() {
    this.getValue();
    this.setRegExp();
    
    super.ngOnInit();

    this.ctrlKey = this.lineKey + this.key;

    this._subscriptions.push(merge(this.userSettingsService.settingsChanged).subscribe(s => {
      this.setRegExp();
    }));

    if (!this.key || (!!this.value1Config[this.key] && !!this.value2Config[this.key])) {      
      this.isHidden = false;
    } else {
      this.isHidden = true;
    }
  }

  // Events ---------------

  onKeydown(e: KeyboardEvent) {
    this.regExp.lastIndex = 0;
    if (!(e.metaKey || (e.ctrlKey && e.code === 'KeyA') || ['Backspace', 'Tab', 'Enter', 'End', 'Home', 'ArrowLeft', 'ArrowUp', 'ArrowRight', 'ArrowDown', 'Delete'].some(i => i === e.code) || !(this.regExp).test(e.key))) {
      e.preventDefault();
    }
    if (e.code === 'Escape') {
      // ESC - Cancel
      if (this.carryOverOutputActive) this.carryOverOutputActive = false;

      this.globalService.focussedCtrlKey = null;
      this.globalService.focussedLineKey = null;

      this.disableOnChange = true;
      (this.input.nativeElement as HTMLInputElement).blur();
      this.disableOnChange = false;

      this.getValue();
      this.setDisplayValue();
    }
  }

  onChange(e) {
    if (!this.disableOnChange) {
      console.log('entered value: ', e.target.value);
      this.setValue(e.target.value);
      this.globalService.focussedCtrlKey = null;
    }
  }

  onFocus(e) {
    this.setFocus();
  }

  onBlur(e) {
    this.isFocussed = false;
    if (this.carryOverOutputActive) {
      this.onChange(e);
      this.carryOverOutputActive = false;
    }
  }

  // Methods -----------------

  getValue() {
    if (!!this.value1Config[this.key] && !!this.value2Config[this.key]) {
      this.value1 = this.projectService.getDataValue(this.value1Config[this.key]);
      this.value2 = this.projectService.getDataValue(this.value2Config[this.key]);
    }
  }

  getDisplayValue() {
    if (!HelperUtils.isNull(this.value1) || !HelperUtils.isNull(this.value2)) {
      const unitConversionPipe = new UnitConversionPipe(this.userSettingsService);
      const formatNumericPipe = new FormatNumericPipe(this.userSettingsService);

      let val1Disp = this.value1;
      let val2Disp = this.value2;
      if (this.value1Config.type === 'NumericProperty') val1Disp = formatNumericPipe.transform(unitConversionPipe.transform(this.value1, this.value1Config.options), this.value1Config.options, false);
      if (this.value2Config.type === 'NumericProperty') val2Disp = formatNumericPipe.transform(unitConversionPipe.transform(this.value2, this.value2Config.options), this.value2Config.options, false);

      return val1Disp + ' ' + this.separators[0] + ' ' + val2Disp;
    }
    return '';
  }

  setDisplayValue() {
    if (this.input) (this.input.nativeElement as HTMLInputElement).value = this.getDisplayValue();
  }

  setValue(input: any) {
    const split = PropertyUtils.getTwoValueSplit(
      input, 
      this.separators, 
      this.userSettingsService, 
      this.regExp, 
      this.value1Config.options,
      this.value2Config.options
    );

    if (split && split.length >= 1 && split.length <= 2) {
      this.value1 = split[0];

      if (HelperUtils.isNull(split[1])) split[1] = 0;
      this.value2 = split[1];
    } else {
      if (input !== '') this.globalService.toast(ToastType.Error, '"' + input + '" cannot be parsed. Please use "[' + PropertyUtils.getTypeLabel(this.value1Config) + ']' + this.separators[0] + '[' + PropertyUtils.getTypeLabel(this.value1Config) + ']".');
      this.value1 = null;
      this.value2 = null;
    }

    this.storeValue();
  }

  storeValue() {
    if (this.value1 === null && this.value2 === null && this.inputKey) {    
      this.projectService.setDataValue(this.inputKey, null);
      console.log('value set to: ', null);
    } else {      
      this.projectService.setDataValue(this.value1Config[this.key], this.value1, true);
      this.projectService.setDataValue(this.value2Config[this.key], this.value2);    
      console.log('value set to: ', this.value1, this.separators[0], this.value2);
    }
    
    this.valueChange.emit({ value1: this.value1, value2: this.value2 });    
  }

  setRegExp() {
    this.regExpRaw = PropertyUtils.getNumericRegExpString(this.userSettingsService.getDecimalSeparator(), this.value1Config.options.allowNegatives, false, this.separators.join());
    this.regExp = new RegExp(this.regExpRaw, 'gi');
  }

  setFocus() {
    if (!this.readOnly && !this.isFocussed) {
      this.globalService.focussedCtrlKey = this.ctrlKey;
      this.globalService.focussedLineKey = this.lineKey;
      this.carryOverOutput();

      setTimeout(() => {
        this.input.nativeElement.select();
      }, 1);
    }
  }

  carryOverOutput(storeValue?: boolean) {
    if (this.lineKey && !this.value1) {
      const prop = PropertyUtils.findProperty(this.lineKey);

      if (prop.type === 'ComposedProperty' && this.value1Config.outputKey && this.value2Config.outputKey) {
        this.value1 = this.projectService.getDataValue(this.value1Config.outputKey);
        this.value2 = this.projectService.getDataValue(this.value2Config.outputKey);
        if (storeValue) {          
          this.storeValue();
        } else {
        if (this.value1 && this.value2) this.carryOverOutputActive = true;
        }
        this.setDisplayValue();
      }
    }
  }

  clearInput() {
    this.value1 = null;
    this.value2 = null;
    this.storeValue();
  }
}
