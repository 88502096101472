import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';

@Component({
  selector: 'dge-layout-col-edit',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.min.css']
})
export class LayoutColumnEditComponent implements OnInit {
  public showWaitInfo = false;
  gearsModuleInitialized = true;

  constructor(private router: Router) {
    router.events.subscribe(
      (event) => {

        if (event instanceof NavigationStart) {
          this.showWaitInfo = true;
        }
        if (event instanceof NavigationEnd
          || event instanceof NavigationCancel
          || event instanceof NavigationError) {
          this.showWaitInfo = false;
        }

      }
    );
  }

  ngOnInit() {

  }  
  
  onTabChange(key) {
    
  }
}
