import { Component, OnInit, AfterViewInit, ElementRef, ViewChild, HostListener } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

import { ProjectService } from 'src/app/services/project.service';

import { ContextMenuService, ContextMenuOptions, ContextMenuType } from '../../services/contextMenu.service';
import { MenuItem, ConfirmationService } from 'primeng/api';
import { GlobalService, PopupSettings } from 'src/app/services/global.service';
import { PropertyUtils } from 'src/app/utils/propertyUtils';

@Component({
  selector: 'dge-context-menu',
  templateUrl: './contextMenu.component.html',
  styleUrls: ['./contextMenu.component.min.css'],
  animations: [
    trigger('appear', [
      state('in', style({
        opacity: '1',
        transform: 'scale(1)'
      })),
      transition('void => *', [
        style({
          opacity: '0'
        }),
        animate('100ms ease')
      ]),
      transition('* => void', [
        animate('100ms ease', style({
          opacity: '0'
        }))
      ])
    ])
  ]
})
export class ContextMenuComponent implements OnInit, AfterViewInit {
  options: ContextMenuOptions;
  items: MenuItem[] = [];
  styles: any = {};

  @ViewChild('contextMenuMain', { static: true }) contextMenuElement: ElementRef;

  constructor(
    private confirmationService: ConfirmationService,
    private contextMenuService: ContextMenuService,
    private globalService: GlobalService,
    private projectService: ProjectService,
  ) { }

  ngOnInit() {
    this.contextMenuService.contextMenuChange.subscribe((options: ContextMenuOptions) => {
      if (!this.contextMenuService.isDisabled) {
        this.options = options;
        if (this.options) {
          this.setItems();
          setTimeout(() => {
            this.setStyles();     
          }, 0);
        }
      }
    });
    this.contextMenuService.contextMenuDisabledChange.subscribe((disabled: boolean) => {
      if (disabled) {
        this.options = null;
      }
    });
  }

  ngAfterViewInit() {
    // position based on `ref`
    // console.log(this.tooltipElement);
  }
  
  @HostListener('document:mouseup', ['$event'])
  onDocumentMouseUp(e: MouseEvent) {
    if (!e.composedPath().some((i: HTMLElement) => i.tagName === 'DGE-CONTEXT-MENU') && this.contextMenuService.options) {
      this.hideContextMenu();
    }
  }
  
  @HostListener('contextmenu', ['$event'])
  onContext(e: MouseEvent) {
    e.preventDefault();
  }

  hideContextMenu() {
    this.contextMenuService.options = null;
  }

  setItems() {
    if (this.options.type === ContextMenuType.Property) {
      // this.contextMenuService.options = null;
      // return;

      // Set focussed line key
      this.globalService.focussedLineKey = this.options.data['lineKey'];

      const lineKey = this.options.data['lineKey'];
      if (lineKey) {
        const prop = PropertyUtils.findProperty(lineKey);

        const isFavorite = this.projectService.project.metaData.favorites.some(i => i === lineKey);
        const hasCtrlKey = !!this.options.data['ctrlKey'];
        const hasOuputKey = prop && prop.outputKey;
        const tooltipOutputKey = hasOuputKey ? '' : 'Not available because property has no output.';
        const hasComment = this.projectService.hasMetaData(lineKey) && this.projectService.getMetaData(lineKey).comment;

        this.items = [];

        if(prop.inputKey == "GearsCalculationInput.motorRotationSpeed")
        {
          this.items.push({ label: 'Belt/chain output speed', icon: 'pi pi-fw pi-info', command: (e: Event) => this.valueOpenBeltChainCalc(e) });
        }

        if(this.items.length == 0)
        {
            this.contextMenuService.options = null;
        }
        // this.items.push({ label: hasComment ? 'Edit comment' : 'Add comment', icon: 'pi pi-fw pi-comment', command: (e: Event) => this.valueOpenComment(e) });
        // if (hasComment) this.items.push({ label: 'Remove comment', icon: 'pi pi-fw pi-trash', command: (e: Event) => this.valueRemoveComment(e) });
          
        // this.items.push({ 
        //   label: isFavorite ? 'Remove from favorites' : 'Flag as favorite', 
        //   icon: isFavorite ? 'pi pi-fw pi-star-o' : 'pi pi-fw pi-star', 
        //   command: (e: Event) => this.valueToggleFavorite(e), 
        //   disabled: !hasOuputKey,
        //   title: tooltipOutputKey
        // });

        // if (hasCtrlKey) {
        //   this.items.push(...[        
        //     { separator: true },
        //     { label: 'Remove color', icon: 'pi pi-fw pi-ban', command: (e: Event) => this.valueSetCtrlColor(e, null) },
        //     { label: 'Set red', icon: 'pi pi-fw pi-circle-on dge-ctrl-fg-1', command: (e: Event) => this.valueSetCtrlColor(e, 1) },
        //     { label: 'Set orange', icon: 'pi pi-fw pi-circle-on dge-ctrl-fg-2', command: (e: Event) => this.valueSetCtrlColor(e, 2) },
        //     { label: 'Set yellow', icon: 'pi pi-fw pi-circle-on dge-ctrl-fg-3', command: (e: Event) => this.valueSetCtrlColor(e, 3) },
        //     { label: 'Set green', icon: 'pi pi-fw pi-circle-on dge-ctrl-fg-4', command: (e: Event) => this.valueSetCtrlColor(e, 4) },
        //     { label: 'Set blue', icon: 'pi pi-fw pi-circle-on dge-ctrl-fg-5', command: (e: Event) => this.valueSetCtrlColor(e, 5) },
        //     { label: 'Set purple', icon: 'pi pi-fw pi-circle-on dge-ctrl-fg-6', command: (e: Event) => this.valueSetCtrlColor(e, 6) }
        //   ]);
        // }
      }
    } else if (this.options.type === ContextMenuType.Custom) {
      this.items = this.options.items;
    }
  }

  setStyles() {
    this.styles = {};
  
    if (this.contextMenuElement) {
      const pos = this.options.position;
      const elWidth = this.contextMenuElement.nativeElement.offsetWidth;
      const elHeight = this.contextMenuElement.nativeElement.offsetHeight;
      if (pos.x + (this.options.offset || 0) + elWidth > window.innerWidth) pos.x = window.innerWidth - elWidth - 10;
      if (pos.y + (this.options.offset || 0) + elHeight > window.innerHeight) pos.y = window.innerHeight - elHeight - 10;
    

    }

    if(this.options)
    {

    this.styles['left'] = this.options.position.x + 'px';
      this.styles['top'] = this.options.position.y + 'px';
    }
  }

  // Type.Value specific methods --------------------------------------------------

  valueOpenBeltChainCalc(e: Event) {
    this.globalService.currentPopup = new PopupSettings({
      type: 'kft-belt-chain-calc',
      data: this.options.data
    });
    this.hideContextMenu();
  }

  valueOpenComment(e: Event) {
    this.globalService.currentPopup = new PopupSettings({
      type: 'comment',
      data: this.options.data
    });
    this.hideContextMenu();
  }
  valueRemoveComment(e: Event) {
    const lineKey = this.options.data['lineKey'];
    this.confirmationService.confirm({ 
      message: 'Do you really want to remove this comment?', 
      accept: () => this.projectService.setLineComment(lineKey, null)
    });
    this.hideContextMenu();
  }
  valueToggleFavorite(e: Event) {
    const options = this.contextMenuService.options;
    this.projectService.toggleFavorite(options.data['lineKey']);
    this.hideContextMenu();
  }
  valueSetCtrlColor(e: Event, colorIdx: number) {
    const options = this.contextMenuService.options;
    if (options.data['ctrlKey']) this.projectService.setCtrlColor(options.data['lineKey'], options.data['ctrlKey'], colorIdx);
    this.hideContextMenu();
  }
}
