import { BaseProperty } from './baseProperty';
import { NumericProperty, NumericPropertyOptions } from './numericProperty';
import { NumericUnitType } from '../units/numericUnitType';

export class ComposedProperty extends BaseProperty {
  public separators: string[] = ['x', '*', ' '];
  public value1: BaseProperty;
  public value2: BaseProperty;
  public assignment: Function;
  public composedType: ComposedPropertyType = ComposedPropertyType.Default;
  public options: NumericPropertyOptions = null;

  constructor (init?: Partial<ComposedProperty>) {
    super(init);
    Object.assign(this, init);

    this.type = 'ComposedProperty';

    if (init.composedType && init.composedType !== ComposedPropertyType.Default && !init.value1 && !init.value2) {
      let v1Suffix: string, v2Suffix: string;

      switch (init.composedType) {
        case ComposedPropertyType.WidthHeightCount:
          v1Suffix = '.InWidth';
          v2Suffix = '.InHeight';
          this.separators = ['x', '*', ' '];
          break;
        case ComposedPropertyType.WidthHeightValue:
          v1Suffix = '.InWidth';
          v2Suffix = '.InHeight';
          this.separators = ['x', '*', ' '];
          if (this.options === null) { this.options = new NumericPropertyOptions({ baseUnit: NumericUnitType.m, viewUnit: NumericUnitType.mm }); }
          break;
        case ComposedPropertyType.TwoSideValue:
          v1Suffix = '.Nce';
          v2Suffix = '.Ce';
          this.separators = ['|', '/', ' ']; 
          break;
        case ComposedPropertyType.TwoLayerPair:
          v1Suffix = '.Top';
          v2Suffix = '.Bottom';
          this.separators = ['|', '/', ' ']; 
          break;
        case ComposedPropertyType.MinMax:
          v1Suffix = '.Min';
          v2Suffix = '.Max';
          this.separators = ['...', ' ']; 
          break;
      }

      this.value1 = new NumericProperty({ 
        inputKey: this.inputKey ? this.inputKey + v1Suffix : null,
        outputKey: this.outputKey ? this.outputKey + v1Suffix : null,
      });
      this.value2 = new NumericProperty({ 
        inputKey: this.inputKey ? this.inputKey + v2Suffix : null,            
        outputKey: this.outputKey ? this.outputKey + v2Suffix : null,
      });

      if (this.options !== null) {
        (this.value1 as NumericProperty).options = this.options;
        (this.value2 as NumericProperty).options = this.options;
      }
    }

    if (!this.inputKey && !!this.value1.inputKey) this.inputKey = this.value1.inputKey;
    if (!this.outputKey && !!this.value1.outputKey) this.outputKey = this.value1.outputKey;
  }
}

export enum ComposedPropertyType {
  Default = 1,
  WidthHeightCount = 2,
  WidthHeightValue = 3,
  TwoSideValue = 4,
  TwoLayerPair = 5,
  MinMax = 6
}