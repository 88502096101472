var inertia;

inertia = {
  tm2: {
    name: {
      singular: 'ton square meter',
      plural: 'ton square meters'
    },
    to_anchor: 1e3
  }, 
  kgm2: {
    name: {
      singular: 'kilogram sqare meter', 
      plural: 'kilogram sqare meters'
    },
    to_anchor: 1
  }
};


module.exports = {
  metric: inertia,
  _anchors: {
    metric: {
      unit: 'kgm2', 
      ratio: 1
    }
  }
};
