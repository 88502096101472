import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'dge-inline-error',
  templateUrl: './inlineError.component.html',
  styleUrls: ['./inlineError.component.css']
})
export class InlineErrorComponent implements OnInit {
  @Input() valueError: string;

  constructor() { }

  ngOnInit() {
  }

}
