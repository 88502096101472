import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

import { ProjectService } from 'src/app/services/project.service';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'dge-el-comment',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.min.css']
})
export class ElementCommentComponent implements OnInit, AfterViewInit {
  @Input() data: { lineKey: string } = null;

  comment: string = null;

  @ViewChild('textarea', { static: true }) textarea: ElementRef;

  constructor(
    private globalService: GlobalService,
    private projectService: ProjectService,
  ) { }

  ngOnInit() {
    if (this.data && this.data.lineKey && this.projectService.hasMetaData(this.data.lineKey)) {
      this.comment = this.projectService.getMetaData(this.data.lineKey).comment || '';
    } else {

    }
  }

  ngAfterViewInit() {
    setTimeout(() => this.textarea.nativeElement.select(), 100);
  }

  saveComment() {
    this.projectService.setLineComment(this.data.lineKey, this.comment);
    this.closeComment();
  }

  closeComment() {
    this.globalService.currentPopup = null;
  }
}
