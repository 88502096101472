import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import 'rxjs/add/operator/do';

import { AuthService } from 'src/app/services/auth.service';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'dge-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.min.css']
})
export class LoginComponent implements OnInit {
  isLoading = false;
  notAuthorized: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.isLoading = true;
    this.login();
  }

  doAutoLogin() {
    this.isLoading = true;
    this.notAuthorized = false;
    this.login();
  }

  login() {
    this.authService.getMsalUser();

    // const obsArr = [this.authService.getMe(), this.authService.getPermissions()];

    // combineLatest(obsArr).subscribe(([user, permissions]) => {
      if (this.authService.isAuthenticated()) {
        this.router.navigate([this.authService.authRedirectUrl || '/main']);
      } else {
        this.notAuthorized = true;
        this.isLoading = false;
      }
    // });
  }
}
