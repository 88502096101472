var metric;

metric = {
  'VAs/m3': {
    name: {
      singular: 'volt ampere second per qubic meter',
      plural: 'volt ampere seconds per qubic meters'
    },
    to_anchor: 1
  }, 
  'kVAmin/m3': {
    name: {
      singular: 'kilo volt ampere minute per qubic meter', 
      plural: 'kilo volt ampere minutes per qubic meters'
    },
    to_anchor: 60000
  }
};


module.exports = {
  metric: metric,
  _anchors: {
    metric: {
      unit: 'VAs/m3', 
      ratio: 1
    }
  }
};
