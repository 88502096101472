import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { faExternalLinkAlt, faTimes } from '@fortawesome/free-solid-svg-icons';

import { GlobalService } from 'src/app/services/global.service';
import { ProjectService } from '../../services/project.service';
import { UserSettingsService } from '../../services/userSettings.service';

import { HelperUtils } from 'src/app/utils/helperUtils';
import { PropertyUtils } from '../../utils/propertyUtils';

import { BaseProperty } from 'src/app/entities/propertyTypes/baseProperty';
import { NumericProperty } from 'src/app/entities/propertyTypes/numericProperty';
import { ComposedProperty } from 'src/app/entities/propertyTypes/composedProperty';
import { MultiProperty } from 'src/app/entities/propertyTypes/multiProperty';
import { ArrayProperty } from 'src/app/entities/propertyTypes/arrayProperty';

import { PropertyMetaData, ProjectError } from 'src/app/entities/project';
import { ControlBaseComponent } from '../controls/baseCtrl.component';
import { Subscription } from 'rxjs';
import { SelectProperty } from 'src/app/entities/propertyTypes/selectProperty';
import { CustomProperty } from 'src/app/entities/propertyTypes/customProperty';

@Component({
  selector: 'dge-el-propertyline',
  templateUrl: './propertyLine.component.html',
  styleUrls: ['./propertyLine.component.min.css']
})
export class ElementPropertyLineComponent implements OnInit, OnDestroy, OnChanges {
  // Display modifier
  @Input() mode: 'default' | 'light' | 'valueOnly' = 'default';
  @Input() ctrlSizeMode: 'default' | 'small' | 'extended' | 'flex' | 'full' = 'default';

  // Property
  mainProperty: BaseProperty;
  inputProperty: any;
  outputProperty: any;
  @Input() set property(value: BaseProperty) {
    this.mainProperty = value;
    this.inputProperty = value;
    this.outputProperty = value;
  }
  get property(): BaseProperty {
    return this.mainProperty;
  }
  inputType: any;
  outputType: any;

  // Input/Output
  @ViewChild('input', { static: false }) inputCtrl: ControlBaseComponent;
  @ViewChild('output', { static: false }) outputCtrl: ControlBaseComponent;
  @Input() overrideKeys: boolean = false;
  @Input() label: string;
  @Input() key: string;
  @Input() inputKey: string;
  @Input() outputKey: string;
  @Input() hideLabel: boolean = false;
  @Input() hideInput: boolean = false;
  @Input() hideOutput: boolean = false;
  @Input() hideUnit: boolean = false;
  @Input() valueError: string;
  hasInput: boolean = null;
  hasOutput: boolean = null;

  @Output() valueChange = new EventEmitter<any>();

  unit: string;

  // Flags
  isList: boolean = false;
  isInputVisible: boolean = false;
  hasInputValue: boolean = false;
  hasOutputValue: boolean = false;

  isFocussed: boolean;
  isFavorite: boolean = false;
  hasComment: boolean = false;
  hasErrors: boolean = false;
  errorMessage: string = '';

  _subscriptions: Subscription[] = [];

  constructor(
    private globalService: GlobalService,
    private projectService: ProjectService,
    private userSettingsService: UserSettingsService,
    private router: Router
  ) { }

  ngOnChanges(changes: SimpleChanges){
    // Toggle 'disabled' flag & style on Output field if it's state is changed after init
    if(changes.hideOutput){
      this.hasOutput = !this.hideOutput && !!this.outputKey;
    }
  }

  ngOnInit() {
    this.setDisplay();
    this.setValue();
    this.setUnit();

    this._subscriptions.push(this.globalService.focussedLineChanged.subscribe((key: string) => {
      this.isFocussed = (key === this.key);
      if (!this.isFocussed && this.isInputVisible) this.isInputVisible = false;
    }));

    // this.globalService.focussedCtrlChanged.subscribe((key: string) => {
    //   this.isInputVisible = (key === this.inputKey);
    // });

    if (!this.hideUnit) {
      this._subscriptions.push(this.userSettingsService.settingsChanged.subscribe((settings: any) => {
        this.setUnit();
      }));
    }

    this._subscriptions.push(this.projectService.dataChanged.subscribe(r => {
      this.setHasInputValue();
    }));

    this._subscriptions.push(this.projectService.valueChanged.subscribe((r: { key: string, value: object }) => {
      if (this.inputType !== 'ComposedProperty') {
        if (r && this.inputKey === r.key) this.setHasInputValue();
      } else {
        const compProp = (this.inputProperty as ComposedProperty);
        if (compProp.value1.inputKey === r.key || compProp.value2.inputKey === r.key) this.setHasInputValue();
      }
    }));

    this._subscriptions.push(this.projectService.favoritesChanged.subscribe((key) => {
      this.isFavorite = this.projectService.project.metaData.favorites.some(i => i === this.key);
    }));
    this._subscriptions.push(this.projectService.metaDataChanged.subscribe((res: { key: string, value: PropertyMetaData }) => {
      if (res && this.key === res.key) {
        this.hasComment = !!res.value.comment || false;
      }
    }));
    this._subscriptions.push(this.projectService.errorsChanged.subscribe((errors: ProjectError[]) => {
      this.hasErrors = false;
      this.errorMessage = '';
      if (errors.length && errors.some(i => i.key === this.inputKey)) {
        const myErrors = errors.find(i => i.key === this.inputKey);
        this.hasErrors = true;
        this.errorMessage = myErrors.message;
      }
    }));
  }

  ngOnDestroy() {
    let sub: Subscription;
    while (sub = this._subscriptions.pop()) {
      if (!sub.closed) sub.unsubscribe();
    }
  }

  setDisplay() {
    if (this.mode === 'light' || this.mode === 'valueOnly') {
      this.ctrlSizeMode = 'flex';
      this.hideLabel = true;
    }

    if (this.mode === 'valueOnly') {
      this.hideUnit = true;
    }
  }

  setValue() {
    if (!this.property) this.property = PropertyUtils.findProperty(this.key);
    if (!this.property) {
      return;
      throw new ReferenceError('[PropertyLineComponent]: "' + this.key + '" not found.');
    }

    if (this.property.type === 'MultiProperty') {
      this.inputProperty = (this.property as MultiProperty).inputProperty;
      this.outputProperty = (this.property as MultiProperty).outputProperty;
    }

    if (!this.overrideKeys || !this.label) this.label = this.mainProperty.label;
    if (!this.overrideKeys) {
      this.inputKey = this.inputProperty.inputKey;
      this.outputKey = this.outputProperty.outputKey;
    } else {
      this.key = this.inputKey;
    }

    this.hasInput = !this.hideInput && !!this.inputKey;
    this.hasOutput = !this.hideOutput && !!this.outputKey;

    this.inputType = this.inputProperty.type;
    this.outputType = this.outputProperty.type;

    const listPropTypes = ['ListProperty', 'CustomListProperty', 'TableProperty'];
    const isInputList = listPropTypes.indexOf(this.inputType) >= 0;
    const isOutputList = listPropTypes.indexOf(this.outputType) >= 0;
    this.isList = isInputList || isOutputList;

    this.setHasInputValue();
  }

  setUnit() {
    if (this.hideUnit) return;

    // unit label
    if (this.inputType === 'NumericProperty') {
      const numVal = (this.inputProperty as NumericProperty);
      if (numVal.options && numVal.options.baseUnit !== null) {
        this.unit = PropertyUtils.getMeasurementSystemUnitLabel(this.userSettingsService.getMeasurementSystem(), numVal.options.viewUnit || numVal.options.baseUnit);
      }
    } else if (this.inputType === 'ComposedProperty') {
      const compVal = (this.inputProperty as ComposedProperty);

      let unit1 = '', unit2 = '';
      if (compVal.value1.type === 'NumericProperty') {
        const numVal = (compVal.value1 as NumericProperty);
        if (numVal.options && numVal.options.baseUnit !== null) {
          unit1 = PropertyUtils.getMeasurementSystemUnitLabel(this.userSettingsService.getMeasurementSystem(), numVal.options.viewUnit || numVal.options.baseUnit);
        }
      }
      if (compVal.value2.type === 'NumericProperty') {
        const numVal = (compVal.value2 as NumericProperty);
        if (numVal.options && numVal.options.baseUnit !== null) {
          unit2 = PropertyUtils.getMeasurementSystemUnitLabel(this.userSettingsService.getMeasurementSystem(), numVal.options.viewUnit || numVal.options.baseUnit);
        }
      }

      this.unit = (unit1 !== unit2 && unit1 && unit2) ? unit1 + '/' + unit2 : unit1;
    } else if (this.inputType === 'ArrayProperty') {
      const arrVal = (this.inputProperty as ArrayProperty);
      if (arrVal.numericOptions && arrVal.numericOptions.baseUnit !== null) {
        this.unit = PropertyUtils.getMeasurementSystemUnitLabel(this.userSettingsService.getMeasurementSystem(), arrVal.numericOptions.viewUnit || arrVal.numericOptions.baseUnit);
      }
    } else if (this.inputType === 'SelectProperty') {
      const selProp = (this.inputProperty as SelectProperty);
      if (selProp.displayUnit !== null) {
        this.unit = PropertyUtils.getMeasurementSystemUnitLabel(this.userSettingsService.getMeasurementSystem(), selProp.displayUnit);
      }
    }
    else if (this.inputType === 'CustomProperty') {
      const selProp = (this.inputProperty as CustomProperty);
      if (selProp.displayUnit !== null) {
        this.unit = PropertyUtils.getMeasurementSystemUnitLabel(this.userSettingsService.getMeasurementSystem(), selProp.displayUnit);
      }
    }
    else {
      this.unit = '';
    }
  }

  onValueChange($e) {
    this.valueChange.emit($e);
  }

  public reloadValues() {
    this.reloadInputValue();
    this.reloadOutputValue();
  }

  public reloadInputValue() {
    this.projectService.valueChangedExternally.next(this.inputKey);
  }

  public reloadOutputValue() {
    this.projectService.valueChangedExternally.next(this.outputKey);
  }

  focus(e) {
    this.globalService.focussedLineKey = this.key;
  }

  getLabelStyles() {
    if (this.hasErrors || this.valueError) return { color: '#f00' };
    if (this.mainProperty && this.mainProperty.required && !this.hasInputValue) return { color: '#f00' };
    return null;
  }

  setHasInputValue() {
    if (this.inputType !== 'ComposedProperty') {
      this.hasInputValue = this.inputKey && !HelperUtils.isNull(this.projectService.getDataValue(this.inputKey));
    } else {
      const compProp = (this.inputProperty as ComposedProperty);
      this.hasInputValue = this.inputKey && (!HelperUtils.isNull(this.projectService.getDataValue(compProp.value1.inputKey)) || !HelperUtils.isNull(this.projectService.getDataValue(compProp.value2.inputKey)));
    }
  }

  showInput() {
    if (!this.isInputVisible) this.isInputVisible = true;
  }

  getInputStyle() {
    const styles = {};
    if ((!this.isInputVisible || !this.isFocussed) && (!this.mainProperty || !this.mainProperty.required) && !this.hasInputValue && this.hasOutput) styles['opacity'] = '0';
    return styles;
  }

  getInputIndicatorStyle() {
    const styles = {};
    // if (!this.hasInputValue) styles['opacity'] = '0';
    return styles;
  }

  openPage(e) {
    this.router.navigate(['main', { outlets: { gears: [this.mainProperty.searchGroup] } }]);
  }

  carryOverOutput() {
    if (this.hasInput && this.inputCtrl) this.inputCtrl.carryOverOutput(true);
  }

  clearInput() {
    if (this.hasInput && this.inputCtrl) this.inputCtrl.clearInput();
  }
}
