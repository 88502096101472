import { Component, OnInit, ElementRef, ViewChild, Input, Output, EventEmitter } from '@angular/core';

import { ProjectService } from '../../services/project.service';
import { GlobalService } from 'src/app/services/global.service';
import { ControlBaseComponent } from './baseCtrl.component';
import { UserSettingsService } from 'src/app/services/userSettings.service';
import { PropertyUtils } from 'src/app/utils/propertyUtils';
import { EntityProperty, EntityDefinition } from 'src/app/entities/propertyTypes/entityProperty';
import { HelperUtils } from 'src/app/utils/helperUtils';
import { Dropdown } from 'primeng/dropdown';
import { Dialog } from 'primeng/dialog';

@Component({
  selector: 'dge-ctrl-entity',
  templateUrl: './entityInput.component.html',
  styleUrls: ['./entityInput.component.min.css']
})
export class ControlEntityEditorComponent extends ControlBaseComponent implements OnInit {
  @Input() value: any;
  @Input() property: EntityProperty;
  @Output() valueChange = new EventEmitter<any>();

  showPopup: boolean = false;
  popupTitle: string;
  
  selectedEntity: EntityDefinition;
  entityDefinitions: { definition: EntityDefinition, value: any, key: string }[] = [];

  @ViewChild('button', { static: false }) button: ElementRef;
  @ViewChild('entitySelect', { static: false }) entitySelect: Dropdown;
  @ViewChild('dialog', { static: false }) dialog: Dialog;

  constructor(
    protected globalService: GlobalService,
    protected projectService: ProjectService,
    protected userSettingsService: UserSettingsService
  ) { 
    super(globalService, projectService, userSettingsService);
  }

  ngOnInit() {    
    this.popupTitle = this.property.label;
    this.getValue();
    
    super.ngOnInit();
  }

  // Events ---------------

  onKeydown(e: KeyboardEvent) {
    // if (e.keyCode === 27) {
    //   // ESC - Cancel
    //   if (this.carryOverOutputActive) this.carryOverOutputActive = false;

    //   this.globalService.focussedCtrlKey = null;
    //   this.globalService.focussedLineKey = null;

    //   this.disableOnChange = true;
    //   (this.button.nativeElement as HTMLInputElement).blur();
    //   this.disableOnChange = false;

    //   this.getValue();
    //   this.setDisplayValue();
    // }
  }

  onFocus(e) {
    // this.setFocus();
  }

  onBlur(e) {
    this.isFocussed = false;
    if (this.carryOverOutputActive) {
      this.carryOverOutputActive = false;
    }
  }

  // Methods -----------------

  getValue() {
    if (!this.showPopup) {
      if (this.key) this.value = this.projectService.getDataValue(this.key);
      this.setEntityDefinitions();
    }
  }

  setDisplayValue() {
    // if (this.button) (this.button.nativeElement as HTMLButtonElement)['label'] = this.value;
  }

  setValue() {
    this.storeValue();
  }

  storeValue() {
    if (this.key) this.projectService.setDataValue(this.key, this.value);
    this.valueChange.emit(this.value);
    console.log('value set to: ', this.value);
  }  

  setFocus() {
    if (!this.readOnly && !this.isFocussed) {
      if (!this.disableKeyChange) {
        this.globalService.focussedCtrlKey = this.ctrlKey;
        this.globalService.focussedLineKey = this.lineKey;
      }
      
      this.carryOverOutput();

      setTimeout(() => {
        // this.button.nativeElement.focus();
      }, 1);
    }
  }

  carryOverOutput(storeValue?: boolean) {
    if (this.lineKey && !this.value) {
      const prop = PropertyUtils.findProperty(this.lineKey);

      if (prop.type === 'EntityProperty' && prop.outputKey) {
        this.value = this.projectService.getDataValue(prop.outputKey);
        if (storeValue) {          
          this.storeValue();
        } else {
          if (this.value) this.carryOverOutputActive = true;
        }
        this.setDisplayValue();
      }
    }
  }

  clearInput() {
    this.value = null;
    this.storeValue();
  }

  // popup

  openPopup() {
    this.showPopup = true;
  }

  onPopupHide() {

  }
  
  onEntityChange($e) {

  }

  selectEntity() {
    const def = this.entitySelect.selectedOption;    
    const newInstance = def.assignment();
    if (!newInstance.discriminator) newInstance.discriminator = def.discriminator;

    if (this.property.useList) {
      if (!this.value) this.value = [];
      this.value.push(newInstance);
    } else {      
      if (newInstance && newInstance.init) {
        const prjVal = HelperUtils.copyObj(this.value);
        try {
          newInstance.init(prjVal);
        } catch (ex) {
          console.log('[ERROR] Cant initialize class per entity assignment: ' + ex.toString());
        }
      }

      this.value = newInstance;
    }

    this.storeValue();
    this.setEntityDefinitions();
  }

  removeEntity(def: EntityDefinition, idx: number) {
    if (this.property.useList) {
      if (this.value && this.value.length > 0) {
        this.value.splice(this.value.indexOf(def), 1);
      }
    } else {
        this.value = null;
    }

    this.storeValue();
    this.setEntityDefinitions();
  }

  setEntityDefinitions() {
    if (!this.value) return [];

    this.entityDefinitions = [];

    if (this.property.useList) {
      if (!this.value.length) return [];

      this.value.forEach((v, idx) => {
        const definition = this.property.availableEntities.filter(e => e.discriminator === PropertyUtils.getDiscriminator(v))[0];
        // if (!definition) definition = this.property.availableEntities[0];

        this.entityDefinitions.push({
          definition: definition,
          value: v,
          key: `${this.key}[${idx}].`
        });
      });
    } else {      
      const definition = this.property.availableEntities.filter(e => e.discriminator === PropertyUtils.getDiscriminator(this.value))[0];
      // if (!definition) definition = this.property.availableEntities[0];
      
      this.entityDefinitions.push({
        definition: definition,
        value: this.value,
        key: `${this.key}.`
      });
    }

    setTimeout(() => {
      this.dialog.center();
    }, 1);
  }
}
