import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

import { ProjectService } from 'src/app/services/project.service';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'kft-bc-calc',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.min.css']
})
export class ChainBeltCalcComponent implements OnInit, AfterViewInit {
  
  constructor(
    private globalService: GlobalService,
    private projectService: ProjectService,
  ) { }

  ngOnInit() {
    
  }

  ngAfterViewInit() {
    
  }
}
