import { BaseProperty } from './baseProperty';

export class CustomListProperty extends BaseProperty {
  defaultListItemType: string = null;

  constructor (init?: Partial<CustomListProperty>) {
    super(init);
    Object.assign(this, init);

    this.type = 'CustomListProperty';

    if (this.inputKey && this.outputKey) {
      console.error('[CustomListProperty.ERROR] Only InputKey OR OutputKeys are allowed for CustomListProperties.');
    }
  }
}
