import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'dge-el-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.min.css']
})
export class ElementSidebarComponent implements OnInit {
  @Input() key: string;
  @Input() label: string;

  constructor() { }

  ngOnInit() {

  }
}
