import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';

import { ProjectService } from 'src/app/services/project.service';
import { GlobalService } from 'src/app/services/global.service';

import { ProjectError } from 'src/app/entities/project';

@Component({
  selector: 'dge-layout-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.min.css']
})
export class LayoutFooterComponent implements OnInit {
  @Output() tabClick = new EventEmitter<string>();
  errors: ProjectError[] = []; // keys
  errorLabel: string = 'Messages';

  constructor(
    private globalService: GlobalService,
    private projectService: ProjectService
  ) { 
    this.errors = [];
  }

  ngOnInit() {
    this.projectService.errorsChanged.subscribe((errors: ProjectError[]) => {
      this.errors = errors;
      this.setErrorLabel();
    });
  }

  setErrorLabel() {
    this.errorLabel = this.errors && this.errors.length > 0 ? '<span style="color: #f00">Messages</span>' : 'Messages';
  }

  onTabChange(key) {
    this.tabClick.emit(key);
  }

  onErrorClick(error: ProjectError) {
    this.globalService.focussedLineKey = error.key;
  }

  formatDate(d: Date) {
    return moment(d).format('HH:mm:ss');
  }
}