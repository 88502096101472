import { BaseProperty } from './baseProperty';

export class TableProperty extends BaseProperty {
  inputKey: never;
  columns: TablePropertyColumn[];
  options: TablePropertyOptions = new TablePropertyOptions();

  constructor (init?: Partial<TableProperty>) {
    super(init);
    Object.assign(this, init);

    this.type = 'TableProperty';
  }
}

export class TablePropertyOptions {    
  maxHeight: number;

  constructor (init?: Partial<TablePropertyOptions>) {
    Object.assign(this, init);
  }
}

export class TablePropertyColumn {
  /** Currently only String- and NumericProperty fully supported. */
  property: BaseProperty;
  /** Can be set to overwrite the properties unit. Use "{{unit}}" to display the properties unit. */
  labelTemplate: string;
  enableSort: boolean = true;
  textAlign: 'left' | 'center' | 'right' = 'left';
  width: string;
  
  constructor (init?: Partial<TablePropertyColumn>) {
    Object.assign(this, init);

    if (this.property.type === 'NumericProperty' && !init.textAlign) this.textAlign = 'right';
  }
}