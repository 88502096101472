import { Pipe, PipeTransform } from '@angular/core';
import { NumericPropertyOptions } from '../entities/propertyTypes/numericProperty';
import { UserSettingsService } from '../services/userSettings.service';
import { HelperUtils } from '../utils/helperUtils';

@Pipe({
  name: 'formatNumeric', 
  pure: false
})
export class FormatNumericPipe implements PipeTransform {
  constructor(private userSettingsService: UserSettingsService) { }

  transform(value: any, numValOpt: NumericPropertyOptions, ignoreCountDecimals: boolean = false): string {
    if (value === null || typeof value === 'undefined' || typeof value !== 'number') return null;

    if (!ignoreCountDecimals && numValOpt) {
      value = HelperUtils.round(value, numValOpt.countDecimals);
      const cntDec = HelperUtils.getPrecision(value);
      if (cntDec < numValOpt.countDecimalsMin) value = value.toFixed(numValOpt.countDecimalsMin);
    }

    const decSep = this.userSettingsService.getDecimalSeparator();
    if (decSep !== '.') value = value.toString().replace('.', decSep);

    return value;
  }
}