import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ConfigService } from './config.service';
import { GlobalService, ToastType } from './global.service';
import { ProjectService } from './project.service';

import { HelperUtils } from '../utils/helperUtils';

import { Observable } from 'rxjs';
import { DgeModule, DgeModuleCallResponse } from '../entities/dgeModule';
import { GearsCalculationInput, GearsCalculationOutput, IScrewRecCalculationInput, IScrewRecCalculationOutput, ScrewRecCalculationInput } from '../entities/serviceTypes';
import { ScrewConCalculationInput } from '../entities/serviceTypes';

@Injectable({ providedIn: 'root' })
export class CalculationService {
  private apiCalculationServiceUrl =  this.configService.get('calculationServiceUrl');
  private options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private globalService: GlobalService,
    private projectService: ProjectService
  ) { }

  doScrewrecCalculation(): Observable<DgeModuleCallResponse> {
    console.log("DO CALCULATION");
    return new Observable<DgeModuleCallResponse>(obs => {
      this.globalService.setGlobalMessage('Waiting for calculation results...');

      const ps = this.projectService;
      const callRes = new DgeModuleCallResponse();

      const input = ScrewRecCalculationInput.fromJS(ps.project.data.screwrecCalculationInput);
      console.log("INPUT: ", ps.project.data.screwrecCalculationInput);
      this.projectService._debugLatestInput.screwrec = input;

      this.http
        .post(
          this.apiCalculationServiceUrl + 'screwrec/Calculation',
          JSON.stringify(input, (k, v) => { if (!!v) return v; }),
          this.options)
        .subscribe((res: IScrewRecCalculationOutput) => {
          console.log("ScrewRec/Calculation", res);
          callRes.response = res;
          if (!HelperUtils.isObjEmpty(res)) {
            callRes.globalMessage = { message: '' };
          }

          ps.errors.clear();
          ps.project.data.screwrecCalculationOutput = res;

          obs.next(callRes);
        }, (res: any) => {
          callRes.response = res;

          if (typeof res.error === 'string') {
            ps.errors.add(res.name, res.error);
          } else {
            for (const errProp in res.error) {
              if (res.error.hasOwnProperty(errProp)) {
                ps.errors.add(`${res.name} - ${errProp}`, res.error[errProp]);
              }
            }
          }

          console.log("RES: ", res);

          callRes.globalMessage = {
            message: 'POST to "ScrewRec/Calculation" unsuccessfull!',
            type: 'error'
          };

          callRes.toastMessage = {
            type: ToastType.Error,
            message: '[ERROR]: Post to "ScrewRec/Calculation" was unsuccessful!'
          };

          obs.next(callRes);
        });
    })
  }

  doScrewconCalculation(): Observable<DgeModuleCallResponse> {
    return new Observable<DgeModuleCallResponse>(obs => {
      this.globalService.setGlobalMessage('Waiting for calculation results...');

      const ps = this.projectService;
      const callRes = new DgeModuleCallResponse();

      const input = ScrewConCalculationInput.fromJS(ps.project.data.screwconCalculationInput);

      this.projectService._debugLatestInput.screwcon = input;

      this.http
        .post(
          this.apiCalculationServiceUrl + 'screwcon/Calculation',
          JSON.stringify(input, (key, value) => { if (value !== null) return value; }),
          this.options)
        .subscribe((res: GearsCalculationOutput) => {
          console.log('ScrewCon/Calculation', res);
          callRes.response = res;
          if (!HelperUtils.isObjEmpty(res)) callRes.globalMessage = { message: '' };

          // Errors
          ps.errors.clear();

          ps.project.data.screwconCalculationOutput = res;

          ps.valueChanged.next({
            key:  "SCREW_SIZES",
            value: ps.project.data.screwconCalculationOutput.availableScrewSizes
          });

          obs.next(callRes);
        }, (res: any) => {
          callRes.response = res;

          if (typeof res.error === 'string') {
            ps.errors.add(res.name, res.error);
          } else {
            for (const errProp in res.error) {
              if (res.error.hasOwnProperty(errProp)) {
                ps.errors.add(res.name + ' - ' + errProp, res.error[errProp]);
              }
            }
          }

          callRes.globalMessage = { message: 'POST to "ScrewCon/Calculation" unsuccessful!', type: 'error' };
          callRes.toastMessage = { type: ToastType.Error, message: '[ERROR]: POST to "ScrewCon/Calculation" was unsuccessful!' };
          obs.next(callRes);
        });
    });
  }

  doGearsCalculation(): Observable<DgeModuleCallResponse> {
    return new Observable<DgeModuleCallResponse>(obs => {
      this.globalService.setGlobalMessage('Waiting for calculation results...');

      const ps = this.projectService;
      const callRes = new DgeModuleCallResponse();

      const input = GearsCalculationInput.fromJS(ps.project.data.gearsCalculationInput);
      // const inputScrewCon = ScrewConCalculationInput.fromJS(ps.project.data.s);

      this.projectService._debugLatestInput.gears = input;

      this.http
        .post(
          this.apiCalculationServiceUrl + 'gears/Calculation',
          JSON.stringify(input, (key, value) => { if (value !== null) return value; }),
          this.options)
        .subscribe((res: GearsCalculationOutput) => {
          
          console.log('Gears/Calculation', res);
          callRes.response = res;
          if (!HelperUtils.isObjEmpty(res)) callRes.globalMessage = { message: '' };

          // Errors
          ps.errors.clear();

          ps.project.data.gearsCalculationOutput = res;

          obs.next(callRes);
        }, (res: any) => {
          callRes.response = res;

          if (typeof res.error === 'string') {
            ps.errors.add(res.name, res.error);
          } else {
            for (const errProp in res.error) {
              if (res.error.hasOwnProperty(errProp)) {
                ps.errors.add(res.name + ' - ' + errProp, res.error[errProp]);
              }
            }
          }

          callRes.globalMessage = { message: 'POST to "Gears/Calculation" unsuccessful!', type: 'error' };
          callRes.toastMessage = { type: ToastType.Error, message: '[ERROR]: POST to "Gears/Calculation" was unsuccessful!' };
          obs.next(callRes);
        });
    });
  }

  exportGearsCalculationAsXml(): Observable<DgeModuleCallResponse> {
    return new Observable<DgeModuleCallResponse>(obs => {
      this.globalService.setGlobalMessage('Exporting calculation ...');

      const ps = this.projectService;
      const callRes = new DgeModuleCallResponse();

      const input = GearsCalculationInput.fromJS(ps.project.data.gearsCalculationInput);

      const requestOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),

        responseType: 'blob' as 'json'

      };

      this.http
        .post(
          this.apiCalculationServiceUrl + 'gears/Calculation/export',
          JSON.stringify(input, (key, value) => { if (value !== null) return value; }), requestOptions)
        .subscribe((res: Blob) => {
          callRes.response = res;

          callRes.globalMessage = { message: '' };

          // Errors
          ps.errors.clear();

          obs.next(callRes);
        }, (res: any) => {
          callRes.response = res;

          if (typeof res.error === 'string') {
            ps.errors.add(res.name, res.error);
          } else {
            for (const errProp in res.error) {
              if (res.error.hasOwnProperty(errProp)) {
                ps.errors.add(res.name + ' - ' + errProp, res.error[errProp]);
              }
            }
          }

          callRes.globalMessage = { message: 'POST to "gears/Calculation/export" unsuccessful!', type: 'error' };
          callRes.toastMessage = { type: ToastType.Error, message: '[ERROR]: POST to "gears/Calculation/export" was unsuccessful!' };

          obs.next(callRes);
        });
    });
  }


  exportScrewconCalculationToAutoCadFile(): Observable<DgeModuleCallResponse> {
    return new Observable<DgeModuleCallResponse>(obs => {
      this.globalService.setGlobalMessage('Exporting calculation ...');

      const ps = this.projectService;
      const callRes = new DgeModuleCallResponse();

      const gearsInput = GearsCalculationInput.fromJS(ps.project.data.gearsCalculationInput);
      const screwconInput = ScrewConCalculationInput.fromJS(ps.project.data.screwconCalculationInput);

      const requestOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),

        responseType: 'blob' as 'json'

      };

      this.http
        .post(
          this.apiCalculationServiceUrl + 'screwcon/Calculation/export/autocad',
          JSON.stringify({
            screwconInput: screwconInput,
            gearsInput: gearsInput
          }, (key, value) => { if (value !== null) return value; }), requestOptions)
        .subscribe((res: Blob) => {
          callRes.response = res;

          callRes.globalMessage = { message: '' };

          // Errors
          ps.errors.clear();

          obs.next(callRes);
        }, (res: any) => {
          callRes.response = res;

          if (typeof res.error === 'string') {
            ps.errors.add(res.name, res.error);
          } else {
            for (const errProp in res.error) {
              if (res.error.hasOwnProperty(errProp)) {
                ps.errors.add(res.name + ' - ' + errProp, res.error[errProp]);
              }
            }
          }

          callRes.globalMessage = { message: 'POST to "screwcon/Calculation/export/autocad" unsuccessful!', type: 'error' };
          callRes.toastMessage = { type: ToastType.Error, message: '[ERROR]: POST to "screwcon/Calculation/export/autocad" was unsuccessful!' };

          obs.next(callRes);
        });
    });
  }

  // handleErrors(callRes: DgeModuleCallResponse, validationResult: any) {
  //   if (validationResult) {
  //     if (!validationResult.isValid) {
  //       callRes.globalMessage = { message: 'POST to "Gears/Calculation" unsuccessful!', type: 'error' };

  //       for (let i = 0; i < validationResult.errors.length; i++) {
  //         const error = validationResult.errors[i];
  //         this.projectService.errors.add(
  //           'RotatingMachineInput.' + error.propertyName,
  //           error.errorMessage
  //         );
  //       }
  //     }
  //   }

  // }
}
