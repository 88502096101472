import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { MenuItem } from 'primeng/api';

@Injectable({ providedIn: 'root' })
export class ContextMenuService {
  public contextMenuChange = new BehaviorSubject(null);
  get options(): ContextMenuOptions { return this.contextMenuChange.value; }
  set options(value: ContextMenuOptions) { this.contextMenuChange.next(value); }

  private _contextMenuDisabled = false;
  get isDisabled(): any {
    return this._contextMenuDisabled;
  }
  set isDisabled(val) {
    this._contextMenuDisabled = val;
    this.contextMenuDisabledChange.next(val);
  }
  public contextMenuDisabledChange = new BehaviorSubject(null);

  constructor() { }
}

export class ContextMenuOptions {
  type: string = '';
  data: any;
  items: MenuItem[];
  offset: number;
  class: string = 'small';
  position: {
    x: number;
    y: number;
  };
}

export enum ContextMenuType {
  /** Use Custom if you want to use custom items.  */
  Custom = '',
  Property = 'property'
}