import { Observable } from "rxjs";

export class FakeEndpoint {
  public results: any;

  constructor() {
    this.results = {};
    
    this.results['CRUNCHER/Synthesis/RotatingMachine'] = {

    };
    
    this.results['VOLTA/Project'] = [
      {
        "id": 32001,
        "name": "Matre Haugsadl",
        "description": null,
        "createdAt": "2017-02-21T09:48:11.773",
        "createdBy": "S-1-5-21-796845957-790525478-725345543-288265",
        "modifiedAt": null,
        "modifiedBy": null,
        "isPrivate": false,
        "userSIDList": [],
        "isDeleted": false,
        "deletedAt": null,
        "deletedBy": null,
        "deletedReason": null
      },
      {
        "id": 32002,
        "name": "Tekapo_B",
        "description": null,
        "createdAt": "2018-01-30T11:28:35.11",
        "createdBy": "S-1-5-21-796845957-790525478-725345543-303584",
        "modifiedAt": null,
        "modifiedBy": null,
        "isPrivate": false,
        "userSIDList": [],
        "isDeleted": false,
        "deletedAt": null,
        "deletedBy": null,
        "deletedReason": null
      },
      {
        "id": 32003,
        "name": "Muskrat Falls",
        "description": null,
        "createdAt": "2016-11-11T20:40:04.577",
        "createdBy": "S-1-5-21-796845957-790525478-725345543-307871",
        "modifiedAt": null,
        "modifiedBy": null,
        "isPrivate": false,
        "userSIDList": [],
        "isDeleted": false,
        "deletedAt": null,
        "deletedBy": null,
        "deletedReason": null
      },
      {
        "id": 32004,
        "name": "Qing_Yuan_PSP",
        "description": null,
        "createdAt": "2016-11-08T16:26:37.933",
        "createdBy": "S-1-5-21-796845957-790525478-725345543-261489",
        "modifiedAt": null,
        "modifiedBy": null,
        "isPrivate": false,
        "userSIDList": [],
        "isDeleted": false,
        "deletedAt": null,
        "deletedBy": null,
        "deletedReason": null
      },
      {
        "id": 32005,
        "name": "Sitarama Stage II",
        "description": null,
        "createdAt": "2017-08-22T12:23:13.613",
        "createdBy": "S-1-5-21-796845957-790525478-725345543-448533",
        "modifiedAt": null,
        "modifiedBy": null,
        "isPrivate": false,
        "userSIDList": [],
        "isDeleted": false,
        "deletedAt": null,
        "deletedBy": null,
        "deletedReason": null
      }
    ];

    this.results['VOLTA/variants/VariantsByProjectId'] = [
      {
        "projectId": 32002,
        "id": 30014,
        "name": "Re-engineering",
        "description": null,
        "createdAt": "2018-01-30T11:28:35.11",
        "createdBy": "S-1-5-21-796845957-790525478-725345543-303584",
        "modifiedAt": null,
        "modifiedBy": null,
        "isPrivate": false,
        "userSIDList": [],
        "isDeleted": false,
        "deletedAt": null,
        "deletedBy": null,
        "deletedReason": null
      },
      {
        "projectId": 32002,
        "id": 30323,
        "name": "New coil winding",
        "description": null,
        "createdAt": "2018-02-21T11:33:26.823",
        "createdBy": "S-1-5-21-796845957-790525478-725345543-261633",
        "modifiedAt": null,
        "modifiedBy": null,
        "isPrivate": false,
        "userSIDList": [],
        "isDeleted": false,
        "deletedAt": null,
        "deletedBy": null,
        "deletedReason": null
      },
      {
        "projectId": 32002,
        "id": 30364,
        "name": "New stator winding complete",
        "description": null,
        "createdAt": "2018-01-30T14:24:19.627",
        "createdBy": "S-1-5-21-796845957-790525478-725345543-303584",
        "modifiedAt": null,
        "modifiedBy": null,
        "isPrivate": false,
        "userSIDList": [],
        "isDeleted": false,
        "deletedAt": null,
        "deletedBy": null,
        "deletedReason": null
      }
    ];

    this.results['VOLTA/versions/VersionsByVariantId'] = [
      {
        "id": 30364,
        "name": "Version 1",
        "description": 'Description'
      },
      {
        "id": 30365,
        "name": "Version 2",
        "description": 'Description'
      },
      {
        "id": 30367,
        "name": "Version 3",
        "description": 'Description'
      },
      {
        "id": 30369,
        "name": "Version 4",
        "description": 'Description'
      }
    ];

    this.results['VOLTA/revisions/RevisionsByVersion'] = [
      {
        "id": 30364,
        "name": "Revision 1",
        "description": 'Description'
      },
      {
        "id": 30365,
        "name": "Revision 2",
        "description": 'Description'
      },
      {
        "id": 30367,
        "name": "Revision 3",
        "description": 'Description'
      }
    ];
  }

  getResponse(type) {
    return new Observable(obs => {
      setTimeout(() => {
        obs.next(this.results[type]);
      }, 200 + Math.random() * 1000);
    });
  }
}