import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { ProjectService } from '../../services/project.service';
import { GlobalService } from 'src/app/services/global.service';
import { UserSettingsService } from 'src/app/services/userSettings.service';

import { ControlBaseComponent } from './baseCtrl.component';
import { NumericPropertyColumn, ListPropertyOptions } from 'src/app/entities/propertyTypes/listProperty';
import { PropertyUtils } from 'src/app/utils/propertyUtils';
import { BaseProperty } from 'src/app/entities/propertyTypes/baseProperty';
import { TableProperty } from 'src/app/entities/propertyTypes/tableProperty';
import { NumericProperty } from 'src/app/entities/propertyTypes/numericProperty';

@Component({
  selector: 'dge-ctrl-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.min.css']
})
export class ControlTableComponent extends ControlBaseComponent implements OnInit {
  @Input() property: TableProperty;
  @Input() isFocussed: boolean;
  @Output() valueChange = new EventEmitter<any>();

  value: any[] = [];

  constructor(
    protected globalService: GlobalService,
    protected projectService: ProjectService,
    protected userSettingsService: UserSettingsService
  ) { 
    super(globalService, projectService, userSettingsService);
  }

  ngOnInit() {
    this.getValue();
    
    super.ngOnInit();

    this._subscriptions.push(this.userSettingsService.settingsChanged.subscribe((settings: any) => {
      this.setUnit();
    }));
  }

  // Events ---------------

  onFocus(e) {
    this.setFocus();
  }

  onRowKeydown(e: KeyboardEvent, rowIdx, colIdx) {

  }

  // Methods -----------------

  getValue() {
    if (this.key) {
      this.value = this.projectService.getDataValue(this.key);
    }
  }

  setValue() {
    // this.storeValue();
  }

  storeValue() {
    // if (this.options.convertToSimpleList && this.columns.length === 1) {
    //   this.projectService.setDataValue(this.key, this.data.map(i => i[this.columns[0].inputKey]));
    // } else if (this.options.convertToArrayOfArrays) {
    //   const dataValue = [];
    //   this.data.forEach(i => {
    //     const r = [];
    //     this.columns.forEach((c, idx) => r[idx] = i[c.inputKey]);
    //     dataValue.push(r);
    //   });
    //   this.projectService.setDataValue(this.key, dataValue);
    // } else {
    //   this.projectService.setDataValue(this.key, this.data);
    // }
    // this.valueChange.emit(this.data);

    // console.log('value set to: ', this.data);
  }

  getCtrlKey(rowIdx, colIdx) {
    return this.key + rowIdx + this.property.columns[colIdx].property.inputKey;
  }

  setUnit() {
    this.property.columns.forEach(col => {
      if (col.property.type === 'NumericProperty') {
        const numProp = col.property as NumericProperty;

        if (numProp.options.baseUnit !== null && col.labelTemplate) {
          numProp.label = col.labelTemplate.replace(/{{unit}}/gi, 
            PropertyUtils.getMeasurementSystemUnitLabel(
              this.userSettingsService.getMeasurementSystem(), 
              numProp.options.viewUnit || numProp.options.baseUnit
            )
          );
        }
      }
    });
  }

  setFocus() {
    if (!this.readOnly) {
      this.globalService.focussedCtrlKey = this.key;
      this.globalService.focussedLineKey = this.lineKey;
    }
  }

  carryOverOutput(storeValue?: boolean) {
    if (this.lineKey && !this.value) {
      // const prop = PropertyUtils.findProperty(this.lineKey);

      // if (prop && prop.type === 'ListProperty' && prop.outputKey) {
      //   this.data = this.projectService.getDataValue(prop.outputKey);
      //   if (storeValue) {
      //     this.storeValue();
      //   } else {
      //     if (this.data) this.carryOverOutputActive = true;
      //   }
      // }
    }
  }

  clearInput() {
    // this.data = null;
    // this.storeValue();
  }
}
