import { Component, OnInit, OnDestroy } from '@angular/core';
import { JsonEditorOptions } from 'ang-jsoneditor';
import { debounceTime } from 'rxjs/operators';
import { SelectItem } from 'primeng/api';

import { ProjectService } from 'src/app/services/project.service';
import { GlobalService } from 'src/app/services/global.service';
import { DrawingsService } from 'src/app/services/drawings.service';
import { UserSettingsService, UserSettings } from 'src/app/services/userSettings.service';

import { Subscription } from 'rxjs';

@Component({
  selector: 'dge-el-debug-json',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.min.css']
})
export class ElementDebugJsonComponent implements OnInit, OnDestroy {
  // JSON
  jsonEditorData = {};
  jsonEditorOptions: JsonEditorOptions;
  jsonEditorViewOptions: SelectItem[] = [
    { label: 'Project Data', value: 'project' },
    { label: 'Last API Input', value: 'api-input' },
    { label: 'Last API Output', value: 'api-output' },
  ];
  selectedJsonEditorView: string = 'project';

  public showWaitInfo = false;

  _subscriptions: Subscription[] = [];

  constructor(
    private projectService: ProjectService, 
    private userSettingsService: UserSettingsService
  ) {
    this.jsonEditorOptions = new JsonEditorOptions();
    this.jsonEditorOptions.expandAll = false;
    this.jsonEditorOptions.mode = 'view';
    this.jsonEditorOptions.modes = ['code', 'text', 'tree', 'view']; // set all allowed modes
  }

  ngOnInit() {
    // this.initDrawings();

    this._subscriptions.push(this.projectService.dataChanged.pipe(debounceTime(1000)).subscribe(() => {
      this.setJsonEditorData('data');
    }));
    this._subscriptions.push(this.projectService.valueChanged.pipe(debounceTime(500)).subscribe(() => {
      this.setJsonEditorData('value');
    }));

    this._subscriptions.push(this.userSettingsService.settingsChanged.subscribe((settings: UserSettings) => {
      if (settings) {
        this.setJsonEditorData(null);
      }
    }));
  } 

  ngOnDestroy() {
    let sub: Subscription;
    while (sub = this._subscriptions.pop()) {
      if (!sub.closed) sub.unsubscribe();
    }
  }

  // DEBUG JSON ----------------------------------------

  onJsonEditorViewChange($e) {    
    this.selectedJsonEditorView = $e;
    this.setJsonEditorData(null);
  }

  setJsonEditorData(type: 'data' | 'value' | null) {
    console.log(type, this.selectedJsonEditorView);

    if (this.selectedJsonEditorView === 'project') this.jsonEditorData = this.projectService.project.data;
    if ((type === null || type === 'data') && this.selectedJsonEditorView === 'api-input') this.jsonEditorData = this.projectService._debugLatestInput || {};
    if ((type === null || type === 'data') && this.selectedJsonEditorView === 'api-output') this.jsonEditorData = this.projectService._debugLatestOutput || {};
  }
}
