// https://angular.io/guide/attribute-directives

import { Directive, ElementRef, Input, OnInit, HostListener } from '@angular/core';

import { TooltipService, TooltipOptions } from '../services/tooltip.service';
@Directive({
  selector: '[dgeTooltip]'
})
export class TooltipDirective implements OnInit {
  @Input() tooltipData: any;
  @Input() tooltipType: 'default' | 'issue' = 'default';
  @Input() tooltipPosition: 'right' | 'bottom' = 'right';
  @Input() tooltipOffset: number = 0;

  options: TooltipOptions;

  constructor(
    private el: ElementRef,
    private tooltipService: TooltipService
  ) { }

  ngOnInit() {

  }

  @HostListener('mouseenter')
  onMouseEnter() {
    if (!this.tooltipService.isTooltipDisabled) {
      clearTimeout(this.tooltipService.tooltipOpenTimeout);
      clearTimeout(this.tooltipService.tooltipCloseTimeout);

      this.tooltipService.tooltipOpenTimeout = setTimeout(() => {

        if (!this.tooltipService.isTooltipDisabled) {
          this.options = new TooltipOptions();
          this.options.data = this.tooltipData;
          this.options.type = this.tooltipType;
          this.options.position = this.tooltipPosition;
          this.options.offset = this.tooltipOffset;
          this.options.refRect = this.el.nativeElement.getBoundingClientRect();
          this.tooltipService.options = this.options;
        }
      }, this.tooltipService.options != null ? 0 : 700);
    }
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    if (this.options) {
      this.tooltipService.tooltipCloseTimeout = setTimeout(() => {
        this.removeTooltip();
      }, 200);
    } else {
      this.removeTooltip();
    }
  }

  private removeTooltip() {
    this.tooltipService.options = null;
    clearTimeout(this.tooltipService.tooltipOpenTimeout);
    clearTimeout(this.tooltipService.tooltipCloseTimeout);
  }

  // todo: window resize

  private highlight(color: string) {
    this.el.nativeElement.style.backgroundColor = color;
  }
}