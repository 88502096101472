import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import { GlobalService, PopupSettings } from 'src/app/services/global.service';
import { PropertyUtils } from 'src/app/utils/propertyUtils';
import { ProjectService } from 'src/app/services/project.service';
import { ElementTabContainerComponent } from '../../elements/tabs/tabContainer.component';
import { Router } from '@angular/router';

@Component({
  selector: 'dge-layout-col-favs-panel-comments',
  templateUrl: './panelComments.component.html',
  styleUrls: ['./panelComments.component.min.css']
})
export class LayoutColumnFavsPanelCommentsComponent implements OnInit {
  comments: ProjectComment[];

  @ViewChild('comment', { static: true }) comment: ElementRef;
  @ViewChild('tabContainer', { static: true }) tabContainer: ElementTabContainerComponent;

  constructor(
    private globalService: GlobalService,
    private projectService: ProjectService,
    private router: Router
  ) { }

  ngOnInit() {
    this.projectService.metaDataChanged.subscribe(() => {
      this.comments = [];
      for (const key in this.projectService.project.metaData.values) {
        if (this.projectService.project.metaData.values.hasOwnProperty(key)) {
          const metaData = this.projectService.project.metaData.values[key];
          if (metaData.comment) {
            const label = PropertyUtils.findProperty(key).label;
            this.comments.push({ lineKey: metaData.lineKey, label: label, comment: metaData.comment });
          }
        }
      }
    });
  }

  onCommentClick() {
  }

  onCommentDblClick(comment: ProjectComment) {
    this.router.navigate(['main', { outlets: { gears: [PropertyUtils.findProperty(comment.lineKey).searchGroup] } }]);
    this.globalService.focussedLineKey = comment.lineKey;
        
    this.globalService.currentPopup = new PopupSettings({
      type: 'comment',
      data: {
        lineKey: comment.lineKey
      }
    });
  }
}

class ProjectComment {
  lineKey: string;
  label: string;
  comment: string;
}
