import { OnInit, Input, OnDestroy } from '@angular/core';

import { merge, Subscription } from 'rxjs';

import { ProjectService } from '../../services/project.service';
import { GlobalService } from 'src/app/services/global.service';
import { UserSettingsService } from 'src/app/services/userSettings.service';

import { PropertyMetaData } from 'src/app/entities/project';

export abstract class ControlBaseComponent implements OnInit, OnDestroy {
  /** Property name. */
  @Input() lineKey: string;
  /** Do not fire line/ctrl key change events. */
  @Input() disableKeyChange: boolean = false;
  /** Control key. Default: [key]. */
  @Input() ctrlKey: string;  
  /** Control will use no input functionality. */
  @Input() readOnly: boolean = false;  

  /** Key of value. */
  _key: string;
  @Input('key') get key(): string {
    return this._key;
  }
  set key(value: string) {
    this._key = value;
    this.ctrlKey = value;
  }

  isFocussed: boolean = false;
  color: number = null;
  /** When focussing an empty input control, while an output is available for the same linekey it'll try to carry over that value to the input. */
  carryOverOutputActive: boolean = false;
  disableOnChange: boolean = false;

  _subscriptions: Subscription[] = [];

  constructor(
    protected globalService: GlobalService,
    protected projectService: ProjectService,
    protected userSettingsService: UserSettingsService
  ) { }

  ngOnInit() {
    if (this.projectService.hasMetaData(this.ctrlKey)) {
      this.color = this.projectService.getMetaData(this.ctrlKey).color || null;
    }

    // Changebehaviors
    this._subscriptions.push(merge(this.globalService.focussedLineChanged, this.globalService.focussedCtrlChanged).subscribe((ctrlKey: string) => {
      this.isFocussed = (this.ctrlKey === this.globalService.focussedCtrlKey);
    }));
    this._subscriptions.push(this.globalService.forceFocussedCtrlChanged.subscribe((ctrlKey: string) => {
      if (ctrlKey && this.ctrlKey === this.globalService.forceFocussedCtrlKey) this.setFocus();
    }));

    this._subscriptions.push(this.userSettingsService.settingsChanged.subscribe((settings: any) => {
      this.getValue();
    }));

    this._subscriptions.push(this.projectService.dataChanged.subscribe(() => {
      this.getValue();
    }));

    this._subscriptions.push(this.projectService.metaDataChanged.subscribe((res: { key: string, value: PropertyMetaData }) => {
      if (res && this.ctrlKey === res.key) {
        this.color = res.value.color || null;
      }
    }));

    this._subscriptions.push(this.projectService.valueChanged.subscribe((r: { key: string, value: any }) => {
      if (r && this.key === r.key) this.getValue();
    }));

    this._subscriptions.push(this.projectService.valueChangedExternally.subscribe((ctrlKey: string) => {
      if (this.ctrlKey === ctrlKey) this.getValue();
    }));
  }

  ngOnDestroy() {
    let sub: Subscription;
    while (sub = this._subscriptions.pop()) {
      if (!sub.closed) sub.unsubscribe();
    }
  }

  getCtrlClasses() {
    const classes = {};
    classes['dge-ctrl'] = true;
    if (this.color !== null) classes['dge-ctrl-bg-' + this.color] = true;
    return classes;
  }

  getOverlayClasses() {
    const classes = {};
    classes['dge-ctrl-overlay'] = true;
    if (this.color !== null) classes['dge-ctrl-bg-' + this.color] = true;
    return classes;
  }

  abstract getValue();

  abstract setFocus();

  abstract carryOverOutput(storeValue?: boolean);

  abstract clearInput();
}
