
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { CommonModule, PlatformLocation } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// Modules
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { PrimeModule } from './app.module.prime';

// COMPONENTS ====================================
// Layout'ish Components
import { LoaderComponent } from './components/layout/general/loader.component';
import { NotFoundComponent } from './components/layout/general/notFound.component';
import { PopupComponent } from './components/layout/general/popup.component';
import { EmptyComponent } from './components/layout/empty.component';
import { TooltipComponent } from './components/shared/tooltip.component';

// Elements
import { ElementPropertyLineComponent } from './components/elements/propertyLine.component';
import { ElementConditionContainerComponent } from './components/elements/conditionContainer.component';
import { ElementTabContainerComponent } from './components/elements/tabs/tabContainer.component';
import { ElementTabContentComponent } from './components/elements/tabs/tabContent.component';
import { ElementSidebarComponent } from './components/elements/sidebar.component';

import { ElementConfirmExtendedComponent } from './components/elements/confirmExtended/confirmExtended.component';
import { ElementDashboardComponent } from './components/elements/dashboard/index.component';
import { ElementDataBrowserMainComponent } from './components/elements/dataBrowser/index.component';
import { ElementSettingsComponent } from './components/elements/settings/index.component';
import { ElementCommentComponent } from './components/elements/comment/index.component';
import { ElementDebugJsonComponent } from './components/elements/debugJson/index.component';
import { ChainBeltCalcComponent  } from './components/elements/chainBeltCalculation/index.component';

// Controls
import { ControlStringInputComponent } from './components/controls/stringInput.component';
import { ControlNumericInputComponent } from './components/controls/numericInput.component';
import { ControlComposedInputComponent } from './components/controls/composedInput.component';
import { ControlSelectComponent } from './components/controls/select.component';
import { ControlListComponent } from './components/controls/list.component';
import { ControlArrayInputComponent } from './components/controls/arrayInput.component';
import { ControlCustomInputComponent } from './components/controls/customInput.component';
import { ControlCustomListInputComponent } from './components/controls/customListInput.component';
import { ControlEntityEditorComponent } from './components/controls/entityInput.component';
import { ControlTableComponent } from './components/controls/table.component';
import { ControlSvgViewerComponent } from './components/controls/svgViewer.component';
import { ControlImageComponent } from './components/controls/image.component';

// DIRECTIVES ====================================
import { ContextMenuDirective } from './directives/contextMenu.directive';
import { TooltipDirective } from './directives/tooltip.directive';

// PIPES =========================================
import { FormatNumericPipe } from './pipes/formatNumeric.pipe';
import { UnitConversionPipe } from './pipes/unitConversion.pipe';
import { SafePipe } from './pipes/safe.pipe';

// SERVICES =========================================
import { ReportingService } from './services/reporting.service';
import { ConfigService } from './services/config.service';
import { CommonDataPoolService } from './services/commonDataPool.service';
import { DrawingsService } from './services/drawings.service';

// Auth
import { MSAL_CONFIG } from '@azure/msal-angular/dist/msal.service';
import { MsalModule } from '@azure/msal-angular';
import { MsalService, MsalInterceptor } from '@azure/msal-angular';
import { LoginComponent } from './components/auth/login.component';
import { InlineErrorComponent } from './components/elements/inlineError/inlineError.component';

// OpenIDConnect
// import { OAuthModule, OAuthService, JwksValidationHandler } from 'angular-oauth2-oidc';
// import { authConfig } from './app.auth.config';

@NgModule({
  declarations: [
    // COMPONENTS ====================================
    // Layout'ish Components
    LoaderComponent,
    NotFoundComponent,
    PopupComponent,
    EmptyComponent,
    LoginComponent,
    TooltipComponent,

    // Elements
    ElementPropertyLineComponent,
    ElementConditionContainerComponent,
    ElementTabContainerComponent,
    ElementTabContentComponent,
    ElementSidebarComponent,

    ElementConfirmExtendedComponent,
    ElementDashboardComponent,
    ElementDataBrowserMainComponent,
    ElementSettingsComponent,
    ElementCommentComponent,
    ElementDebugJsonComponent,
    ChainBeltCalcComponent,

    InlineErrorComponent,

    // Controls
    ControlStringInputComponent,
    ControlNumericInputComponent,
    ControlComposedInputComponent,
    ControlSelectComponent,
    ControlListComponent,
    ControlArrayInputComponent,
    ControlCustomInputComponent,
    ControlCustomListInputComponent,
    ControlEntityEditorComponent,
    ControlTableComponent,
    ControlSvgViewerComponent,
    ControlImageComponent,

    // DIRECTIVES ====================================
    ContextMenuDirective,
    TooltipDirective,

    // PIPES =========================================
    FormatNumericPipe,
    UnitConversionPipe,
    SafePipe
  ],
  imports: [
    // Angular Modules
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,

    // Modules
    FontAwesomeModule,
    NgJsonEditorModule,
    PrimeModule,

    // Auth
    MsalModule,
    // OAuthModule.forRoot({
    //   resourceServer: {
    //     allowedUrls: ['https://volta-dev.andritz.com', 'https://cruncher-dev.andritz.com'],
    //     sendAccessToken: true
    //   }
    // })
  ],
  exports: [
    // Angular Modules
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,

    // Modules
    FontAwesomeModule,
    NgJsonEditorModule,
    PrimeModule,

    // COMPONENTS ====================================
    // Layout'ish Components
    LoaderComponent,
    NotFoundComponent,
    PopupComponent,
    EmptyComponent,
    LoginComponent,
    TooltipComponent,

    // Elements
    ElementPropertyLineComponent,
    ElementConditionContainerComponent,
    ElementTabContainerComponent,
    ElementTabContentComponent,
    ElementSidebarComponent,

    ElementConfirmExtendedComponent,
    ElementDashboardComponent,
    ElementDataBrowserMainComponent,
    ElementSettingsComponent,
    ElementCommentComponent,
    ElementDebugJsonComponent,
    ChainBeltCalcComponent,

    // Controls
    ControlStringInputComponent,
    ControlNumericInputComponent,
    ControlComposedInputComponent,
    ControlSelectComponent,
    ControlListComponent,
    ControlArrayInputComponent,
    ControlCustomInputComponent,
    ControlCustomListInputComponent,
    ControlEntityEditorComponent,
    ControlTableComponent,
    ControlSvgViewerComponent,
    ControlImageComponent,

    // DIRECTIVES ====================================
    ContextMenuDirective,
    TooltipDirective,

    // PIPES =========================================
    FormatNumericPipe,
    UnitConversionPipe,
    SafePipe
  ],
  providers: [
    MsalService,
    ReportingService,
    CommonDataPoolService,
    DrawingsService,

    { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [ConfigService], multi: true },
    { provide: MSAL_CONFIG, useFactory: initializeAuth, deps: [PlatformLocation, ConfigService] },
    { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true }
  ]
})
export class SharedModule {
  // constructor(private oauthService: OAuthService) {
  //   this.configure();
  // }

  // private configure() {
  //   this.oauthService.configure(authConfig);
  //   this.oauthService.tokenValidationHandler = new JwksValidationHandler();
  //   this.oauthService.loadDiscoveryDocumentAndLogin();
  //   this.oauthService.setupAutomaticSilentRefresh();
  // }
}

export function initializeAuth(platformLocation: PlatformLocation, configService: ConfigService) {
  const appBaseUrl = window.location.origin + platformLocation.getBaseHrefFromDOM();
  const msalConfig = {
    redirectUri: appBaseUrl,
    authority: configService.authConfig.aad.authority,
    clientID: configService.authConfig.aad.clientId,
    protectedResourceMap: configService.authConfig.aad.protectedResourceMap,
    cacheLocation: 'localStorage', // for SSO over tabs
    loadFrameTimeout: 25000
  };
  return msalConfig;
}


export function initializeApp(configService: ConfigService) {
  return () => Promise.all([configService.loadAuthConfig()]);
}
