export const DrawingDescriptions = {
  'CROSS SECTION (1 POLE)': {
    'mode': 0,
    'fillSlots': true,
    'discriminator': 'DrawingDescriptionCrossSection'
  },

  'CROSS SECTION (HALF)': {
    'mode': 1,
    'fillSlots': false,
    'discriminator': 'DrawingDescriptionCrossSection'
  },

  'CROSS SECTION (FULL)': {
    'mode': 2,
    'fillSlots': false,
    'discriminator': 'DrawingDescriptionCrossSection'
  },

  'LONGITUDINAL SECTION': {
    'discriminator': 'DrawingDescriptionLongitudinalSection',
    'drawCompleteSection': true
  },

  'SLOT SECTION': {
    'fillSlots': true,
    'drawRightContour': true,
    'drawLeftContour': true,
    'showDimensions': true,
    'discriminator': 'DrawingDescriptionSlotSection'
  },
  
  'FANS': {
    'discriminator': 'DrawingDescriptionFans'
  },

  'WINDING DIAGRAM - STANDARD': {
    'data': null,
    'settings': {
      'hasConnectionsOnBottom': false,
      'isRound': false,
      'isStacked': false,
      'slotOffset': 0,
      'slotTextPeriod': 0
    },
    'discriminator': 'DrawingDescriptionWindingDiagram'
  },

  'WINDING DIAGRAM - STACKED': {
    'data': null,
    'settings': {
      'hasConnectionsOnBottom': false,
      'isRound': false,
      'isStacked': true,
      'slotOffset': 0,
      'slotTextPeriod': 0
    },
    'discriminator': 'DrawingDescriptionWindingDiagram'
  },

  'WINDING DIAGRAM - ROUND': {
    'data': null,
    'settings': {
      'hasConnectionsOnBottom': false,
      'isRound': true,
      'isStacked': false,
      'slotOffset': 0,
      'slotTextPeriod': 0
    },
    'discriminator': 'DrawingDescriptionWindingDiagram'
  },

  'BOX (TINGLEY) DIAGRAM': {
    'data': null,
    'settings': {
    },
    'discriminator': 'DrawingDescriptionBoxDiagram'
  },

  'VOLTAGE VECTORS': {
    'data': null,
    'settings': {
    },
    'discriminator': 'DrawingDescriptionVoltageVectorDiagram'
  },

  // 'CORE LONGITUDINAL SECTION': {
  //   'discriminator': 'DrawingDescriptionCoreLongitudinalSection'
  // }
};