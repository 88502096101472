var metric, imperial;

metric = {
  ['A/m']: {
    name: {
      singular: 'Ampere per meter', 
      plural: 'Amperes per meter'
    }, 
    to_anchor: 1
  }, 
  ['A/cm']: {
    name: {
      singular: 'Ampere per centimeter',
      plural: 'Amperes per centimeter'
    },
    to_anchor: 1 / 0.01
  },
  ['A/m2']: {
    name: {
      singular: 'Ampere per square meter',
      plural: 'Amperes per square meter'
    },
    to_anchor: 1
  },
  ['A/mm2']: {
    name: {
      singular: 'Ampere per square millimeter',
      plural: 'Amperes per square millimeter'
    },
    to_anchor: 1e6
  },
  ['F']: {
    name: {
      singular: 'Farad',
      plural: 'Farads'
    },
    to_anchor: 1
  },
  ['µF']: {
    name: {
      singular: 'micro Farad',
      plural: 'micro Farads'
    },
    to_anchor: 1.0e-6
  },
  ['A2/m3']: {
    name: {
      singular: 'square Ampere per cubic meter',
      plural: 'square Amperes per cubic meter'
    },
    to_anchor: 1
  },
  ['A2/cm/mm2']: {
    name: {
      singular: 'square Ampere per centimeter and millimeter',
      plural: 'square Amperes per centimeter and millimeter'
    },
    to_anchor: 1.0e8
  },
};

imperial = {
  'A/in': {
    name: {
      singular: 'Ampere per inch',
      plural: 'Amperes per inch'
    },
    to_anchor: 1
  }
};

module.exports = {
  metric: metric,
  imperial: imperial,
  _anchors: {
    metric: {
      unit: 'A/m',
      ratio: 1/0.0254
    },
    imperial: {
      unit: 'A/in',
      ratio: 0.0254
    }
  }
};
