// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  
  apiCallsEnabled: true,

  commonDataPoolUrl: 'https://kftcdp-dev.azurewebsites.net/api/',
  // calculationServiceUrl: 'https://localhost:5001/api/',
  calculationServiceUrl: 'https://kftcaetools-dev.azurewebsites.net/api/',
  
  disabledModules: [ 'screwrec' ],
  
  helpUrl: 'https://kftcdp-dev.azurewebsites.net/help/',

  imageUrls: {
    'help': 'https://kftcdp-dev.azurewebsites.net/help/images/'
  },
  
  auth: {
    aad: {
      clientId: 'e238cf8a-a6a5-4969-ba39-aad923907880',
      authority: 'https://login.microsoftonline.com/6785298f-e857-464b-9b4b-807178402632/',
      protectedResourceMap: [ 
        ['https://localhost:44307', ['api://1e781659-5b8f-46f8-aecd-7229215d3176/tool.access']], 
        ['https://kftcaetools-dev.azurewebsites.net', ['api://1e781659-5b8f-46f8-aecd-7229215d3176/tool.access']], 
        ['https://kftcdp-dev.azurewebsites.net', ['api://938004f2-cb6b-460e-86f0-bbab85088efc/data.access']], 
        ['https://graph.microsoft.com', ['User.Read']]
      ]
    }
  },

  

  // commonDataPoolUrl: 'https://voltadev-andritz.msappproxy.net/api/',
  // calculationServiceUrl: 'https://cruncherdev-andritz.msappproxy.net/api/',

  // imageUrls: {
  //   'help': 'https://volta-dev.andritz.com/help/images/'
  // },
  
  // auth: {
  //   aad: {
  //     clientId: '7dbd8b50-9c7c-4999-951c-776ed26eaaac',
  //     authority: 'https://login.microsoftonline.com/6785298f-e857-464b-9b4b-807178402632/',
  //     protectedResourceMap: [ 
  //       ['https://cruncherdev-andritz.msappproxy.net', ['api://cf1b5161-fdb0-45a3-be97-a4d664dcb005/cruncher.access']], 
  //       ['https://voltadev-andritz.msappproxy.net', ['api://0e31e1a6-f83a-430d-b7e3-d95e435b8453/volta.access']], 
  //       ['https://graph.microsoft.com/beta/me', ['user.read']]
  //     ]
  //   }
  // }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
