import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { SelectTypeOption, SelectOption, SelectProperty } from '../../entities/propertyTypes/selectProperty';

import { ProjectService } from '../../services/project.service';
import { GlobalService } from 'src/app/services/global.service';
import { ControlBaseComponent } from './baseCtrl.component';
import { UserSettingsService } from 'src/app/services/userSettings.service';
import { PropertyUtils } from 'src/app/utils/propertyUtils';
import { HelperUtils } from 'src/app/utils/helperUtils';

@Component({
  selector: 'dge-ctrl-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.min.css']
})
export class ControlSelectComponent extends ControlBaseComponent implements OnInit {
  @Input() options: SelectOption[];
  @Input() property: SelectProperty;
  @Input() mode: 'default' | 'type' = 'default';

  _value: any;
  get value() {
    return this._value;
  }
  @Input() set value(value: any) {
    this._value = value;
    this.setDisplayValue();
  }
  oldValue: any;
  
  displayValue: string = null;

  @Output() valueChange = new EventEmitter<any>();

  constructor(
    protected globalService: GlobalService,
    protected projectService: ProjectService,
    protected userSettingsService: UserSettingsService
  ) { 
    super(globalService, projectService, userSettingsService);
  }

  ngOnInit() {
    if (!this.property && this.options) {
      this.property = new SelectProperty({ availableValues: this.options });
    }

    this.getValue();
    
    super.ngOnInit();
  }

  // Events ---------------

  onKeydown(e: KeyboardEvent) {
    if (e.which === 27) {
      // ESC - Cancel
      this.getValue();
      if (this.carryOverOutputActive) this.carryOverOutputActive = false;
      this.globalService.focussedCtrlKey = null;
      this.globalService.focussedLineKey = null;
    }
  }

  onChange(e) {
    if (this.mode === 'type') {
      this.setValue(this.getNewInstance(e.value));      
      this.projectService.typeChanged.next({ key: this.key, value: e.value });
    } else {
      this.setValue(e.value);
      this.projectService.valueChanged.next({ key: this.key, value: e.value });
    }

    this.oldValue = e.value;

    this.globalService.focussedCtrlKey = null;
    this.carryOverOutputActive = false;
  }

  onFocus(e) {
    this.setFocus();
  }

  onBlur(e) {
    this.isFocussed = false;

    if (this.carryOverOutputActive) {
      this.onChange(e);
      this.carryOverOutputActive = false;
    }
  }

  // Methods -----------------

  getSelectOptions() {
    if (!this.readOnly) {
      return this.property.availableValues.filter(v => !v.hidden);
    } else {
      return this.property.availableValues;
    }
  }

  getNewInstance(discriminator: string) {    
    const option = (this.property.availableValues.find(i => i.value === discriminator) as SelectTypeOption);
    if (!option || !option.assignment) return null;

    const newInstance = option.assignment();

    if (newInstance && newInstance.init) {
      const prjVal = this.projectService.getDataValue(this.key);
      try {
        newInstance.init(prjVal);
      } catch (ex) {
        console.log('[ERROR] Cant initialize class per select assignment: ' + ex.toString());
      }
    }

    return newInstance;
  }

  getValue() {
    if (this.key) {
      const prjVal = this.projectService.getDataValue(this.key);
      
      if (this.mode === 'type') {
        this.value = (typeof prjVal === 'object' && prjVal !== null) ? PropertyUtils.getDiscriminator(prjVal) : prjVal; // org. Null
      } else {
        this.value = prjVal;
      }
    }
  }

  setValue(value: any) {
    if (this.key) this.projectService.setDataValue(this.key, value);
    console.log('value set to: ', value);
    this.valueChange.emit(value);
  }

  setDisplayValue() {
    if (this.property.availableValues) {
      const selectedOption = this.property.availableValues.find(i => i.value === this.value);
      this.displayValue = selectedOption ? selectedOption.label : this.value;
    }
  }

  setFocus() {
    if (!this.readOnly && !this.isFocussed) {
      this.globalService.focussedCtrlKey = this.ctrlKey;
      this.globalService.focussedLineKey = this.lineKey;

      this.carryOverOutput();
    }
  }

  carryOverOutput(storeValue?: boolean) {
    if (this.lineKey && !this.value) {
      const prop = PropertyUtils.findProperty(this.lineKey);
      if (prop && prop.outputKey) {
        if (prop.type === 'SelectProperty') {
          this.value = this.projectService.getDataValue(prop.outputKey);

          if (storeValue) {
            this.setValue(this.value);
            this.projectService.valueChanged.next({ key: this.key, value: this.value });
          }
        }
        
        if (prop.type === 'SelectTypeProperty') {
          this.value = this.projectService.getDataValue(prop.outputKey);

          console.log('carryOverOutput', this.value);

          if (this.value !== null) {
            const outputItem = this.property.availableValues.find(i => i.value === this.value);
            console.log('outputItem', outputItem);
            if (outputItem) {
              // Var 1: Via label
              const inputItem = this.property.availableValues.find(i => i.label === outputItem.label && !i.hidden);
              console.log('inputItem', inputItem);
              if (inputItem) {   
                this.value = inputItem.value;

                if (storeValue) {
                  this.setValue(this.getNewInstance(this.value));      
                  this.projectService.typeChanged.next({ key: this.key, value: this.value });
                }
              }
            } else if (PropertyUtils.getDiscriminator(this.value)) {
              // Var 2: Via discriminator
              this.value = PropertyUtils.getDiscriminator(this.value) + 'Input';
              
              if (storeValue) {
                this.setValue(this.getNewInstance(this.value));      
                this.projectService.typeChanged.next({ key: this.key, value: this.value });
              }
            }
          }
        }
        
        if (!storeValue)this.carryOverOutputActive = true;
      }
    }
  }

  clearInput() {
    this.value = null;
    this.setValue(null);
  }
}
