import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { ProjectService } from '../../services/project.service';
import { GlobalService } from 'src/app/services/global.service';
import { UserSettingsService } from 'src/app/services/userSettings.service';

import { ControlBaseComponent } from './baseCtrl.component';
import { NumericPropertyColumn, ListPropertyOptions } from 'src/app/entities/propertyTypes/listProperty';
import { PropertyUtils } from 'src/app/utils/propertyUtils';
import { BaseProperty } from 'src/app/entities/propertyTypes/baseProperty';

@Component({
  selector: 'dge-ctrl-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.min.css']
})
export class ControlListComponent extends ControlBaseComponent implements OnInit {
  @Input() columns: BaseProperty[];
  @Input() options: ListPropertyOptions;
  @Input() isFocussed: boolean;
  @Output() valueChange = new EventEmitter<any>();
  data: any[] = [];

  constructor(
    protected globalService: GlobalService,
    protected projectService: ProjectService,
    protected userSettingsService: UserSettingsService
  ) { 
    super(globalService, projectService, userSettingsService);
  }

  ngOnInit() {
    this.getValue();
    
    super.ngOnInit();

    this._subscriptions.push(this.userSettingsService.settingsChanged.subscribe((settings: any) => {
      this.setUnit();
    }));
  }

  // Events ---------------

  onItemChange(value, idx, colName) {
    this.data[idx][colName] = value;
    this.setValue();
  }

  onFocus(e) {
    this.setFocus();
  }

  onRowKeydown(e: KeyboardEvent, rowIdx, colIdx) {
    if (e.ctrlKey && e.which === 13) {
      // CTRL + Enter to add rows
      this.addItem();
    }

    if (e.ctrlKey && [37, 38, 39, 40].some(i => i === e.which)) {
      // CTRL + Arrow keys to move around
      if (e.which === 38) rowIdx--;
      if (e.which === 40) rowIdx++;
      if (e.which === 37) colIdx--;
      if (e.which === 39) colIdx++;

      if (rowIdx < 0) rowIdx = 0;
      if (rowIdx >= this.data.length) rowIdx = this.data.length - 1;
      if (colIdx < 0) colIdx = 0;
      if (colIdx >= this.columns.length) colIdx = this.columns.length - 1;

      this.globalService.forceFocussedCtrlKey = this.getCtrlKey(rowIdx, colIdx);
    }

    if (e.altKey && [38, 40].some(i => i === e.which)) {
      // ALT + Arrow up/down to move rows
      let newRowIdx = rowIdx;
      if (e.which === 38 && rowIdx > 0) { 
        newRowIdx -= 1; 
      } else if (e.which === 40 && rowIdx < this.data.length) { 
        newRowIdx += 1; 
      } else { 
        return; 
      }

      this.data.splice(newRowIdx, 0, this.data.splice(rowIdx, 1)[0]);
      this.globalService.forceFocussedCtrlKey = this.getCtrlKey(newRowIdx, colIdx);
    }
  }

  // Methods -----------------

  getValue() {
    if (this.key) {
      let value = this.projectService.getDataValue(this.key);

      if (value) {
        if (this.options.convertToSimpleList && this.columns.length === 1) {
          value = value.map(i => {
            const obj = {};
            this.columns.forEach((c, idx) => obj[c.inputKey] = i);
            return obj;
          });
        } else if (this.options.convertToArrayOfArrays) {
          value = value.map(i => {
            const obj = {};
            this.columns.forEach((c, idx) => obj[c.inputKey] = i[idx]);
            return obj;
          });
        }

        this.data = value;
      } else {
        this.data = [];
      }
    }
  }

  setValue() {
    this.storeValue();
  }

  storeValue() {
    if (this.options.convertToSimpleList && this.columns.length === 1) {
      this.projectService.setDataValue(this.key, this.data.map(i => i[this.columns[0].inputKey]));
    } else if (this.options.convertToArrayOfArrays) {
      const dataValue = [];
      this.data.forEach(i => {
        const r = [];
        this.columns.forEach((c, idx) => r[idx] = i[c.inputKey]);
        dataValue.push(r);
      });
      this.projectService.setDataValue(this.key, dataValue);
    } else {
      this.projectService.setDataValue(this.key, this.data);
    }
    this.valueChange.emit(this.data);

    console.log('value set to: ', this.data);
  }

  getCtrlKey(rowIdx, colIdx) {
    return this.key + rowIdx + this.columns[colIdx].inputKey;
  }

  setUnit() {
    this.columns.forEach(i => {
      if (i.type === 'NumericPropertyColumn') {
        const numValCol = i as NumericPropertyColumn;

        if (numValCol.options.baseUnit !== null) {
          numValCol.label = numValCol.labelTemplate.replace(/{{unit}}/gi, 
            PropertyUtils.getMeasurementSystemUnitLabel(
              this.userSettingsService.getMeasurementSystem(), 
              numValCol.options.viewUnit || numValCol.options.baseUnit
            )
          );
        } else {
          numValCol.label = numValCol.labelTemplate;
        }
      }
    });
  }

  addItem() {
    const newItem = typeof(this.options.assignment) === 'function' ? this.options.assignment() : {};
    if (typeof newItem === 'object')
      this.columns.forEach(c => newItem[c.inputKey] = null);
    this.data.push(newItem);
    
    this.setValue();
  }

  removeItem(idx) {
    this.data.splice(idx, 1);

    this.setValue();
  }

  setFocus() {
    if (!this.readOnly) {
      this.globalService.focussedCtrlKey = this.key;
      this.globalService.focussedLineKey = this.lineKey;
    }
  }

  carryOverOutput(storeValue?: boolean) {
    if (this.lineKey && !this.data) {
      const prop = PropertyUtils.findProperty(this.lineKey);

      if (prop && prop.type === 'ListProperty' && prop.outputKey) {
        this.data = this.projectService.getDataValue(prop.outputKey);
        if (storeValue) {
          this.storeValue();
        } else {
          if (this.data) this.carryOverOutputActive = true;
        }
      }
    }
  }

  clearInput() {
    this.data = null;
    this.storeValue();
  }
}
