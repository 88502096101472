import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { faCopy } from '@fortawesome/free-regular-svg-icons';

import { GlobalService, ToastType } from 'src/app/services/global.service';
import { PropertyUtils } from 'src/app/utils/propertyUtils';
import { ConfigService } from 'src/app/services/config.service';
import { ComposedProperty } from 'src/app/entities/propertyTypes/composedProperty';
import { MultiProperty } from 'src/app/entities/propertyTypes/multiProperty';
import { BaseProperty } from 'src/app/entities/propertyTypes/baseProperty';

@Component({
  selector: 'dge-layout-col-favs-panel-help',
  templateUrl: './panelHelp.component.html',
  styleUrls: ['./panelHelp.component.min.css']
})
export class LayoutColumnFavsPanelHelpComponent implements OnInit {
  isLoading: boolean = false;
  focussedKey: string;
  helpHtml: string = '';  
  label: string;  
  inputKeys: string[];
  outputKeys: string[];
  type: string;
  helpFileName: string;
  lastHelpFileName: string;

  icon_faCopy = faCopy;
  @ViewChild('copyInput', { static: true }) copyInput: ElementRef;

  constructor(
    private configService: ConfigService,
    private globalService: GlobalService,
    private http: HttpClient
  ) { }

  ngOnInit() {
    this.globalService.focussedLineChanged.subscribe((key: string) => {
      this.focussedKey = key;
      this.setLabels();
      
      if (this.focussedKey && this.configService.get('apiCallsEnabled')) {
        const prop = PropertyUtils.findProperty(this.focussedKey);
        this.helpFileName = (prop ? prop.helpKey : null) || this.focussedKey;

        if (prop ? prop.helpKey : null) {
          this.helpFileName = this.helpFileName + '.html';
        } else {
          this.helpFileName = 'machine.' + this.helpFileName.split('.').slice(1).join('.') + '.html';
        }

        if (this.helpFileName !== this.lastHelpFileName) {
          this.lastHelpFileName = this.helpFileName;
          this.isLoading = true;
          this.helpHtml = 'Loading...';

          this.http.get(this.configService.get('helpUrl') + this.helpFileName, { responseType: 'text' }).subscribe((res: any) => {
            // Remove included styling which f**** up the layout.
            res = res.replace(/<link.*>/, '');
            // Remove heading as we already have a label
            res = res.replace(/<p class="heading">.*<\/p>/, '');
            res = res.replace('https://volta.andritz.com', 'https://volta-dev.andritz.com');
            
            this.helpHtml = res;

            this.isLoading = false;
          }, err => {
            this.helpHtml = 'Help text not found.';
            this.isLoading = false;
          });
        }
      }     
    });
  }

  setLabels() {
    if (this.focussedKey) {
      this.inputKeys = [];
      this.outputKeys = [];
      const prop = PropertyUtils.findProperty(this.focussedKey);

      if (prop) {       
        this.label = prop.label;
        this.setPropertyKeys(prop);
        this.type = PropertyUtils.getTypeLabel(prop, true);
      } else {
        this.label = '';
        this.inputKeys = null;
        this.outputKeys = null;
        this.type = '';
      }
    }
  }

  setPropertyKeys(prop: BaseProperty) {
    if (prop.type === 'ComposedProperty') {
      const compProp = (prop as ComposedProperty);
      this.inputKeys = [
        this.replaceKeyDots(compProp.value1.inputKey) || '[none]',
        this.replaceKeyDots(compProp.value2.inputKey) || '[none]'
      ];
      this.outputKeys = [
        this.replaceKeyDots(compProp.value1.outputKey) || '[none]',
        this.replaceKeyDots(compProp.value2.outputKey) || '[none]'
      ];
    } else if (prop.type === 'MultiProperty') {
      const multiProp = (prop as MultiProperty);
      this.setPropertyKeys(multiProp.inputProperty);
      this.setPropertyKeys(multiProp.outputProperty);
    } else {
      this.inputKeys = [this.replaceKeyDots(prop.inputKey) || '[none]'];
      this.outputKeys = [this.replaceKeyDots(prop.outputKey) || '[none]'];
    }

  }

  replaceKeyDots(s: string): string {
    if (!s) return s;
    // return s.replace(/\./g, '\u200B.');
    return s;
  }

  copyText(s: string) {
    const copyEl = this.copyInput.nativeElement;
    copyEl.value = s;
    copyEl.style.display = '';
    copyEl.select();
    document.execCommand("copy");
    copyEl.style.display = 'none';
    this.globalService.toast(ToastType.Success, 'Successfully copied "' + s + '".');
  }
}
