import { Injectable } from '@angular/core';

import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Injectable({ providedIn: 'root' })
export class TooltipService {
  public tooltipChange = new BehaviorSubject(null);
  get options(): TooltipOptions { return this.tooltipChange.value; }
  set options(value: TooltipOptions) { this.tooltipChange.next(value); }

  private _tooltipDisabled = false;
  get isTooltipDisabled(): any {
    return this._tooltipDisabled;
  }
  set isTooltipDisabled(val) {
    this._tooltipDisabled = val;
    this.tooltipDisabledChange.next(val);
  }
  public tooltipDisabledChange = new BehaviorSubject(null);

  public tooltipOpenTimeout;
  public tooltipCloseTimeout;

  constructor() { }
}

export class TooltipOptions {
  title: string;
  body: string;
  type: 'default' | 'issue' = 'default';
  data: any;
  isLoading: boolean;
  offset: number;
  position: 'right' | 'bottom' = 'right';
  refRect: {
    x: number;
    y: number;
    width: number;
    height: number;
    top: number;
    left: number;
    right: number;
    bottom: number;
  };
}
