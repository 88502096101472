import { Component, OnInit, Input, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';

import { merge } from 'rxjs';

import { ProjectService } from '../../services/project.service';
import { GlobalService } from 'src/app/services/global.service';
import { UserSettingsService } from 'src/app/services/userSettings.service';

import { NumericPropertyOptions, NumericProperty } from '../../entities/propertyTypes/numericProperty';
import { UnitConversionPipe } from 'src/app/pipes/unitConversion.pipe';
import { ControlBaseComponent } from './baseCtrl.component';
import { PropertyUtils } from 'src/app/utils/propertyUtils';
import { FormatNumericPipe } from 'src/app/pipes/formatNumeric.pipe';
import { HelperUtils } from 'src/app/utils/helperUtils';

@Component({
  selector: 'dge-ctrl-numeric',
  templateUrl: './numericInput.component.html',
  styleUrls: ['./numericInput.component.min.css']
})
export class ControlNumericInputComponent extends ControlBaseComponent implements OnInit {
  @Input() options: NumericPropertyOptions;
  @Input() value: any;
  @Output() valueChange = new EventEmitter<any>();
  regExp: RegExp;
  regExpRaw: string;

  @ViewChild('overlay', { static: false }) overlay: ElementRef;
  @ViewChild('input', { static: false }) input: ElementRef;

  constructor(
    protected globalService: GlobalService,
    protected projectService: ProjectService,
    protected userSettingsService: UserSettingsService
  ) {
    super(globalService, projectService, userSettingsService);
  }

  ngOnInit() {
    this.getValue();
    this.setRegExp();

    super.ngOnInit();

    // #TODO# - own change event for dec. sep
    this._subscriptions.push(merge(this.userSettingsService.settingsChanged).subscribe(s => {
      this.setRegExp();
    }));
  }

  // Events ---------------

  onKeydown(e: KeyboardEvent) {
    this.regExp.lastIndex = 0;
    if (!(e.metaKey || (e.ctrlKey && e.code === 'KeyA') || ['Backspace', 'Tab', 'Enter', 'End', 'Home', 'ArrowLeft', 'ArrowUp', 'ArrowRight', 'ArrowDown', 'Delete'].some(i => i === e.code) || !(this.regExp).test(e.key))) {
      e.preventDefault();
    }
    if (e.code === 'Escape') {
      // ESC - Cancel
      if (this.carryOverOutputActive) this.carryOverOutputActive = false;

      this.globalService.focussedCtrlKey = null;
      this.globalService.focussedLineKey = null;

      this.disableOnChange = true;
      (this.input.nativeElement as HTMLInputElement).blur();
      this.disableOnChange = false;

      this.getValue();
      this.setDisplayValue();
    }
  }

  onChange(e) {
    if (!this.disableOnChange) {
      console.log('entered value: ', e.target.value);
      this.setValue(e.target.value);
      this.globalService.focussedCtrlKey = null;
    }
  }

  onFocus(e: MouseEvent) {
    this.setFocus();
  }

  onBlur(e) {
    this.isFocussed = false;
    if (this.carryOverOutputActive) {
      this.onChange(e);
      this.carryOverOutputActive = false;
    }
  }

  // Methods -----------------

  getValue() {
    if (this.key) this.value = this.projectService.getDataValue(this.key);
  }

  getDisplayValue() {
    return (new FormatNumericPipe(this.userSettingsService).transform(
        (new UnitConversionPipe(this.userSettingsService)).transform(this.value, this.options),
      this.options, true));
  }

  setDisplayValue() {
    if (this.input) (this.input.nativeElement as HTMLInputElement).value = this.getDisplayValue();
  }

  setValue(input: any) {
    let value: number = new UnitConversionPipe(this.userSettingsService).transform(
      HelperUtils.cleanUpNumber(input, this.userSettingsService.getDecimalSeparator(), this.regExp),
      this.options,
      true
    );

    if(this.options.minValue != undefined && this.options.minValue > value) {
      value = this.options.minValue;
    } else if(this.options.maxValue != undefined && this.options.maxValue < value) {
      value = this.options.maxValue;
    }

    this.value = value;
    this.storeValue();
  }

  storeValue() {
    if (this.key) this.projectService.setDataValue(this.key, this.value);
    this.valueChange.emit(this.value);
    console.log('value set to: ', this.value);
  }

  setRegExp() {
    this.regExpRaw = PropertyUtils.getNumericRegExpString(this.options.allowDecimals ? this.userSettingsService.getDecimalSeparator() : '', this.options.allowNegatives, true, '');
    this.regExp = new RegExp(this.regExpRaw, 'gi');
  }

  setFocus() {
    if (!this.readOnly && !this.isFocussed) {
      this.globalService.focussedCtrlKey = this.ctrlKey;
      this.globalService.focussedLineKey = this.lineKey;

      this.carryOverOutput();

      setTimeout(() => {
        this.input.nativeElement.select();
      }, 1);
    }
  }

  carryOverOutput(storeValue?: boolean) {
    if (this.lineKey && !this.value) {
      const prop = PropertyUtils.findProperty(this.lineKey);

      if (prop && prop.type === 'NumericProperty' && prop.outputKey) {
        this.value = this.projectService.getDataValue(prop.outputKey);
        if (storeValue) {
          this.storeValue();
        } else {
          if (this.value) this.carryOverOutputActive = true;
        }
        this.setDisplayValue();
      }
    }
  }

  clearInput() {
    this.value = null;
    this.storeValue();
  }
}
