import { Component, OnInit, Input, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';

import { merge } from 'rxjs';

import { ProjectService } from '../../services/project.service';
import { GlobalService, ToastType } from 'src/app/services/global.service';
import { UserSettingsService } from 'src/app/services/userSettings.service';

import { ControlBaseComponent } from './baseCtrl.component';
import { PropertyUtils } from 'src/app/utils/propertyUtils';
import { FormatNumericPipe } from 'src/app/pipes/formatNumeric.pipe';
import { NumericPropertyOptions, NumericProperty } from 'src/app/entities/propertyTypes/numericProperty';
import { UnitConversionPipe } from 'src/app/pipes/unitConversion.pipe';
import { HelperUtils } from 'src/app/utils/helperUtils';

@Component({
  selector: 'dge-ctrl-array',
  templateUrl: './arrayInput.component.html',
  styleUrls: ['./arrayInput.component.min.css']
})
export class ControlArrayInputComponent extends ControlBaseComponent implements OnInit {
  @Input() numericOptions: NumericPropertyOptions;
  @Input() separators: string[] = [',', ' '];
  @Output() valueChange = new EventEmitter<any>();
  @Input() value: any[];
  regExp: RegExp;
  regExpRaw: string;

  @ViewChild('overlay', { static: false }) overlay: ElementRef;
  @ViewChild('input', { static: false }) input: ElementRef;

  constructor(
    protected globalService: GlobalService,
    protected projectService: ProjectService,
    protected userSettingsService: UserSettingsService
  ) { 
    super(globalService, projectService, userSettingsService);
  }

  ngOnInit() {
    this.getValue();
    this.setRegExp();
    
    super.ngOnInit();

    this._subscriptions.push(merge(this.userSettingsService.settingsChanged).subscribe(s => {
      this.setRegExp();
    }));
  }

  // Events ---------------

  onKeydown(e: KeyboardEvent) {
    this.regExp.lastIndex = 0;
    if (!(e.metaKey || (e.ctrlKey && e.code === 'KeyA') || ['Backspace', 'Tab', 'Enter', 'End', 'Home', 'ArrowLeft', 'ArrowUp', 'ArrowRight', 'ArrowDown', 'Delete'].some(i => i === e.code) || !(this.regExp).test(e.key))) {
      e.preventDefault();
    }
  }

  onChange(e) {
    console.log('entered value: ', e.target.value);
    this.setValue(e.target.value);
    this.globalService.focussedCtrlKey = null;
  }

  onFocus(e) {
    this.setFocus();
  }

  onBlur(e) {
    this.isFocussed = false;
  }

  // Methods -----------------

  getValue() {
    this.value = this.projectService.getDataValue(this.key);
  }

  getDisplayValue() {
    if (!HelperUtils.isNull(this.value) && this.value.length > 0) {
      const unitConversionPipe = new UnitConversionPipe(this.userSettingsService);
      const formatNumericPipe = new FormatNumericPipe(this.userSettingsService);

      let valDisp = '';
      for (let i = 0; i < this.value.length; i++) {
        if (i > 0) valDisp += this.separators[0] + ' ';
        valDisp += formatNumericPipe.transform(unitConversionPipe.transform(this.value[i], this.numericOptions), this.numericOptions, false);
      }

      return valDisp;
    }
    return '';
  }

  setValue(input: any) {
    const split = PropertyUtils.getSeparatedValueSplit(
      input, 
      this.separators, 
      this.userSettingsService, 
      this.regExp, 
      [this.numericOptions]
    );

    if (split && split.length >= 1) {
      this.value = split;
    } else {
      if (input !== '') {
        const typeLabel = PropertyUtils.getTypeLabel(new NumericProperty({ options: this.numericOptions }));
        this.globalService.toast(ToastType.Error, '"' + input + '" cannot be parsed. Please use "[' + typeLabel + ']' + this.separators[0] + ' [' + typeLabel + ']".');
      }
      this.value = null;
    }

    this.storeValue();
  }

  storeValue() {
    this.projectService.setDataValue(this.key, this.value);    
    this.valueChange.emit(this.value);    
    console.log('value set to: ', this.value);
  }  

  setRegExp() {
    this.regExpRaw = PropertyUtils.getNumericRegExpString(this.numericOptions.allowDecimals ? this.userSettingsService.getDecimalSeparator() : '', this.numericOptions.allowNegatives, false, this.separators.join());
    this.regExp = new RegExp(this.regExpRaw, 'gi');
  }

  setFocus() {
    if (!this.readOnly) {
      this.globalService.focussedCtrlKey = this.ctrlKey;
      this.globalService.focussedLineKey = this.lineKey;
      this.input.nativeElement.select();
    }
  }

  carryOverOutput(storeValue?: boolean) {
    if (this.lineKey && !this.value) {
      const prop = PropertyUtils.findProperty(this.lineKey);
      
      if (prop && prop.type === 'ArrayProperty' && prop.outputKey) {
        this.value = this.projectService.getDataValue(prop.outputKey);
        if (storeValue) {
          this.storeValue();
        } else {
          if (this.value) this.carryOverOutputActive = true;
        }
        this.getDisplayValue();
      }
    }
  }

  clearInput() {
    this.value = null;
    this.storeValue();
  }
}
