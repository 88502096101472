import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'dge-loader',
  templateUrl: 'loader.component.html',
  styleUrls: ['loader.component.min.css']
})

export class LoaderComponent implements OnInit {
  _text: string;
  get text(): string {
    return this._text;
  }
  @Input('text')
  set text(val: string) {
    this._text = val;
  }

  @Input() visible: boolean = true;

  constructor() { }

  ngOnInit() { }
}
