import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  authConfig: IAuthConfig;

  constructor(
    private http: HttpClient
  ) { }

  get(configName: string) {
    return environment[configName];
  }

  loadAuthConfig() {
    const jsonFile = `/config/auth.json`;
    return new Promise<void>((resolve, reject) => {
      this.authConfig = this.get('auth');
      resolve();      
    });
  }
}

export interface IAuthConfig {
  aad: {
    authority: string;
    clientId: string;
    protectedResourceMap: [string, string[]][]
  };
}
